import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import { Chip, IconButton, Tooltip } from '@mui/material';

import { handleVisitPage } from '../../../helpers/helpers';

import actionButtonsStyles from './styles';

type Props = {
  params: any;
  onMarkAsNotSoftware: (row: unknown) => void;
  onForceCrawl: (row: unknown) => void;
};

const ActionButtonsFalseNegatives = ({ params, onMarkAsNotSoftware, onForceCrawl }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <IconButton size="small" onClick={() => handleVisitPage(params?.row?.uniqueDomain)}>
        <LanguageIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={() => onMarkAsNotSoftware(params?.row)}>
        <Tooltip title={t('validation.finishStep.notSW')}>
          <CloseIcon fontSize="small" />
        </Tooltip>
      </IconButton>

      <IconButton size="small" onClick={() => onForceCrawl(params.row)}>
        <Chip
          sx={actionButtonsStyles.forceCrawlButton}
          label={t('crawlerInboxPage.forceFullCrawlActioButton')}
          size="small"
        />
      </IconButton>
    </>
  );
};

export default ActionButtonsFalseNegatives;
