import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LanguageIcon from '@mui/icons-material/Language';
import { Backdrop, Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomFooter from '../../../../components/Footer/CustomFooter';
import {
  useCreateUpdateCompanyTechUseMutation,
  useDeleteCompanyTechUseMutation,
  useGetStackshareToolCategoriesQuery,
  useGetStackshareToolsLazyQuery,
  useGetTechnologyUseQuery,
} from '../../../../gql/generated/graphql';
import { getNodes } from '../../../../gql/helpers';
import { ensureProtocol, handleVisitPage } from '../../../../helpers/helpers';
import { TechnologyCategories } from '../../types';
import TechnologySelect from '../TechnologySelect/TechnologySelect';

import { validateTechnologyStyles } from './styles';

type Props = {
  companyId: number;
};

export type TechnologyType = {
  category: string | null;
  technology: string | null;
  stackshareToolId: number | null;
};

const ValidateTechnology = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [technReferenceUrl, setTechnReferenceUrl] = useState('');
  const [selectedTechIds, setSelectedTechIds] = useState<number[]>([]);
  const [technology, setTechnology] = useState<TechnologyType>({
    category: null,
    technology: null,
    stackshareToolId: null,
  });

  const {
    data: technologyUsed,
    loading: technologyUsedLoading,
    refetch: technologyUsedRefetch,
  } = useGetTechnologyUseQuery({
    variables: { companyId },
  });
  const { data: stackshareToolCategories, loading: stackshareToolCategoriesLoading } =
    useGetStackshareToolCategoriesQuery();
  const [getStackshareTools, { data: stackshareTools, loading: stackshareToolsLoading }] =
    useGetStackshareToolsLazyQuery();
  const [deleteTechnology, { loading: deleteTechnologyLoading }] =
    useDeleteCompanyTechUseMutation();
  const [createUpdateCompanyTechUse, { loading: createUpdateCompanyTechUseLoading }] =
    useCreateUpdateCompanyTechUseMutation();

  const stackshareToolCategoriesNodes = getNodes(
    stackshareToolCategories?.getStackshareToolCategories,
  );
  const stackshareToolsNodes = getNodes(stackshareTools?.getStackshareTools);

  const technologyUsedNodes = technologyUsed?.getCompany?.edges?.[0]?.node?.techUse?.edges?.map(
    (techUseEdge) => {
      const techUseNode = techUseEdge?.node;
      const companyNode = technologyUsed?.getCompany?.edges?.[0]?.node;

      return {
        id: techUseNode?.stackshareTool?.id,
        type: techUseNode?.stackshareTool?.breadcrumb,
        name: techUseNode?.stackshareTool?.name,
        stacksUsed: techUseNode?.stackshareTool?.stacksUsed,
        votes: techUseNode?.stackshareTool?.votes,
        crawlUrl: companyNode?.crawlUrl, // Accessing crawlUrl from the company level
        findingUrl: techUseNode?.findingUrl,
        dbId: techUseNode?.dbId,
      };
    },
  );

  const technologyColumns = [
    {
      field: 'type',
      headerName: t('validation.validateTechnology.categoryColumnLabel'),
      width: 200,
    },
    {
      field: 'name',
      headerName: t('validation.validateTechnology.toolColumnLabel'),
      width: 200,
    },
    {
      field: 'stacksUsed',
      headerName: t('validation.validateTechnology.stacksUsedColumnLabel'),
      width: 200,
    },
    {
      field: 'votes',
      headerName: t('validation.validateTechnology.votesColumnLabel'),
      width: 100,
    },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            size="small"
            onClick={() => handleOpenPage(params?.row?.crawlUrl, params?.row?.findingUrl)}
          >
            <Tooltip title={t('validation.validateTechnology.rowIconTooltipVisit')}>
              <LanguageIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => handleDelete(params.row)}>
            <Tooltip title={t('validation.validateTechnology.rowIconTooltipDelete')}>
              <DeleteOutlineIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleOpenPage = (crawlUrl: string, findingUrl: string) => {
    const fullUrl = `${crawlUrl}${findingUrl}`;
    handleVisitPage(fullUrl);
  };

  const handleDelete = async (row) => {
    const selectedTechnology = technologyUsed?.getCompany?.edges?.[0]?.node?.techUse?.edges?.find(
      (node) => node?.node?.stackshareTool?.id === row?.id,
    );

    if (!selectedTechnology?.node?.dbId) return;

    const result = await deleteTechnology({
      variables: { techUseId: selectedTechnology?.node?.dbId },
    });

    if (result?.data?.deleteCompanyTechUse?.success) {
      await technologyUsedRefetch();
    }
  };

  const handleDeleteSelectedTechnologies = async (techIds: number[]) => {
    try {
      const promises = techIds?.map((techId) =>
        deleteTechnology({
          variables: { techUseId: techId },
        }),
      );

      await Promise.all(promises);

      await technologyUsedRefetch();
    } catch (e) {
      console.error('Error during deletion of technologies:', e);
    }
  };

  const handleTechnologyChange = async (name: string, value: string | null) => {
    if (!value) return;

    const selectedTechnology = stackshareToolsNodes.find((item) => item?.name === value);

    setTechnology((prevState: TechnologyType) => ({
      ...prevState,
      [name]: value,
      stackshareToolId: selectedTechnology?.dbId,
    }));

    if (name === TechnologyCategories.CATEGORY) {
      await getStackshareTools({ variables: { category: value } });
      setTechnology((prevState) => ({
        ...prevState,
        technology: null,
      }));
    }
  };

  const handleChangeTechReferenceUrl = (url: string) => {
    setTechnReferenceUrl(url);
  };

  const handleAddTechnology = async () => {
    const result = await createUpdateCompanyTechUse({
      variables: {
        companyId,
        stackshareToolId: technology.stackshareToolId,
      },
    });

    if (result?.data?.createUpdateCompanyTechUse?.companyId) {
      setTechnology({
        category: null,
        technology: null,
        stackshareToolId: null,
      });
      await technologyUsedRefetch();
    }
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <DataGrid
        sx={validateTechnologyStyles.dataGrid}
        disableRowSelectionOnClick
        rows={technologyUsedNodes || []}
        columns={technologyColumns}
        loading={technologyUsedLoading}
        rowSelectionModel={selectedTechIds}
        getRowId={(row) => row?.dbId}
        onRowSelectionModelChange={(ids: number[]) => setSelectedTechIds(ids)}
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        slots={{
          footer: () => (
            <CustomFooter
              onRefresh={() => technologyUsedRefetch()}
              onDeleteSelected={() => handleDeleteSelectedTechnologies(selectedTechIds)}
            />
          ),
        }}
      />
      <TechnologySelect
        categories={stackshareToolCategoriesNodes || []}
        technologies={stackshareToolsNodes || []}
        technReferenceUrl={technReferenceUrl}
        state={technology}
        onChangeTechnology={handleTechnologyChange}
        onChangeTechReferenceUrl={handleChangeTechReferenceUrl}
        onHandleAddTechnology={handleAddTechnology}
        technologiesLoading={stackshareToolsLoading}
        categoriesLoading={stackshareToolCategoriesLoading}
      />
      <Backdrop open={deleteTechnologyLoading || createUpdateCompanyTechUseLoading} />
    </Box>
  );
};

export default ValidateTechnology;
