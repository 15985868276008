import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AddBuyAndBuildTarget = {
  __typename?: 'AddBuyAndBuildTarget';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AddBuyAndBuildTargetsFromSourcescrub = {
  __typename?: 'AddBuyAndBuildTargetsFromSourcescrub';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AddCustomerSegmentMutation = {
  __typename?: 'AddCustomerSegmentMutation';
  definitionUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  segmentDescription?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddIndustry = {
  __typename?: 'AddIndustry';
  companyId?: Maybe<Scalars['Int']['output']>;
  referencedAt?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
};

export type AddJobDescriptionUrl = {
  __typename?: 'AddJobDescriptionURL';
  jobDescriptionUrl?: Maybe<Scalars['String']['output']>;
};

export type AddSoftwareCategoryToCompany = {
  __typename?: 'AddSoftwareCategoryToCompany';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BabCompanyType = Node & {
  __typename?: 'BABCompanyType';
  address?: Maybe<Scalars['String']['output']>;
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  buyAndBuildSources?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  buyAndBuildTargets?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  city?: Maybe<Scalars['String']['output']>;
  companyAnalyses?: Maybe<CompanyAnalysisTypeConnection>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  crawlResults?: Maybe<Array<Maybe<RecentPlatformCrawlType>>>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  crawlingFinished?: Maybe<Scalars['Boolean']['output']>;
  crawlingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingInProgress?: Maybe<Scalars['Boolean']['output']>;
  crawlingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  doNotCrawl?: Maybe<Scalars['Boolean']['output']>;
  dueDilligence?: Maybe<Scalars['Boolean']['output']>;
  emailPattern?: Maybe<Scalars['String']['output']>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  genericShareholdings?: Maybe<GenericShareholdingSqlTypeConnection>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  initialSource?: Maybe<Scalars['String']['output']>;
  isBidPortfolioCompany?: Maybe<Scalars['Boolean']['output']>;
  isGenericIndustryCompany?: Maybe<Scalars['Boolean']['output']>;
  isItCompanyCompany?: Maybe<Scalars['Boolean']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSaasCompany?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  klazifyDomain?: Maybe<KlazifyDomainType>;
  klazifyDomains?: Maybe<KlazifyDomainConnection>;
  latestCrawlResult?: Maybe<GenericCrawlResultSqlType>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  managingDirector?: Maybe<Scalars['String']['output']>;
  maxFte?: Maybe<Scalars['Int']['output']>;
  maxFteAnalysis?: Maybe<Scalars['Int']['output']>;
  numberOfFailedCrawls?: Maybe<Scalars['Int']['output']>;
  openregCompanies?: Maybe<OpenRegCompanySqlTypeConnection>;
  parentCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  peOwned?: Maybe<Scalars['Boolean']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  precalculatedKpis?: Maybe<KpiPreCalculationTypeConnection>;
  privateOwned?: Maybe<Scalars['Boolean']['output']>;
  productCategorization?: Maybe<ProductCategorizationConnection>;
  shareholders?: Maybe<GenericShareHolderTypeConnection>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  sicCodeDetails?: Maybe<SicCodeSqlType>;
  street?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  subsidiaryOf?: Maybe<Scalars['Int']['output']>;
  summaryDescription?: Maybe<Scalars['String']['output']>;
  techUse?: Maybe<GenericCompanyTechUseConnection>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  validated?: Maybe<Scalars['Boolean']['output']>;
  validationDatetime?: Maybe<Scalars['DateTime']['output']>;
  validationUser?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type BabCompanyTypeBuyAndBuildSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeBuyAndBuildTargetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeCompanyAnalysesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeKlazifyDomainsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeOpenregCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypePrecalculatedKpisArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeProductCategorizationArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeShareholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeSubsidiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BabCompanyTypeTechUseArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BenchmarkBalanceSheetConnection = {
  __typename?: 'BenchmarkBalanceSheetConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkBalanceSheetEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkBalanceSheet` and its cursor. */
export type BenchmarkBalanceSheetEdge = {
  __typename?: 'BenchmarkBalanceSheetEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkBalanceSheetType>;
};

export enum BenchmarkBalanceSheetSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkBalanceSheetType = Node & {
  __typename?: 'BenchmarkBalanceSheetType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkCustomersAggregatedConnection = {
  __typename?: 'BenchmarkCustomersAggregatedConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkCustomersAggregatedEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkCustomersAggregated` and its cursor. */
export type BenchmarkCustomersAggregatedEdge = {
  __typename?: 'BenchmarkCustomersAggregatedEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkCustomersAggregatedType>;
};

export enum BenchmarkCustomersAggregatedSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkCustomersAggregatedType = Node & {
  __typename?: 'BenchmarkCustomersAggregatedType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkCustomersListConnection = {
  __typename?: 'BenchmarkCustomersListConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkCustomersListEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkCustomersList` and its cursor. */
export type BenchmarkCustomersListEdge = {
  __typename?: 'BenchmarkCustomersListEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkCustomersListType>;
};

export enum BenchmarkCustomersListSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkCustomersListType = Node & {
  __typename?: 'BenchmarkCustomersListType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkFteConnection = {
  __typename?: 'BenchmarkFTEConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkFteEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkFTE` and its cursor. */
export type BenchmarkFteEdge = {
  __typename?: 'BenchmarkFTEEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkFteType>;
};

export enum BenchmarkFteSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkFteType = Node & {
  __typename?: 'BenchmarkFTEType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkPlConnection = {
  __typename?: 'BenchmarkPLConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkPlEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkPL` and its cursor. */
export type BenchmarkPlEdge = {
  __typename?: 'BenchmarkPLEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkPlType>;
};

export enum BenchmarkPlSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkPlType = Node & {
  __typename?: 'BenchmarkPLType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkPricingConnection = {
  __typename?: 'BenchmarkPricingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkPricingEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkPricing` and its cursor. */
export type BenchmarkPricingEdge = {
  __typename?: 'BenchmarkPricingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkPricingType>;
};

export enum BenchmarkPricingSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkPricingType = Node & {
  __typename?: 'BenchmarkPricingType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkingBaseModelConnection = {
  __typename?: 'BenchmarkingBaseModelConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BenchmarkingBaseModelEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BenchmarkingBaseModel` and its cursor. */
export type BenchmarkingBaseModelEdge = {
  __typename?: 'BenchmarkingBaseModelEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BenchmarkingBaseModelType>;
};

export enum BenchmarkingBaseModelSortEnum {
  BenchmarkControlDataIdAsc = 'benchmark_control_data_id_asc',
  BenchmarkControlDataIdDesc = 'benchmark_control_data_id_desc',
  BmTypeAsc = 'bm_type_asc',
  BmTypeDesc = 'bm_type_desc',
  Category_1Asc = 'category_1_asc',
  Category_1Desc = 'category_1_desc',
  Category_2Asc = 'category_2_asc',
  Category_2Desc = 'category_2_desc',
  Category_3Asc = 'category_3_asc',
  Category_3Desc = 'category_3_desc',
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CurrencyAsc = 'currency_asc',
  CurrencyDesc = 'currency_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DeleteTimeAsc = 'delete_time_asc',
  DeleteTimeDesc = 'delete_time_desc',
  DeletedByAsc = 'deleted_by_asc',
  DeletedByDesc = 'deleted_by_desc',
  FlaggedAsErrorAsc = 'flagged_as_error_asc',
  FlaggedAsErrorDesc = 'flagged_as_error_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  HasBeenProcessedAsc = 'has_been_processed_asc',
  HasBeenProcessedDesc = 'has_been_processed_desc',
  HasBeenReplacedAsc = 'has_been_replaced_asc',
  HasBeenReplacedDesc = 'has_been_replaced_desc',
  HasBeenReviewedAsc = 'has_been_reviewed_asc',
  HasBeenReviewedDesc = 'has_been_reviewed_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsFirstActualAsc = 'is_first_actual_asc',
  IsFirstActualDesc = 'is_first_actual_desc',
  IsLastActualAsc = 'is_last_actual_asc',
  IsLastActualDesc = 'is_last_actual_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  OverwriteTimeAsc = 'overwrite_time_asc',
  OverwriteTimeDesc = 'overwrite_time_desc',
  PeriodAsc = 'period_asc',
  PeriodDesc = 'period_desc',
  PeriodEndDateAsc = 'period_end_date_asc',
  PeriodEndDateDesc = 'period_end_date_desc',
  PeriodStartDateAsc = 'period_start_date_asc',
  PeriodStartDateDesc = 'period_start_date_desc',
  PeriodTypeAsc = 'period_type_asc',
  PeriodTypeDesc = 'period_type_desc',
  PipedriveDealIdAsc = 'pipedrive_deal_id_asc',
  PipedriveDealIdDesc = 'pipedrive_deal_id_desc',
  ProcessTimeAsc = 'process_time_asc',
  ProcessTimeDesc = 'process_time_desc',
  ReplaceTimeAsc = 'replace_time_asc',
  ReplaceTimeDesc = 'replace_time_desc',
  ReplacedWithAsc = 'replaced_with_asc',
  ReplacedWithDesc = 'replaced_with_desc',
  ReviewTimeAsc = 'review_time_asc',
  ReviewTimeDesc = 'review_time_desc',
  ReviewedByAsc = 'reviewed_by_asc',
  ReviewedByDesc = 'reviewed_by_desc',
  UploadFileNameAsc = 'upload_file_name_asc',
  UploadFileNameDesc = 'upload_file_name_desc',
  UploadFilePathAsc = 'upload_file_path_asc',
  UploadFilePathDesc = 'upload_file_path_desc',
  UploadTimeAsc = 'upload_time_asc',
  UploadTimeDesc = 'upload_time_desc',
  UploaderNameAsc = 'uploader_name_asc',
  UploaderNameDesc = 'uploader_name_desc',
  UsedInKpiCalculationAsc = 'used_in_kpi_calculation_asc',
  UsedInKpiCalculationDesc = 'used_in_kpi_calculation_desc',
  ValuationLevelAsc = 'valuation_level_asc',
  ValuationLevelDesc = 'valuation_level_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc'
}

export type BenchmarkingBaseModelType = Node & {
  __typename?: 'BenchmarkingBaseModelType';
  benchmarkControlDataId?: Maybe<Scalars['Int']['output']>;
  bmType?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  flaggedAsError?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  hasBeenProcessed?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReplaced?: Maybe<Scalars['Boolean']['output']>;
  hasBeenReviewed?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isFirstActual?: Maybe<Scalars['Boolean']['output']>;
  isLastActual?: Maybe<Scalars['Boolean']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  overwriteTime?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodEndDate?: Maybe<Scalars['String']['output']>;
  periodStartDate?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  pipedriveDealId?: Maybe<Scalars['Int']['output']>;
  processTime?: Maybe<Scalars['DateTime']['output']>;
  replaceTime?: Maybe<Scalars['DateTime']['output']>;
  replacedWith?: Maybe<Scalars['Int']['output']>;
  reviewTime?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  uploadFileName?: Maybe<Scalars['String']['output']>;
  uploadFilePath?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
  usedInKpiCalculation?: Maybe<Scalars['String']['output']>;
  valuationLevel?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BuyAndBuildTargetAssessmentType = Node & {
  __typename?: 'BuyAndBuildTargetAssessmentType';
  address?: Maybe<Scalars['String']['output']>;
  analysis?: Maybe<GenericCompanyAnalysisConnection>;
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  buyAndBuildShortlist?: Maybe<Array<Maybe<BuyAndBuildTargetAssessmentType>>>;
  buyAndBuildSources?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  buyAndBuildTargets?: Maybe<GenericBuyAndBuildRelevanceConnection>;
  city?: Maybe<Scalars['String']['output']>;
  companyAnalyses?: Maybe<CompanyAnalysisTypeConnection>;
  competitionCpcKeywords?: Maybe<Array<Maybe<KeywordResultType>>>;
  competitionIntensity?: Maybe<Scalars['Float']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  crawlResults?: Maybe<GenericCrawlResultConnection>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  crawlingFinished?: Maybe<Scalars['Boolean']['output']>;
  crawlingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingInProgress?: Maybe<Scalars['Boolean']['output']>;
  crawlingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  customerIndustryGrowthRates?: Maybe<Array<Maybe<CustomerGrowthRateType>>>;
  customers?: Maybe<CompanyConnection>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  dealAssessmentFileUrl?: Maybe<DealAssessmentFileType>;
  doNotCrawl?: Maybe<Scalars['Boolean']['output']>;
  dueDilligence?: Maybe<Scalars['Boolean']['output']>;
  emailPattern?: Maybe<Scalars['String']['output']>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  findingsToken?: Maybe<FindingsTokenType>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fullCrawlTimestamps?: Maybe<FullCrawlTimestampConnection>;
  genericShareholdings?: Maybe<GenericShareholdingConnection>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  initialSource?: Maybe<Scalars['String']['output']>;
  isBidPortfolioCompany?: Maybe<Scalars['Boolean']['output']>;
  isGenericIndustryCompany?: Maybe<Scalars['Boolean']['output']>;
  isItCompanyCompany?: Maybe<Scalars['Boolean']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSaasCompany?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  klazifyDomain?: Maybe<KlazifyDomainType>;
  klazifyDomains?: Maybe<KlazifyDomainConnection>;
  latestCrawlResult?: Maybe<GenericCrawlResultSqlType>;
  latestDatetimeModified?: Maybe<Scalars['DateTime']['output']>;
  latestUrl?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinDescription?: Maybe<Scalars['String']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  managingDirector?: Maybe<Scalars['String']['output']>;
  matchUrl?: Maybe<Scalars['String']['output']>;
  maxFte?: Maybe<Scalars['Int']['output']>;
  maxFteAnalysis?: Maybe<Scalars['Int']['output']>;
  mlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  numberOfFailedCrawls?: Maybe<Scalars['Int']['output']>;
  openregCompanies?: Maybe<OpenRegCompanyConnection>;
  parentCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  peOwned?: Maybe<Scalars['Boolean']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  precalculatedKpis?: Maybe<KpiPrecalculationConnection>;
  privateOwned?: Maybe<Scalars['Boolean']['output']>;
  productCategories?: Maybe<ProductCategorizationConnection>;
  productCategorization?: Maybe<ProductCategorizationConnection>;
  productTechnologies?: Maybe<Array<Maybe<ProductTechnologylType>>>;
  recentCrawls?: Maybe<GenericCrawlResultConnection>;
  relevance?: Maybe<Scalars['Float']['output']>;
  shareholders?: Maybe<GenericShareHolderTypeConnection>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  sicCodeDetails?: Maybe<SicCodeSqlType>;
  softwareCategoryGrowthRates?: Maybe<Array<Maybe<SoftwareCategoryGrowthRateType>>>;
  specialCpcKeywords?: Maybe<Array<Maybe<KeywordResultType>>>;
  street?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  subsidiaryOf?: Maybe<Scalars['Int']['output']>;
  summaryDescription?: Maybe<Scalars['String']['output']>;
  teamQuality?: Maybe<TeamQualityType>;
  techUse?: Maybe<GenericCompanyTechUseConnection>;
  topCpcKeywords?: Maybe<Array<Maybe<KeywordResultType>>>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  validated?: Maybe<Scalars['Boolean']['output']>;
  validationDatetime?: Maybe<Scalars['DateTime']['output']>;
  validationUser?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type BuyAndBuildTargetAssessmentTypeAnalysisArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fteGt?: InputMaybe<Scalars['Int']['input']>;
  fteLt?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeBuyAndBuildSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeBuyAndBuildTargetsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isShortlisted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeCompanyAnalysesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeCrawlResultsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeCustomerIndustryGrowthRatesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeCustomersArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeFullCrawlTimestampsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  crawlWasFinished?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeKlazifyDomainsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeOpenregCompaniesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypePrecalculatedKpisArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeProductCategoriesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeProductCategorizationArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeProductTechnologiesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeRecentCrawlsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeShareholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeSoftwareCategoryGrowthRatesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeSubsidiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BuyAndBuildTargetAssessmentTypeTechUseArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BuyAndBuildTargetAssessmentTypeConnection = {
  __typename?: 'BuyAndBuildTargetAssessmentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BuyAndBuildTargetAssessmentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BuyAndBuildTargetAssessmentType` and its cursor. */
export type BuyAndBuildTargetAssessmentTypeEdge = {
  __typename?: 'BuyAndBuildTargetAssessmentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BuyAndBuildTargetAssessmentType>;
};

export type CleanupCrawlResults = {
  __typename?: 'CleanupCrawlResults';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyAnalysisType = Node & {
  __typename?: 'CompanyAnalysisType';
  company?: Maybe<BuyAndBuildTargetAssessmentType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  ebitda?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isItCompanyScore?: Maybe<Scalars['Float']['output']>;
  isSaasScore?: Maybe<Scalars['Float']['output']>;
  isSoftwareScore?: Maybe<Scalars['Float']['output']>;
  noOfCustomers?: Maybe<Scalars['Int']['output']>;
  noOfUsers?: Maybe<Scalars['Int']['output']>;
  recurringShare?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['Int']['output']>;
};

export type CompanyAnalysisTypeConnection = {
  __typename?: 'CompanyAnalysisTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyAnalysisTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CompanyAnalysisType` and its cursor. */
export type CompanyAnalysisTypeEdge = {
  __typename?: 'CompanyAnalysisTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyAnalysisType>;
};

export type CompanyAssesmentConnection = Node & {
  __typename?: 'CompanyAssesmentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyAssesmentEdge>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `CompanyAssesment` and its cursor. */
export type CompanyAssesmentEdge = {
  __typename?: 'CompanyAssesmentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyAssessmentType>;
};

export type CompanyAssessmentType = Node & {
  __typename?: 'CompanyAssessmentType';
  address?: Maybe<Scalars['String']['output']>;
  analysis?: Maybe<GenericCompanyAnalysisConnection>;
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  buyAndBuildShortlist?: Maybe<Array<Maybe<BuyAndBuildTargetAssessmentType>>>;
  buyAndBuildSources?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  buyAndBuildTargets?: Maybe<GenericBuyAndBuildRelevanceConnection>;
  city?: Maybe<Scalars['String']['output']>;
  companyAnalyses?: Maybe<CompanyAnalysisTypeConnection>;
  competitionCpcKeywords?: Maybe<Array<Maybe<KeywordResultType>>>;
  competitionIntensity?: Maybe<Scalars['Float']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  crawlResults?: Maybe<GenericCrawlResultConnection>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  crawlingFinished?: Maybe<Scalars['Boolean']['output']>;
  crawlingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingInProgress?: Maybe<Scalars['Boolean']['output']>;
  crawlingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  customerIndustryGrowthRates?: Maybe<Array<Maybe<CustomerGrowthRateType>>>;
  customers?: Maybe<CompanyConnection>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  dealAssessmentFileUrl?: Maybe<DealAssessmentFileType>;
  doNotCrawl?: Maybe<Scalars['Boolean']['output']>;
  dueDilligence?: Maybe<Scalars['Boolean']['output']>;
  emailPattern?: Maybe<Scalars['String']['output']>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  findingsToken?: Maybe<FindingsTokenType>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fullCrawlTimestamps?: Maybe<FullCrawlTimestampConnection>;
  genericShareholdings?: Maybe<GenericShareholdingConnection>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  initialSource?: Maybe<Scalars['String']['output']>;
  isBidPortfolioCompany?: Maybe<Scalars['Boolean']['output']>;
  isGenericIndustryCompany?: Maybe<Scalars['Boolean']['output']>;
  isItCompanyCompany?: Maybe<Scalars['Boolean']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSaasCompany?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  klazifyDomain?: Maybe<KlazifyDomainType>;
  klazifyDomains?: Maybe<KlazifyDomainConnection>;
  latestCrawlResult?: Maybe<GenericCrawlResultSqlType>;
  latestDatetimeModified?: Maybe<Scalars['DateTime']['output']>;
  latestUrl?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinDescription?: Maybe<Scalars['String']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  managingDirector?: Maybe<Scalars['String']['output']>;
  maxFte?: Maybe<Scalars['Int']['output']>;
  maxFteAnalysis?: Maybe<Scalars['Int']['output']>;
  mlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  numberOfFailedCrawls?: Maybe<Scalars['Int']['output']>;
  openregCompanies?: Maybe<OpenRegCompanyConnection>;
  parentCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  peOwned?: Maybe<Scalars['Boolean']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  precalculatedKpis?: Maybe<KpiPrecalculationConnection>;
  privateOwned?: Maybe<Scalars['Boolean']['output']>;
  productCategories?: Maybe<ProductCategorizationConnection>;
  productCategorization?: Maybe<ProductCategorizationConnection>;
  productTechnologies?: Maybe<Array<Maybe<ProductTechnologylType>>>;
  recentCrawls?: Maybe<GenericCrawlResultConnection>;
  shareholders?: Maybe<GenericShareHolderTypeConnection>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  sicCodeDetails?: Maybe<SicCodeSqlType>;
  softwareCategoryGrowthRates?: Maybe<Array<Maybe<SoftwareCategoryGrowthRateType>>>;
  specialCpcKeywords?: Maybe<Array<Maybe<KeywordResultType>>>;
  street?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  subsidiaryOf?: Maybe<Scalars['Int']['output']>;
  summaryDescription?: Maybe<Scalars['String']['output']>;
  teamQuality?: Maybe<TeamQualityType>;
  techUse?: Maybe<GenericCompanyTechUseConnection>;
  topCpcKeywords?: Maybe<Array<Maybe<KeywordResultType>>>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  validated?: Maybe<Scalars['Boolean']['output']>;
  validationDatetime?: Maybe<Scalars['DateTime']['output']>;
  validationUser?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type CompanyAssessmentTypeAnalysisArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fteGt?: InputMaybe<Scalars['Int']['input']>;
  fteLt?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeBuyAndBuildSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeBuyAndBuildTargetsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isShortlisted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeCompanyAnalysesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeCrawlResultsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeCustomerIndustryGrowthRatesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeCustomersArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeFullCrawlTimestampsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  crawlWasFinished?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeKlazifyDomainsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeOpenregCompaniesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypePrecalculatedKpisArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeProductCategoriesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeProductCategorizationArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeProductTechnologiesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeRecentCrawlsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeShareholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeSoftwareCategoryGrowthRatesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeSubsidiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAssessmentTypeTechUseArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyType>;
};

export type CompanyInboxType = Node & {
  __typename?: 'CompanyInboxType';
  address?: Maybe<Scalars['String']['output']>;
  analysis?: Maybe<GenericCompanyAnalysisConnection>;
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  buyAndBuildSources?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  buyAndBuildTargets?: Maybe<GenericBuyAndBuildRelevanceConnection>;
  city?: Maybe<Scalars['String']['output']>;
  companyAnalyses?: Maybe<CompanyAnalysisTypeConnection>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  crawlResults?: Maybe<GenericCrawlResultConnection>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  crawlingFinished?: Maybe<Scalars['Boolean']['output']>;
  crawlingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingInProgress?: Maybe<Scalars['Boolean']['output']>;
  crawlingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  dealAssessmentFileUrl?: Maybe<DealAssessmentFileType>;
  doNotCrawl?: Maybe<Scalars['Boolean']['output']>;
  dueDilligence?: Maybe<Scalars['Boolean']['output']>;
  emailPattern?: Maybe<Scalars['String']['output']>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fteAnalysisResult?: Maybe<Scalars['Float']['output']>;
  fullCrawlTimestamps?: Maybe<FullCrawlTimestampConnection>;
  genericShareholdings?: Maybe<GenericShareholdingConnection>;
  hadMlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  initialSource?: Maybe<Scalars['String']['output']>;
  isBidPortfolioCompany?: Maybe<Scalars['Boolean']['output']>;
  isGenericIndustryCompany?: Maybe<Scalars['Boolean']['output']>;
  isItCompanyCompany?: Maybe<Scalars['Boolean']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSaasCompany?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  klazifyDomain?: Maybe<KlazifyDomainType>;
  klazifyDomains?: Maybe<KlazifyDomainConnection>;
  latestCrawlResult?: Maybe<GenericCrawlResultSqlType>;
  latestDatetimeModified?: Maybe<Scalars['DateTime']['output']>;
  latestUrl?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinDescription?: Maybe<Scalars['String']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  managingDirector?: Maybe<Scalars['String']['output']>;
  maxFte?: Maybe<Scalars['Int']['output']>;
  maxFteAnalysis?: Maybe<Scalars['Int']['output']>;
  mlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  numberOfFailedCrawls?: Maybe<Scalars['Int']['output']>;
  openregCompanies?: Maybe<OpenRegCompanyConnection>;
  pageInfo?: Maybe<DbIdPageInfo>;
  parentCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  peOwned?: Maybe<Scalars['Boolean']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  precalculatedKpis?: Maybe<KpiPreCalculationTypeConnection>;
  privateOwned?: Maybe<Scalars['Boolean']['output']>;
  productCategorization?: Maybe<ProductCategorizationConnection>;
  recentCrawls?: Maybe<GenericCrawlResultConnection>;
  shareholders?: Maybe<GenericShareHolderTypeConnection>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  sicCodeDetails?: Maybe<SicCodeSqlType>;
  street?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  subsidiaryOf?: Maybe<Scalars['Int']['output']>;
  systemType?: Maybe<Scalars['String']['output']>;
  techUse?: Maybe<GenericCompanyTechUseConnection>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  validated?: Maybe<Scalars['Boolean']['output']>;
  validationDatetime?: Maybe<Scalars['DateTime']['output']>;
  validationUser?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type CompanyInboxTypeAnalysisArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fteGt?: InputMaybe<Scalars['Int']['input']>;
  fteLt?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeBuyAndBuildSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeBuyAndBuildTargetsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isShortlisted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeCompanyAnalysesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeCrawlResultsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeFullCrawlTimestampsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  crawlWasFinished?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeKlazifyDomainsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeOpenregCompaniesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypePrecalculatedKpisArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeProductCategorizationArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeRecentCrawlsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeShareholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeSubsidiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInboxTypeTechUseArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyType = Node & {
  __typename?: 'CompanyType';
  address?: Maybe<Scalars['String']['output']>;
  analysis?: Maybe<GenericCompanyAnalysisConnection>;
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  buyAndBuildSources?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  buyAndBuildTargets?: Maybe<GenericBuyAndBuildRelevanceConnection>;
  city?: Maybe<Scalars['String']['output']>;
  companyAnalyses?: Maybe<CompanyAnalysisTypeConnection>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  crawlResults?: Maybe<GenericCrawlResultConnection>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  crawlingFinished?: Maybe<Scalars['Boolean']['output']>;
  crawlingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingInProgress?: Maybe<Scalars['Boolean']['output']>;
  crawlingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  dealAssessmentFileUrl?: Maybe<DealAssessmentFileType>;
  doNotCrawl?: Maybe<Scalars['Boolean']['output']>;
  dueDilligence?: Maybe<Scalars['Boolean']['output']>;
  emailPattern?: Maybe<Scalars['String']['output']>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fullCrawlTimestamps?: Maybe<FullCrawlTimestampConnection>;
  genericShareholdings?: Maybe<GenericShareholdingConnection>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  initialSource?: Maybe<Scalars['String']['output']>;
  isBidPortfolioCompany?: Maybe<Scalars['Boolean']['output']>;
  isGenericIndustryCompany?: Maybe<Scalars['Boolean']['output']>;
  isItCompanyCompany?: Maybe<Scalars['Boolean']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSaasCompany?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  klazifyDomain?: Maybe<KlazifyDomainType>;
  klazifyDomains?: Maybe<KlazifyDomainConnection>;
  latestCrawlResult?: Maybe<GenericCrawlResultSqlType>;
  latestDatetimeModified?: Maybe<Scalars['DateTime']['output']>;
  latestUrl?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinDescription?: Maybe<Scalars['String']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  managingDirector?: Maybe<Scalars['String']['output']>;
  maxFte?: Maybe<Scalars['Int']['output']>;
  maxFteAnalysis?: Maybe<Scalars['Int']['output']>;
  mlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  numberOfFailedCrawls?: Maybe<Scalars['Int']['output']>;
  openregCompanies?: Maybe<OpenRegCompanyConnection>;
  parentCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  peOwned?: Maybe<Scalars['Boolean']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  precalculatedKpis?: Maybe<KpiPreCalculationTypeConnection>;
  privateOwned?: Maybe<Scalars['Boolean']['output']>;
  productCategorization?: Maybe<ProductCategorizationConnection>;
  recentCrawls?: Maybe<GenericCrawlResultConnection>;
  shareholders?: Maybe<GenericShareHolderTypeConnection>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  sicCodeDetails?: Maybe<SicCodeSqlType>;
  street?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  subsidiaryOf?: Maybe<Scalars['Int']['output']>;
  techUse?: Maybe<GenericCompanyTechUseConnection>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  validated?: Maybe<Scalars['Boolean']['output']>;
  validationDatetime?: Maybe<Scalars['DateTime']['output']>;
  validationUser?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type CompanyTypeAnalysisArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fteGt?: InputMaybe<Scalars['Int']['input']>;
  fteLt?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeBuyAndBuildSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeBuyAndBuildTargetsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isShortlisted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCompanyAnalysesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeCrawlResultsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeFullCrawlTimestampsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  crawlWasFinished?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeKlazifyDomainsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeOpenregCompaniesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypePrecalculatedKpisArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeProductCategorizationArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeRecentCrawlsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeShareholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeSubsidiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTypeTechUseArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyWithLatestUpload = {
  __typename?: 'CompanyWithLatestUpload';
  company?: Maybe<CompanyType>;
  uploadTime?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
};

export type CortexConfigType = {
  __typename?: 'CortexConfigType';
  assessmentXlsTemplateId?: Maybe<Scalars['String']['output']>;
  assessmentXlsUploadTime?: Maybe<Scalars['DateTime']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  initialBbWeightForSourcescrubTags?: Maybe<Scalars['Float']['output']>;
  macAcceptableWebsitePopularity?: Maybe<Scalars['Int']['output']>;
  maxCrawledPagesPerDomain?: Maybe<Scalars['Int']['output']>;
  maxRecursionDepth?: Maybe<Scalars['Int']['output']>;
  minFacesForGroupPicture?: Maybe<Scalars['Int']['output']>;
  mongoSiteCacheInDays?: Maybe<Scalars['Int']['output']>;
  pipedriveCortexLabelId?: Maybe<Scalars['Int']['output']>;
  pipedriveDfmTargetDealsFilterId?: Maybe<Scalars['Int']['output']>;
  pipedriveHotLeadLabelId?: Maybe<Scalars['Int']['output']>;
  pipedriveOpenTargetDealsLimit?: Maybe<Scalars['Int']['output']>;
  pipedriveSoftwareCompanyOrgTypeIndicator?: Maybe<Scalars['Int']['output']>;
  postToZapier?: Maybe<Scalars['Boolean']['output']>;
  sourcescrubTagIdForGenericGenericCompanyLabel?: Maybe<Scalars['Int']['output']>;
};

export type CortexFeedbackPipedriveBridge = {
  __typename?: 'CortexFeedbackPipedriveBridge';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CortexIdType = {
  __typename?: 'CortexIdType';
  cortexId?: Maybe<Scalars['Int']['output']>;
};

export type CrawlerFindingTokenConnection = {
  __typename?: 'CrawlerFindingTokenConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CrawlerFindingTokenEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `CrawlerFindingToken` and its cursor. */
export type CrawlerFindingTokenEdge = {
  __typename?: 'CrawlerFindingTokenEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CrawlerFindingTokenType>;
};

export type CrawlerFindingTokenType = {
  __typename?: 'CrawlerFindingTokenType';
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  genericCompanyAnalysisId?: Maybe<Scalars['Int']['output']>;
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  noOfVotes?: Maybe<Scalars['Int']['output']>;
  tokenizedTitle?: Maybe<Scalars['String']['output']>;
  tokenizedUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  voteDownUrl?: Maybe<Scalars['String']['output']>;
  voteUpUrl?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type CreateBenchmarkBalanceSheet = {
  __typename?: 'CreateBenchmarkBalanceSheet';
  benchmarkingData?: Maybe<BenchmarkBalanceSheetType>;
};

export type CreateBenchmarkCustomersAggregated = {
  __typename?: 'CreateBenchmarkCustomersAggregated';
  benchmarkingData?: Maybe<BenchmarkCustomersAggregatedType>;
};

export type CreateBenchmarkCustomersList = {
  __typename?: 'CreateBenchmarkCustomersList';
  benchmarkingData?: Maybe<BenchmarkCustomersListType>;
};

export type CreateBenchmarkFte = {
  __typename?: 'CreateBenchmarkFTE';
  benchmarkingData?: Maybe<BenchmarkFteType>;
};

export type CreateBenchmarkPl = {
  __typename?: 'CreateBenchmarkPL';
  benchmarkingData?: Maybe<BenchmarkPlType>;
};

export type CreateBenchmarkPricing = {
  __typename?: 'CreateBenchmarkPricing';
  benchmarkingData?: Maybe<BenchmarkPricingType>;
};

export type CreateUpdateCompanyTechUse = {
  __typename?: 'CreateUpdateCompanyTechUse';
  companyId?: Maybe<Scalars['Int']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  findingUrl?: Maybe<Scalars['String']['output']>;
  genericCompanyTechUseId?: Maybe<Scalars['Int']['output']>;
  markedAsFalsePositive?: Maybe<Scalars['Boolean']['output']>;
  stackshareToolId?: Maybe<Scalars['Int']['output']>;
};

export type CreateUpdateGenericShareholder = {
  __typename?: 'CreateUpdateGenericShareholder';
  dbId?: Maybe<Scalars['Int']['output']>;
  genericCompanyId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  typeOfInvestor?: Maybe<Scalars['String']['output']>;
  yearBornOrFounded?: Maybe<Scalars['Int']['output']>;
};

export type CreateUpdateGenericShareholding = {
  __typename?: 'CreateUpdateGenericShareholding';
  dbId?: Maybe<Scalars['Int']['output']>;
  genericCompanyId?: Maybe<Scalars['Int']['output']>;
  genericShareholderId?: Maybe<Scalars['Int']['output']>;
  holding?: Maybe<Scalars['Float']['output']>;
  holdingCount?: Maybe<Scalars['Int']['output']>;
};

export type CreateUpdateKlazifyCategory = {
  __typename?: 'CreateUpdateKlazifyCategory';
  dbId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subCategory1?: Maybe<Scalars['String']['output']>;
  subCategory2?: Maybe<Scalars['String']['output']>;
  subCategory3?: Maybe<Scalars['String']['output']>;
  subCategory4?: Maybe<Scalars['String']['output']>;
};

export type CreateUpdateXoviKeyword = {
  __typename?: 'CreateUpdateXoviKeyword';
  change?: Maybe<Scalars['Int']['output']>;
  competitorDensity?: Maybe<Scalars['Int']['output']>;
  cpc?: Maybe<Scalars['Float']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  markedAsIrrelevant?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  searchEngine?: Maybe<Scalars['String']['output']>;
  sevo?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateXoviKeywords = {
  __typename?: 'CreateXoviKeywords';
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
};

export type CustomerGrowthRateRawConnection = {
  __typename?: 'CustomerGrowthRateRawConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerGrowthRateRawEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `CustomerGrowthRateRaw` and its cursor. */
export type CustomerGrowthRateRawEdge = {
  __typename?: 'CustomerGrowthRateRawEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerGrowthRateRawType>;
};

export type CustomerGrowthRateRawType = Node & {
  __typename?: 'CustomerGrowthRateRawType';
  adjustedMedianGrowth?: Maybe<Scalars['Float']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  ebitdaAssets?: Maybe<Scalars['Float']['output']>;
  ebitdaSales?: Maybe<Scalars['Float']['output']>;
  evAssets?: Maybe<Scalars['Float']['output']>;
  evEbitda?: Maybe<Scalars['Float']['output']>;
  genericCompanyId?: Maybe<Scalars['Int']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  oneYearSalesGrowth?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  sampleSize?: Maybe<Scalars['Int']['output']>;
  sic?: Maybe<Scalars['Int']['output']>;
  sicDesc?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
  zScore?: Maybe<Scalars['Float']['output']>;
};

export type CustomerGrowthRateType = Node & {
  __typename?: 'CustomerGrowthRateType';
  adjustedMedianGrowth?: Maybe<Scalars['Float']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  sampleSize?: Maybe<Scalars['Int']['output']>;
  sicDesc?: Maybe<Scalars['String']['output']>;
};

export type CustomerIndustrySicCodeConnection = {
  __typename?: 'CustomerIndustrySicCodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerIndustrySicCodeEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `CustomerIndustrySicCode` and its cursor. */
export type CustomerIndustrySicCodeEdge = {
  __typename?: 'CustomerIndustrySicCodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerIndustrySicCodeSqlType>;
};

export type CustomerIndustrySicCodeSqlType = Node & {
  __typename?: 'CustomerIndustrySicCodeSQLType';
  active?: Maybe<Scalars['Boolean']['output']>;
  arr?: Maybe<Scalars['Int']['output']>;
  computedCustomerIndustry?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<CompanyType>;
  customerReferenceName?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDdDatapoint?: Maybe<Scalars['Boolean']['output']>;
  lossReason?: Maybe<Scalars['String']['output']>;
  lostYear?: Maybe<Scalars['Int']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  markedAsIrrelevant?: Maybe<Scalars['Boolean']['output']>;
  nps?: Maybe<Scalars['Int']['output']>;
  referenceCompanyId?: Maybe<Scalars['Int']['output']>;
  referencedAt?: Maybe<Scalars['String']['output']>;
  revenueYearOne?: Maybe<Scalars['Int']['output']>;
  revenueYearTwo?: Maybe<Scalars['Int']['output']>;
  sicCode?: Maybe<SicCodeType>;
  softwareCompanyId?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
  wonYear?: Maybe<Scalars['Int']['output']>;
};

export type CustomerReferenceRawType = Node & {
  __typename?: 'CustomerReferenceRawType';
  IAB1?: Maybe<Scalars['String']['output']>;
  IAB2?: Maybe<Scalars['String']['output']>;
  IAB3?: Maybe<Scalars['String']['output']>;
  ISDd?: Maybe<Scalars['Boolean']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  arr?: Maybe<Scalars['Int']['output']>;
  computedCustomerIndustry?: Maybe<Scalars['String']['output']>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  customerReferenceName?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  findingUrl?: Maybe<Scalars['String']['output']>;
  genericCustomer?: Maybe<Scalars['Boolean']['output']>;
  genericCustomerReferenceId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDdDatapoint?: Maybe<Scalars['Boolean']['output']>;
  lossReason?: Maybe<Scalars['String']['output']>;
  lostYear?: Maybe<Scalars['Int']['output']>;
  markedAsDeleted?: Maybe<Scalars['Boolean']['output']>;
  markedAsIrrelevant?: Maybe<Scalars['Boolean']['output']>;
  nps?: Maybe<Scalars['Int']['output']>;
  referenceCompanyId?: Maybe<Scalars['Int']['output']>;
  referencedAt?: Maybe<Scalars['String']['output']>;
  revenueYearOne?: Maybe<Scalars['Int']['output']>;
  revenueYearTwo?: Maybe<Scalars['Int']['output']>;
  rn?: Maybe<Scalars['Int']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  softwareCompanyId?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
  wonYear?: Maybe<Scalars['Int']['output']>;
};

export type CustomerReferenceRawTypeConnection = {
  __typename?: 'CustomerReferenceRawTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerReferenceRawTypeEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `CustomerReferenceRawType` and its cursor. */
export type CustomerReferenceRawTypeEdge = {
  __typename?: 'CustomerReferenceRawTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerReferenceRawType>;
};

export type CustomerSegment = {
  __typename?: 'CustomerSegment';
  customerDomain?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerSegment?: Maybe<Scalars['String']['output']>;
  customerSegmentCertainty?: Maybe<Scalars['String']['output']>;
  customerSegmentDescription?: Maybe<Scalars['String']['output']>;
  customerSegmentKeyword?: Maybe<Scalars['String']['output']>;
  klazifyCategory?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  softwareDomain?: Maybe<Scalars['String']['output']>;
  softwareId?: Maybe<Scalars['Int']['output']>;
};

export type CustomerSegmentInput = {
  bidGroupCompanyId: Scalars['Int']['input'];
  definitionUrl?: InputMaybe<Scalars['String']['input']>;
  keyword1: Scalars['String']['input'];
  keyword2?: InputMaybe<Scalars['String']['input']>;
  keyword3?: InputMaybe<Scalars['String']['input']>;
  keyword4?: InputMaybe<Scalars['String']['input']>;
  keyword5?: InputMaybe<Scalars['String']['input']>;
  parentSegmentId?: InputMaybe<Scalars['String']['input']>;
  segmentDescription: Scalars['String']['input'];
  segmentName: Scalars['String']['input'];
};

export type CustomerSegmentType = {
  __typename?: 'CustomerSegmentType';
  bidGroupCompanyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  definitionUrl?: Maybe<Scalars['String']['output']>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keyword1?: Maybe<Scalars['String']['output']>;
  keyword2?: Maybe<Scalars['String']['output']>;
  keyword3?: Maybe<Scalars['String']['output']>;
  keyword4?: Maybe<Scalars['String']['output']>;
  keyword5?: Maybe<Scalars['String']['output']>;
  parentSegment?: Maybe<CustomerSegmentType>;
  segmentDescription?: Maybe<Scalars['String']['output']>;
  segmentDescriptionEmbedding?: Maybe<Scalars['JSONString']['output']>;
  segmentName?: Maybe<Scalars['String']['output']>;
  subsegmentOf?: Maybe<Scalars['Int']['output']>;
  subsegments?: Maybe<Array<Maybe<CustomerSegmentType>>>;
};

export type DbIdPageInfo = {
  __typename?: 'DbIdPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  endId?: Maybe<Scalars['Int']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
  startId?: Maybe<Scalars['Int']['output']>;
};

export type DealAssessmentFileType = {
  __typename?: 'DealAssessmentFileType';
  dateOfFile?: Maybe<Scalars['DateTime']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  generationTriggered?: Maybe<Scalars['Boolean']['output']>;
  noDataUploaded?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCompanyTechUse = {
  __typename?: 'DeleteCompanyTechUse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCustomerReference = {
  __typename?: 'DeleteCustomerReference';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCustomerSegmentMutation = {
  __typename?: 'DeleteCustomerSegmentMutation';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteGenericShareholder = {
  __typename?: 'DeleteGenericShareholder';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteGenericShareholding = {
  __typename?: 'DeleteGenericShareholding';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DownvoteFindingsToken = {
  __typename?: 'DownvoteFindingsToken';
  ok?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<FindingsTokenType>;
};

export type EditCompany = {
  __typename?: 'EditCompany';
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type EmployeeStaticsRawConnection = {
  __typename?: 'EmployeeStaticsRawConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmployeeStaticsRawEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `EmployeeStaticsRaw` and its cursor. */
export type EmployeeStaticsRawEdge = {
  __typename?: 'EmployeeStaticsRawEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<EmployeeStaticsRawType>;
};

export type EmployeeStaticsRawType = Node & {
  __typename?: 'EmployeeStaticsRawType';
  averageTotalExperienceYears?: Maybe<Scalars['Float']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  employeeId?: Maybe<Scalars['Int']['output']>;
  employmentDuration?: Maybe<Scalars['Int']['output']>;
  employmentEnd?: Maybe<Scalars['DateTime']['output']>;
  employmentStart?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  medianEmploymentMonths?: Maybe<Scalars['Float']['output']>;
  medianPreviousEmployers?: Maybe<Scalars['Float']['output']>;
  medianTotalEmploymentMonths?: Maybe<Scalars['Float']['output']>;
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  teamQualityPercentage?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
};

export type ExcelRowInput = {
  cells: Array<InputMaybe<Scalars['String']['input']>>;
};

export type FindingsTokenType = Node & {
  __typename?: 'FindingsTokenType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type FullCrawlTimestampConnection = {
  __typename?: 'FullCrawlTimestampConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FullCrawlTimestampEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FullCrawlTimestamp` and its cursor. */
export type FullCrawlTimestampEdge = {
  __typename?: 'FullCrawlTimestampEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FullCrawlTimestampSqlType>;
};

export type FullCrawlTimestampSqlType = Node & {
  __typename?: 'FullCrawlTimestampSQLType';
  aggregateKeywordsTask?: Maybe<Scalars['Boolean']['output']>;
  aggregateKeywordsTaskAttempts?: Maybe<Scalars['Boolean']['output']>;
  asOfDate?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyTechstackAnalysis?: Maybe<Scalars['Boolean']['output']>;
  companyTechstackAnalysisAttempts?: Maybe<Scalars['Int']['output']>;
  crawlWasFinished?: Maybe<Scalars['Boolean']['output']>;
  customerReferenceAnalysis?: Maybe<Scalars['Boolean']['output']>;
  customerReferenceAnalysisAttempts?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  doTheCrawl?: Maybe<Scalars['Boolean']['output']>;
  employeeLinkedinDataAnalysis?: Maybe<Scalars['Boolean']['output']>;
  employeeLinkedinDataAnalysisAttempts?: Maybe<Scalars['Int']['output']>;
  gatherEmployeeLinkedinData?: Maybe<Scalars['Boolean']['output']>;
  gatherEmployeeLinkedinDataAttempts?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  imageIdentification?: Maybe<Scalars['Boolean']['output']>;
  imageIdentificationAttempts?: Maybe<Scalars['Int']['output']>;
  imageRecognition?: Maybe<Scalars['Boolean']['output']>;
  imageRecognitionAttempts?: Maybe<Scalars['Int']['output']>;
  initialKlazzification?: Maybe<Scalars['Boolean']['output']>;
  initialKlazzificationAttempts?: Maybe<Scalars['Int']['output']>;
  isCrawlOfCustomer?: Maybe<Scalars['Boolean']['output']>;
  keywordAggregation?: Maybe<Scalars['Boolean']['output']>;
  keywordAggregationAttempts?: Maybe<Scalars['Int']['output']>;
  otherNonSwCrawlReason?: Maybe<Scalars['String']['output']>;
  pageClassification?: Maybe<Scalars['Boolean']['output']>;
  pageClassificationAttempts?: Maybe<Scalars['Int']['output']>;
  productClassification?: Maybe<Scalars['Boolean']['output']>;
  productClassificationAttempts?: Maybe<Scalars['Int']['output']>;
  resultPostedToPipedrive?: Maybe<Scalars['Boolean']['output']>;
  resultPostedToPipedriveAttempts?: Maybe<Scalars['Int']['output']>;
  searchCompaniesForKeywordsOfCompanyTask?: Maybe<Scalars['Boolean']['output']>;
  searchCompaniesForKeywordsOfCompanyTaskAttempts?: Maybe<Scalars['Int']['output']>;
  semanticAggregation?: Maybe<Scalars['Boolean']['output']>;
  semanticAggregationAttempts?: Maybe<Scalars['Int']['output']>;
  socialNetworkAnalysis?: Maybe<Scalars['Boolean']['output']>;
  socialNetworkAnalysisAttempts?: Maybe<Scalars['Int']['output']>;
  taskPrepDataForExpTasksAfterValidationAttempts?: Maybe<Scalars['Int']['output']>;
  taskPrepareDataForExecuteExpensiveTasksAfterValidation?: Maybe<Scalars['Boolean']['output']>;
  textCrawl?: Maybe<Scalars['Boolean']['output']>;
  textCrawlAttempts?: Maybe<Scalars['Int']['output']>;
  xoviAnalysis?: Maybe<Scalars['Boolean']['output']>;
  xoviAnalysisAttempts?: Maybe<Scalars['Int']['output']>;
  xoviApiCallForCustomersOfCompany?: Maybe<Scalars['Boolean']['output']>;
  xoviApiCallForCustomersOfCompanyAttempts?: Maybe<Scalars['Int']['output']>;
};

export type GenericBuyAndBuildRelevanceConnection = {
  __typename?: 'GenericBuyAndBuildRelevanceConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericBuyAndBuildRelevanceEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `GenericBuyAndBuildRelevance` and its cursor. */
export type GenericBuyAndBuildRelevanceEdge = {
  __typename?: 'GenericBuyAndBuildRelevanceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericBuyAndBuildRelevanceSqlType>;
};

export type GenericBuyAndBuildRelevanceSqlType = Node & {
  __typename?: 'GenericBuyAndBuildRelevanceSQLType';
  avgKeywordRelevance?: Maybe<Scalars['Float']['output']>;
  avgSemanticFulltextRelevance?: Maybe<Scalars['Float']['output']>;
  avgSemanticShortRelevance?: Maybe<Scalars['Float']['output']>;
  buyAndBuildNotes?: Maybe<Scalars['String']['output']>;
  buyAndBuildRelevance?: Maybe<Scalars['Float']['output']>;
  company?: Maybe<BabCompanyType>;
  cosineSimilarityIndustry?: Maybe<Scalars['Float']['output']>;
  cosineSimilarityLinkedin?: Maybe<Scalars['Float']['output']>;
  cosineSimilaritySoftwareCategory?: Maybe<Scalars['Float']['output']>;
  cosineSimilarityWebsite?: Maybe<Scalars['Float']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  hasSourcescrubTags?: Maybe<Scalars['Boolean']['output']>;
  highestSemanticFulltextRelevance?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isShortlisted?: Maybe<Scalars['Boolean']['output']>;
  isShortlistedCompany1?: Maybe<Scalars['Boolean']['output']>;
  isShortlistedCompany2?: Maybe<Scalars['Boolean']['output']>;
  keywordAlternative?: Maybe<Scalars['String']['output']>;
  keywordId?: Maybe<Scalars['Int']['output']>;
  lastPipedrivePushDate?: Maybe<Scalars['DateTime']['output']>;
  manuallyLabeledScore?: Maybe<Scalars['Float']['output']>;
  matchUrl?: Maybe<Scalars['String']['output']>;
  noOfKeywordsFound?: Maybe<Scalars['Int']['output']>;
  noOfOverlappingCustomers?: Maybe<Scalars['Int']['output']>;
  noOfOverlappingSwCategories?: Maybe<Scalars['Int']['output']>;
  noOfSemanticShortHits?: Maybe<Scalars['Int']['output']>;
  noOfSematicFulltextHits?: Maybe<Scalars['Int']['output']>;
  platformIsHorizontal?: Maybe<Scalars['Boolean']['output']>;
  shareOfCustomersServedByOtherCompanies?: Maybe<Scalars['Float']['output']>;
  softwareCompany1Id?: Maybe<Scalars['Int']['output']>;
  softwareCompany2Id?: Maybe<Scalars['Int']['output']>;
  softwareCompany2IsCompetitor?: Maybe<Scalars['Boolean']['output']>;
  softwareCompany2IsNotBuyable?: Maybe<Scalars['Boolean']['output']>;
  targetCompanyIsHorizontal?: Maybe<Scalars['Boolean']['output']>;
  totalNoOfCustomers?: Maybe<Scalars['Int']['output']>;
};

export type GenericCompanyAnalysisConnection = {
  __typename?: 'GenericCompanyAnalysisConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericCompanyAnalysisEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GenericCompanyAnalysis` and its cursor. */
export type GenericCompanyAnalysisEdge = {
  __typename?: 'GenericCompanyAnalysisEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericCompanyAnalysisSqlType>;
};

export type GenericCompanyAnalysisSqlType = Node & {
  __typename?: 'GenericCompanyAnalysisSQLType';
  company?: Maybe<BuyAndBuildTargetAssessmentType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  ebitda?: Maybe<Scalars['Int']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isItCompanyScore?: Maybe<Scalars['Float']['output']>;
  isSaasScore?: Maybe<Scalars['Float']['output']>;
  isSoftwareScore?: Maybe<Scalars['Float']['output']>;
  noOfCustomers?: Maybe<Scalars['Int']['output']>;
  noOfUsers?: Maybe<Scalars['Int']['output']>;
  recurringShare?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['Int']['output']>;
};

export enum GenericCompanySortEnum {
  AddressAsc = 'address_asc',
  AddressDesc = 'address_desc',
  BenchmarkingIndexAsc = 'benchmarking_index_asc',
  BenchmarkingIndexDesc = 'benchmarking_index_desc',
  CityAsc = 'city_asc',
  CityDesc = 'city_desc',
  CountryAsc = 'country_asc',
  CountryDesc = 'country_desc',
  CourtAsc = 'court_asc',
  CourtDesc = 'court_desc',
  CrawlUrlAsc = 'crawl_url_asc',
  CrawlUrlDesc = 'crawl_url_desc',
  CrawlingFinishedAsc = 'crawling_finished_asc',
  CrawlingFinishedAtAsc = 'crawling_finished_at_asc',
  CrawlingFinishedAtDesc = 'crawling_finished_at_desc',
  CrawlingFinishedDesc = 'crawling_finished_desc',
  CrawlingInProgressAsc = 'crawling_in_progress_asc',
  CrawlingInProgressDesc = 'crawling_in_progress_desc',
  CrawlingStartedAtAsc = 'crawling_started_at_asc',
  CrawlingStartedAtDesc = 'crawling_started_at_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DoNotCrawlAsc = 'do_not_crawl_asc',
  DoNotCrawlDesc = 'do_not_crawl_desc',
  DueDilligenceAsc = 'due_dilligence_asc',
  DueDilligenceDesc = 'due_dilligence_desc',
  EmailPatternAsc = 'email_pattern_asc',
  EmailPatternDesc = 'email_pattern_desc',
  FoundingYearAsc = 'founding_year_asc',
  FoundingYearDesc = 'founding_year_desc',
  HasBeenValidatedAsc = 'has_been_validated_asc',
  HasBeenValidatedDesc = 'has_been_validated_desc',
  HrbNumberAsc = 'hrb_number_asc',
  HrbNumberDesc = 'hrb_number_desc',
  IabDomainCategoryAsc = 'iab_domain_category_asc',
  IabDomainCategoryDesc = 'iab_domain_category_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  InitialSourceAsc = 'initial_source_asc',
  InitialSourceDesc = 'initial_source_desc',
  IsBidPortfolioCompanyAsc = 'is_bid_portfolio_company_asc',
  IsBidPortfolioCompanyDesc = 'is_bid_portfolio_company_desc',
  IsGenericIndustryCompanyAsc = 'is_generic_industry_company_asc',
  IsGenericIndustryCompanyDesc = 'is_generic_industry_company_desc',
  IsItCompanyCompanyAsc = 'is_it_company_company_asc',
  IsItCompanyCompanyDesc = 'is_it_company_company_desc',
  IsSaasCompanyAsc = 'is_saas_company_asc',
  IsSaasCompanyDesc = 'is_saas_company_desc',
  IsSoftwareCompanyAsc = 'is_software_company_asc',
  IsSoftwareCompanyDesc = 'is_software_company_desc',
  LegalNameAsc = 'legal_name_asc',
  LegalNameDesc = 'legal_name_desc',
  LinkedinUrlAsc = 'linkedin_url_asc',
  LinkedinUrlDesc = 'linkedin_url_desc',
  ManagingDirectorAsc = 'managing_director_asc',
  ManagingDirectorDesc = 'managing_director_desc',
  NumberOfFailedCrawlsAsc = 'number_of_failed_crawls_asc',
  NumberOfFailedCrawlsDesc = 'number_of_failed_crawls_desc',
  PipedriveIdAsc = 'pipedrive_id_asc',
  PipedriveIdDesc = 'pipedrive_id_desc',
  ShortNameAsc = 'short_name_asc',
  ShortNameDesc = 'short_name_desc',
  SicCodeAsc = 'sic_code_asc',
  SicCodeCertaintyAsc = 'sic_code_certainty_asc',
  SicCodeCertaintyDesc = 'sic_code_certainty_desc',
  SicCodeDesc = 'sic_code_desc',
  StreetAsc = 'street_asc',
  StreetDesc = 'street_desc',
  SubsidiaryOfAsc = 'subsidiary_of_asc',
  SubsidiaryOfDesc = 'subsidiary_of_desc',
  UniqueDomainAsc = 'unique_domain_asc',
  UniqueDomainDesc = 'unique_domain_desc',
  ValidationDatetimeAsc = 'validation_datetime_asc',
  ValidationDatetimeDesc = 'validation_datetime_desc',
  ValidationUserAsc = 'validation_user_asc',
  ValidationUserDesc = 'validation_user_desc',
  ZipCodeAsc = 'zip_code_asc',
  ZipCodeDesc = 'zip_code_desc'
}

export type GenericCompanyTechUseConnection = {
  __typename?: 'GenericCompanyTechUseConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericCompanyTechUseEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `GenericCompanyTechUse` and its cursor. */
export type GenericCompanyTechUseEdge = {
  __typename?: 'GenericCompanyTechUseEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TechUseType>;
};

export type GenericCompanyTechUseWithStackshareToolSqlType = Node & {
  __typename?: 'GenericCompanyTechUseWithStackshareToolSQLType';
  breadcrumb?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  findingUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isApiFramework?: Maybe<Scalars['Boolean']['output']>;
  isBackendFramework?: Maybe<Scalars['Boolean']['output']>;
  isFrontendFramework?: Maybe<Scalars['Boolean']['output']>;
  markedAsFalsePositive?: Maybe<Scalars['Boolean']['output']>;
  matchRelevance?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stacksUsed?: Maybe<Scalars['Int']['output']>;
  stackshareTool?: Maybe<ProductTechnologylType>;
  stackshareToolId?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Scalars['Int']['output']>;
  yearOfInitialRelease?: Maybe<Scalars['Int']['output']>;
};

export type GenericCompanyTechUseWithStackshareToolSqlTypeConnection = {
  __typename?: 'GenericCompanyTechUseWithStackshareToolSQLTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericCompanyTechUseWithStackshareToolSqlTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GenericCompanyTechUseWithStackshareToolSQLType` and its cursor. */
export type GenericCompanyTechUseWithStackshareToolSqlTypeEdge = {
  __typename?: 'GenericCompanyTechUseWithStackshareToolSQLTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericCompanyTechUseWithStackshareToolSqlType>;
};

export type GenericCrawlResultConnection = {
  __typename?: 'GenericCrawlResultConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericCrawlResultEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `GenericCrawlResult` and its cursor. */
export type GenericCrawlResultEdge = {
  __typename?: 'GenericCrawlResultEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericCrawlResultSqlType>;
};

export type GenericCrawlResultSqlType = Node & {
  __typename?: 'GenericCrawlResultSQLType';
  company?: Maybe<BuyAndBuildTargetAssessmentType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  crawlFinishedTime?: Maybe<Scalars['DateTime']['output']>;
  crawlResultObject?: Maybe<Scalars['JSONString']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  employeeEmailCallbackHasHappened?: Maybe<Scalars['Boolean']['output']>;
  expensiveAnalysisExecutionTime?: Maybe<Scalars['DateTime']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fteAnalysisObject?: Maybe<Scalars['JSONString']['output']>;
  hadMlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hasCompanyBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinLowerFte?: Maybe<Scalars['Int']['output']>;
  linkedinUpperFte?: Maybe<Scalars['Int']['output']>;
  listOfEmails?: Maybe<Scalars['JSONString']['output']>;
  listOfMds?: Maybe<Scalars['JSONString']['output']>;
  mongoEmbeddingCreationTime?: Maybe<Scalars['DateTime']['output']>;
  socialResultObject?: Maybe<Scalars['JSONString']['output']>;
  softwareCategory?: Maybe<SoftwareCategorySqlType>;
  softwareCategoryCertainty?: Maybe<Scalars['Float']['output']>;
  softwareCategoryId?: Maybe<Scalars['Int']['output']>;
  specialXoviKeywords?: Maybe<KeywordForCompanySqlTypeConnection>;
  url?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  xoviKeywords?: Maybe<XoviKeywordConnection>;
};


export type GenericCrawlResultSqlTypeSpecialXoviKeywordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type GenericCrawlResultSqlTypeXoviKeywordsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  cpc?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPlaceholderForEmptyXoviResponse?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  markedAsIrrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<XoviKeywordOrderEnum>>>;
};

export enum GenericCrawlResultSortEnum {
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  CrawlFinishedTimeAsc = 'crawl_finished_time_asc',
  CrawlFinishedTimeDesc = 'crawl_finished_time_desc',
  CrawlResultObjectAsc = 'crawl_result_object_asc',
  CrawlResultObjectDesc = 'crawl_result_object_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  EmployeeEmailCallbackHasHappenedAsc = 'employee_email_callback_has_happened_asc',
  EmployeeEmailCallbackHasHappenedDesc = 'employee_email_callback_has_happened_desc',
  ExpensiveAnalysisExecutionTimeAsc = 'expensive_analysis_execution_time_asc',
  ExpensiveAnalysisExecutionTimeDesc = 'expensive_analysis_execution_time_desc',
  FteAnalysisObjectAsc = 'fte_analysis_object_asc',
  FteAnalysisObjectDesc = 'fte_analysis_object_desc',
  FteAsc = 'fte_asc',
  FteDesc = 'fte_desc',
  HadMlModelAppliedAsc = 'had_ml_model_applied_asc',
  HadMlModelAppliedDesc = 'had_ml_model_applied_desc',
  HasBeenValidatedAsc = 'has_been_validated_asc',
  HasBeenValidatedDesc = 'has_been_validated_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsRelevantAsc = 'is_relevant_asc',
  IsRelevantDesc = 'is_relevant_desc',
  IsSoftwareCompanyAsc = 'is_software_company_asc',
  IsSoftwareCompanyDesc = 'is_software_company_desc',
  LinkedinFteAsc = 'linkedin_fte_asc',
  LinkedinFteDesc = 'linkedin_fte_desc',
  LinkedinLowerFteAsc = 'linkedin_lower_fte_asc',
  LinkedinLowerFteDesc = 'linkedin_lower_fte_desc',
  LinkedinUpperFteAsc = 'linkedin_upper_fte_asc',
  LinkedinUpperFteDesc = 'linkedin_upper_fte_desc',
  ListOfEmailsAsc = 'list_of_emails_asc',
  ListOfEmailsDesc = 'list_of_emails_desc',
  ListOfMdsAsc = 'list_of_mds_asc',
  ListOfMdsDesc = 'list_of_mds_desc',
  MongoEmbeddingCreationTimeAsc = 'mongo_embedding_creation_time_asc',
  MongoEmbeddingCreationTimeDesc = 'mongo_embedding_creation_time_desc',
  SocialResultObjectAsc = 'social_result_object_asc',
  SocialResultObjectDesc = 'social_result_object_desc',
  SoftwareCategoryCertaintyAsc = 'software_category_certainty_asc',
  SoftwareCategoryCertaintyDesc = 'software_category_certainty_desc',
  SoftwareCategoryIdAsc = 'software_category_id_asc',
  SoftwareCategoryIdDesc = 'software_category_id_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc',
  WeightAsc = 'weight_asc',
  WeightDesc = 'weight_desc'
}

export enum GenericCustomerReferenceSortEnum {
  ActiveAsc = 'active_asc',
  ActiveDesc = 'active_desc',
  ArrAsc = 'arr_asc',
  ArrDesc = 'arr_desc',
  ComputedCustomerIndustryAsc = 'computed_customer_industry_asc',
  ComputedCustomerIndustryDesc = 'computed_customer_industry_desc',
  CustomerReferenceNameAsc = 'customer_reference_name_asc',
  CustomerReferenceNameDesc = 'customer_reference_name_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsDdDatapointAsc = 'is_dd_datapoint_asc',
  IsDdDatapointDesc = 'is_dd_datapoint_desc',
  LossReasonAsc = 'loss_reason_asc',
  LossReasonDesc = 'loss_reason_desc',
  LostYearAsc = 'lost_year_asc',
  LostYearDesc = 'lost_year_desc',
  MarkedAsDeletedAsc = 'marked_as_deleted_asc',
  MarkedAsDeletedDesc = 'marked_as_deleted_desc',
  MarkedAsIrrelevantAsc = 'marked_as_irrelevant_asc',
  MarkedAsIrrelevantDesc = 'marked_as_irrelevant_desc',
  NpsAsc = 'nps_asc',
  NpsDesc = 'nps_desc',
  ReferenceCompanyIdAsc = 'reference_company_id_asc',
  ReferenceCompanyIdDesc = 'reference_company_id_desc',
  ReferencedAtAsc = 'referenced_at_asc',
  ReferencedAtDesc = 'referenced_at_desc',
  RevenueYearOneAsc = 'revenue_year_one_asc',
  RevenueYearOneDesc = 'revenue_year_one_desc',
  RevenueYearTwoAsc = 'revenue_year_two_asc',
  RevenueYearTwoDesc = 'revenue_year_two_desc',
  SoftwareCompanyIdAsc = 'software_company_id_asc',
  SoftwareCompanyIdDesc = 'software_company_id_desc',
  UsersAsc = 'users_asc',
  UsersDesc = 'users_desc',
  WonYearAsc = 'won_year_asc',
  WonYearDesc = 'won_year_desc'
}

export enum GenericEmploymentSortEnum {
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  EmployeeIdAsc = 'employee_id_asc',
  EmployeeIdDesc = 'employee_id_desc',
  EmploymentDurationAsc = 'employment_duration_asc',
  EmploymentDurationDesc = 'employment_duration_desc',
  EmploymentEndAsc = 'employment_end_asc',
  EmploymentEndDesc = 'employment_end_desc',
  EmploymentStartAsc = 'employment_start_asc',
  EmploymentStartDesc = 'employment_start_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  TitleAsc = 'title_asc',
  TitleDesc = 'title_desc'
}

export enum GenericLanguageModelFindingsSortEnum {
  CompanyIdAsc = 'company_id_asc',
  CompanyIdDesc = 'company_id_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DetectedLanguageAsc = 'detected_language_asc',
  DetectedLanguageDesc = 'detected_language_desc',
  FindingsUrlAsc = 'findings_url_asc',
  FindingsUrlDesc = 'findings_url_desc',
  HasBeenManuallyCheckedAsc = 'has_been_manually_checked_asc',
  HasBeenManuallyCheckedDesc = 'has_been_manually_checked_desc',
  HasBeenOverwrittenAsc = 'has_been_overwritten_asc',
  HasBeenOverwrittenDesc = 'has_been_overwritten_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  OpenAiVoteAsc = 'open_ai_vote_asc',
  OpenAiVoteDesc = 'open_ai_vote_desc'
}

export type GenericShareHolderType = Node & {
  __typename?: 'GenericShareHolderType';
  company?: Maybe<BuyAndBuildTargetAssessmentType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  genericShareholdings?: Maybe<GenericShareholdingSqlTypeConnection>;
  holding?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isNaturalPerson?: Maybe<Scalars['Boolean']['output']>;
  isPe?: Maybe<Scalars['Boolean']['output']>;
  isTradeBuyer?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  typeOfInvestor?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  yearBornOrFounded?: Maybe<Scalars['Int']['output']>;
};


export type GenericShareHolderTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GenericShareHolderTypeConnection = {
  __typename?: 'GenericShareHolderTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericShareHolderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GenericShareHolderType` and its cursor. */
export type GenericShareHolderTypeEdge = {
  __typename?: 'GenericShareHolderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericShareHolderType>;
};

export type GenericShareholdingConnection = {
  __typename?: 'GenericShareholdingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericShareholdingEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GenericShareholding` and its cursor. */
export type GenericShareholdingEdge = {
  __typename?: 'GenericShareholdingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericShareholdingSqlType>;
};

export type GenericShareholdingSqlType = Node & {
  __typename?: 'GenericShareholdingSQLType';
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  genericCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  genericCompanyId?: Maybe<Scalars['Int']['output']>;
  genericShareholder?: Maybe<GenericShareHolderType>;
  genericShareholderId?: Maybe<Scalars['Int']['output']>;
  holding?: Maybe<Scalars['Float']['output']>;
  holdingCount?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  investmentTransactions?: Maybe<InvestmentTransactionConnection>;
};


export type GenericShareholdingSqlTypeInvestmentTransactionsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GenericShareholdingSqlTypeConnection = {
  __typename?: 'GenericShareholdingSQLTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GenericShareholdingSqlTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GenericShareholdingSQLType` and its cursor. */
export type GenericShareholdingSqlTypeEdge = {
  __typename?: 'GenericShareholdingSQLTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GenericShareholdingSqlType>;
};

export enum GenericUrlFacesCountSortEnum {
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  FacesCountAsc = 'faces_count_asc',
  FacesCountDesc = 'faces_count_desc',
  GenericCrawlResultIdAsc = 'generic_crawl_result_id_asc',
  GenericCrawlResultIdDesc = 'generic_crawl_result_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc'
}

export enum GenericUrlTextFindingSortEnum {
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  FteCountAsc = 'fte_count_asc',
  FteCountDesc = 'fte_count_desc',
  GenericCrawlResultIdAsc = 'generic_crawl_result_id_asc',
  GenericCrawlResultIdDesc = 'generic_crawl_result_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc'
}

export type GoogleTermType = Node & {
  __typename?: 'GoogleTermType';
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  googleTrend?: Maybe<GoogleTrendType>;
  googleTrends?: Maybe<Array<Maybe<GoogleTrendType>>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  softwareCategory?: Maybe<SoftwareCategoryTypeConnection>;
  term?: Maybe<Scalars['String']['output']>;
};


export type GoogleTermTypeSoftwareCategoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GoogleTrendType = Node & {
  __typename?: 'GoogleTrendType';
  date?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  googleTerm?: Maybe<GoogleTermType>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  interest?: Maybe<Scalars['Int']['output']>;
};

export type ImageFindingConnection = {
  __typename?: 'ImageFindingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ImageFindingEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `ImageFinding` and its cursor. */
export type ImageFindingEdge = {
  __typename?: 'ImageFindingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ImageFindingType>;
};

export type ImageFindingType = {
  __typename?: 'ImageFindingType';
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  facesCount?: Maybe<Scalars['Int']['output']>;
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum IndustryClassificationSampleSortEnum {
  CountryAsc = 'country_asc',
  CountryDesc = 'country_desc',
  DateAsc = 'date_asc',
  DateDesc = 'date_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  EbitdaAssetsAsc = 'ebitda_assets_asc',
  EbitdaAssetsDesc = 'ebitda_assets_desc',
  EbitdaSalesAsc = 'ebitda_sales_asc',
  EbitdaSalesDesc = 'ebitda_sales_desc',
  EvAssetsAsc = 'ev_assets_asc',
  EvAssetsDesc = 'ev_assets_desc',
  EvEbitdaAsc = 'ev_ebitda_asc',
  EvEbitdaDesc = 'ev_ebitda_desc',
  GenericCompanyIdAsc = 'generic_company_id_asc',
  GenericCompanyIdDesc = 'generic_company_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IdentifierAsc = 'identifier_asc',
  IdentifierDesc = 'identifier_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  OneYearSalesGrowthAsc = 'one_year_sales_growth_asc',
  OneYearSalesGrowthDesc = 'one_year_sales_growth_desc',
  RegionAsc = 'region_asc',
  RegionDesc = 'region_desc',
  SicAsc = 'sic_asc',
  SicDesc = 'sic_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc',
  YearAsc = 'year_asc',
  YearDesc = 'year_desc',
  ZScoreAsc = 'z_score_asc',
  ZScoreDesc = 'z_score_desc'
}

export type InsertCertainProductCategory = {
  __typename?: 'InsertCertainProductCategory';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InvestmentTransactionConnection = {
  __typename?: 'InvestmentTransactionConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvestmentTransactionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InvestmentTransaction` and its cursor. */
export type InvestmentTransactionEdge = {
  __typename?: 'InvestmentTransactionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InvestmentTransactionSqlType>;
};

export type InvestmentTransactionSqlType = Node & {
  __typename?: 'InvestmentTransactionSQLType';
  amountRaised?: Maybe<Scalars['Float']['output']>;
  buyerId?: Maybe<Scalars['Int']['output']>;
  dateOfTransaction?: Maybe<Scalars['DateTime']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  genericShareholdingId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  role?: Maybe<Scalars['String']['output']>;
  round?: Maybe<Scalars['String']['output']>;
  sellerId?: Maybe<Scalars['Int']['output']>;
  totalAmountRaised?: Maybe<Scalars['Float']['output']>;
  typeOfTransaction?: Maybe<Scalars['String']['output']>;
};

export type KpiPreCalculationType = Node & {
  __typename?: 'KPIPreCalculationType';
  absEbit?: Maybe<Scalars['Float']['output']>;
  absEbitda?: Maybe<Scalars['Float']['output']>;
  absFte?: Maybe<Scalars['Float']['output']>;
  absSales?: Maybe<Scalars['Float']['output']>;
  ageOfProgrammingLanguage?: Maybe<Scalars['Int']['output']>;
  buyAndBuildScore?: Maybe<Scalars['Float']['output']>;
  cLevelQuality?: Maybe<Scalars['Float']['output']>;
  callabilityScore?: Maybe<Scalars['Float']['output']>;
  cogsRatio?: Maybe<Scalars['Float']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  consultingManRate?: Maybe<Scalars['Float']['output']>;
  customerIndustryGrowth?: Maybe<Scalars['Float']['output']>;
  customerIndustrySic?: Maybe<Scalars['String']['output']>;
  customerLifetimeValueDistribution?: Maybe<Scalars['Float']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  downgradeArr?: Maybe<Scalars['Float']['output']>;
  downgradeArrRate?: Maybe<Scalars['Float']['output']>;
  ebitMargin?: Maybe<Scalars['Float']['output']>;
  ebitdaMargin?: Maybe<Scalars['Float']['output']>;
  employeeConcentrationRatio?: Maybe<Scalars['Float']['output']>;
  enterpriseValueEstimation?: Maybe<Scalars['Float']['output']>;
  expansionArr?: Maybe<Scalars['Float']['output']>;
  expansionArrRate?: Maybe<Scalars['Float']['output']>;
  financialBaseForValuation?: Maybe<Scalars['String']['output']>;
  fteNetContributionMargin?: Maybe<Scalars['Float']['output']>;
  grossArrChurn?: Maybe<Scalars['Float']['output']>;
  grossArrChurnRate?: Maybe<Scalars['Float']['output']>;
  grossLogoChurn?: Maybe<Scalars['Float']['output']>;
  grossLogoChurnRate?: Maybe<Scalars['Float']['output']>;
  grossNewCustomerLogoGrowth?: Maybe<Scalars['Float']['output']>;
  grossNewCustomerLogoGrowthRate?: Maybe<Scalars['Float']['output']>;
  grossNewCustomerSalesGrowth?: Maybe<Scalars['Float']['output']>;
  grossNewCustomerSalesGrowthRate?: Maybe<Scalars['Float']['output']>;
  grossRetentionRate?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  mostExpensiveKeyword?: Maybe<Scalars['String']['output']>;
  netArrChurn?: Maybe<Scalars['Float']['output']>;
  netArrChurnRate?: Maybe<Scalars['Float']['output']>;
  netLogoChurn?: Maybe<Scalars['Float']['output']>;
  netLogoChurnRate?: Maybe<Scalars['Float']['output']>;
  netNewCustomerSalesGrowth?: Maybe<Scalars['Float']['output']>;
  netRetentionRate?: Maybe<Scalars['Float']['output']>;
  onlineVisibility?: Maybe<Scalars['Float']['output']>;
  opexRatio?: Maybe<Scalars['Float']['output']>;
  popularityOfProgrammingLanguage?: Maybe<Scalars['Float']['output']>;
  pricePerGoogleKeywordClick?: Maybe<Scalars['Float']['output']>;
  recurringRevenueShare?: Maybe<Scalars['Float']['output']>;
  salesGrowth?: Maybe<Scalars['Float']['output']>;
  softwareCategoryGrowth?: Maybe<Scalars['Float']['output']>;
  softwareShare?: Maybe<Scalars['Float']['output']>;
  teamQuality?: Maybe<Scalars['Float']['output']>;
  top3CustomerShare?: Maybe<Scalars['Float']['output']>;
  useOfBackendFramework?: Maybe<Scalars['Boolean']['output']>;
  useOfFrontendFramework?: Maybe<Scalars['Boolean']['output']>;
};

export type KpiPreCalculationTypeConnection = {
  __typename?: 'KPIPreCalculationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KpiPreCalculationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `KPIPreCalculationType` and its cursor. */
export type KpiPreCalculationTypeEdge = {
  __typename?: 'KPIPreCalculationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KpiPreCalculationType>;
};

export type KpiPrecalculationConnection = {
  __typename?: 'KPIPrecalculationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KpiPrecalculationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `KPIPrecalculation` and its cursor. */
export type KpiPrecalculationEdge = {
  __typename?: 'KPIPrecalculationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KpiPreCalculationType>;
};

export type KeywordForCompanySqlType = Node & {
  __typename?: 'KeywordForCompanySQLType';
  change?: Maybe<Scalars['Int']['output']>;
  competitorDensity?: Maybe<Scalars['Int']['output']>;
  cpc?: Maybe<Scalars['Float']['output']>;
  createdFromDataForSeo?: Maybe<Scalars['Boolean']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  genericCrawlResults?: Maybe<RecentPlatformCrawlType>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  hasBeenSearched?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isPlaceholderForEmptyXoviResponse?: Maybe<Scalars['Boolean']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  keywordEmbedding?: Maybe<Scalars['JSONString']['output']>;
  keywordLanguage?: Maybe<Scalars['String']['output']>;
  markedAsIrrelevant?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  searchEngine?: Maybe<Scalars['String']['output']>;
  seoDataAnalysisId?: Maybe<Scalars['Int']['output']>;
  sevo?: Maybe<Scalars['Int']['output']>;
  specialGenericCrawlResults?: Maybe<RecentPlatformCrawlType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type KeywordForCompanySqlTypeConnection = {
  __typename?: 'KeywordForCompanySQLTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KeywordForCompanySqlTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `KeywordForCompanySQLType` and its cursor. */
export type KeywordForCompanySqlTypeEdge = {
  __typename?: 'KeywordForCompanySQLTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KeywordForCompanySqlType>;
};

export type KeywordResultRawConnection = {
  __typename?: 'KeywordResultRawConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KeywordResultRawEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `KeywordResultRaw` and its cursor. */
export type KeywordResultRawEdge = {
  __typename?: 'KeywordResultRawEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KeywordResultRawType>;
};

export type KeywordResultRawType = Node & {
  __typename?: 'KeywordResultRawType';
  change?: Maybe<Scalars['Int']['output']>;
  competitorDensity?: Maybe<Scalars['Int']['output']>;
  cpc?: Maybe<Scalars['Float']['output']>;
  createdFromDataForSeo?: Maybe<Scalars['Boolean']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['UUID']['output']>;
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  genericCrawlResults?: Maybe<RecentPlatformCrawlType>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  hasBeenSearched?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isPlaceholderForEmptyXoviResponse?: Maybe<Scalars['Boolean']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  keywordEmbedding?: Maybe<Scalars['JSONString']['output']>;
  keywordLanguage?: Maybe<Scalars['String']['output']>;
  markedAsIrrelevant?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  searchEngine?: Maybe<Scalars['String']['output']>;
  seoDataAnalysisId?: Maybe<Scalars['Int']['output']>;
  sevo?: Maybe<Scalars['Int']['output']>;
  specialGenericCrawlResults?: Maybe<RecentPlatformCrawlType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type KeywordResultType = {
  __typename?: 'KeywordResultType';
  cpc?: Maybe<Scalars['Float']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
};

export type KlazifyCategoryConnection = {
  __typename?: 'KlazifyCategoryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KlazifyCategoryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `KlazifyCategory` and its cursor. */
export type KlazifyCategoryEdge = {
  __typename?: 'KlazifyCategoryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KlazifyCategorySqlType>;
};

export type KlazifyCategorySqlType = Node & {
  __typename?: 'KlazifyCategorySQLType';
  IAB5?: Maybe<Scalars['String']['output']>;
  IAB7?: Maybe<Scalars['String']['output']>;
  confidence?: Maybe<Scalars['Float']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  domainId?: Maybe<Scalars['Int']['output']>;
  hasSubcategories?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  subCategory1?: Maybe<Scalars['String']['output']>;
  subCategory2?: Maybe<Scalars['String']['output']>;
  subCategory3?: Maybe<Scalars['String']['output']>;
  subCategory4?: Maybe<Scalars['String']['output']>;
};

export type KlazifyCompanyType = Node & {
  __typename?: 'KlazifyCompanyType';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type KlazifyDomainConnection = {
  __typename?: 'KlazifyDomainConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KlazifyDomainEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `KlazifyDomain` and its cursor. */
export type KlazifyDomainEdge = {
  __typename?: 'KlazifyDomainEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KlazifyDomainType>;
};

export enum KlazifyDomainSortEnum {
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DomainNameAsc = 'domain_name_asc',
  DomainNameDesc = 'domain_name_desc',
  GenericCompanyIdAsc = 'generic_company_id_asc',
  GenericCompanyIdDesc = 'generic_company_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  LogoUrlAsc = 'logo_url_asc',
  LogoUrlDesc = 'logo_url_desc',
  SuccessAsc = 'success_asc',
  SuccessDesc = 'success_desc'
}

export type KlazifyDomainType = Node & {
  __typename?: 'KlazifyDomainType';
  categories?: Maybe<KlazifyCategoryConnection>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  domainName?: Maybe<Scalars['String']['output']>;
  genericCompanyId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  klazifyCompany?: Maybe<KlazifyCompanyType>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};


export type KlazifyDomainTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LabelBuyAndBuildTargetQuality = {
  __typename?: 'LabelBuyAndBuildTargetQuality';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MlModelFindingConnection = {
  __typename?: 'MLModelFindingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MlModelFindingEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `MLModelFinding` and its cursor. */
export type MlModelFindingEdge = {
  __typename?: 'MLModelFindingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<MlModelFindingType>;
};

export type MlModelFindingType = {
  __typename?: 'MLModelFindingType';
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  detectedLanguage?: Maybe<Scalars['String']['output']>;
  findingsUrl?: Maybe<Scalars['String']['output']>;
  hasBeenManuallyChecked?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  openAiVote?: Maybe<Scalars['Int']['output']>;
  resultUrl?: Maybe<Scalars['String']['output']>;
};

export type MarkAllCrawlResultsAsValidated = {
  __typename?: 'MarkAllCrawlResultsAsValidated';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkCompanyAsNotSoftware = {
  __typename?: 'MarkCompanyAsNotSoftware';
  company?: Maybe<CompanyType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkCompanyAsSoftware = {
  __typename?: 'MarkCompanyAsSoftware';
  company?: Maybe<CompanyType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkProductAsHorizontal = {
  __typename?: 'MarkProductAsHorizontal';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkXoviKeywordAsIrrelevant = {
  __typename?: 'MarkXOVIKeywordAsIrrelevant';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkXoviKeywordsAsIrrelevant = {
  __typename?: 'MarkXOVIKeywordsAsIrrelevant';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MatchCompanyWithOpenRegistry = {
  __typename?: 'MatchCompanyWithOpenRegistry';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  addBuyAndBuildTarget?: Maybe<AddBuyAndBuildTarget>;
  addBuyAndBuildTargetsFromSourcescrub?: Maybe<AddBuyAndBuildTargetsFromSourcescrub>;
  addCustomerSegment?: Maybe<AddCustomerSegmentMutation>;
  addIndustry?: Maybe<AddIndustry>;
  addJobDescriptionUrl?: Maybe<AddJobDescriptionUrl>;
  addSoftwareCategoryToCompany?: Maybe<AddSoftwareCategoryToCompany>;
  cleanupCrawlResults?: Maybe<CleanupCrawlResults>;
  cortexFeedbackPipedriveBridge?: Maybe<CortexFeedbackPipedriveBridge>;
  createBenchmarkBalanceSheet?: Maybe<CreateBenchmarkBalanceSheet>;
  createBenchmarkCustomersAggregated?: Maybe<CreateBenchmarkCustomersAggregated>;
  createBenchmarkCustomersList?: Maybe<CreateBenchmarkCustomersList>;
  createBenchmarkFte?: Maybe<CreateBenchmarkFte>;
  createBenchmarkPl?: Maybe<CreateBenchmarkPl>;
  createBenchmarkPricing?: Maybe<CreateBenchmarkPricing>;
  createKeywords?: Maybe<CreateXoviKeywords>;
  createUpdateCategory?: Maybe<CreateUpdateKlazifyCategory>;
  createUpdateCompanyTechUse?: Maybe<CreateUpdateCompanyTechUse>;
  createUpdateGenericShareholder?: Maybe<CreateUpdateGenericShareholder>;
  createUpdateGenericShareholding?: Maybe<CreateUpdateGenericShareholding>;
  createUpdateKeyword?: Maybe<CreateUpdateXoviKeyword>;
  deleteAllBmForCompany?: Maybe<DeleteAllBenchmarkingDataForCompany>;
  deleteCompanyTechUse?: Maybe<DeleteCompanyTechUse>;
  deleteCustomerReference?: Maybe<DeleteCustomerReference>;
  deleteCustomerSegment?: Maybe<DeleteCustomerSegmentMutation>;
  deleteGenericShareholder?: Maybe<DeleteGenericShareholder>;
  deleteGenericShareholding?: Maybe<DeleteGenericShareholding>;
  deleteKeyword?: Maybe<MarkXoviKeywordAsIrrelevant>;
  deleteKeywords?: Maybe<MarkXoviKeywordsAsIrrelevant>;
  downvoteFindingsToken?: Maybe<DownvoteFindingsToken>;
  editCompany?: Maybe<EditCompany>;
  finishExcelFileUpload?: Maybe<FinishExcelFileUpload>;
  insertCertainProductCategory?: Maybe<InsertCertainProductCategory>;
  labelBuyAndBuildTargetQuality?: Maybe<LabelBuyAndBuildTargetQuality>;
  markAllCrawlResultsAsValidated?: Maybe<MarkAllCrawlResultsAsValidated>;
  markCompanyAsNotSoftware?: Maybe<MarkCompanyAsNotSoftware>;
  markCompanyAsSoftware?: Maybe<MarkCompanyAsSoftware>;
  markCustomerReferenceObsolete?: Maybe<MarkCustomerReferenceObsolete>;
  markProductAsHorizontal?: Maybe<MarkProductAsHorizontal>;
  matchCompanyWithOpenRegistry?: Maybe<MatchCompanyWithOpenRegistry>;
  precalculateAllKpis?: Maybe<PreCalculateAllKpIs>;
  precalculateKpis?: Maybe<PreCalculateKpIs>;
  predictBuyAndBuildRelevanceForCompany?: Maybe<PredictBuyAndBuildRelevanceForCompany>;
  predictCallabilityForCompany?: Maybe<PredictCallabilityForCompany>;
  reclassifyCustomerSegment?: Maybe<ReclassifyCustomerSegmentMutation>;
  removeShortlistedBuyAndBuildTargets?: Maybe<RemoveShortlistedBuyAndBuildTarget>;
  resegmentAllCustomers?: Maybe<ResegmentAllCustomersMutation>;
  retrieveSeoDataForKeywords?: Maybe<RetrieveSeoDataForKeywords>;
  scheduledMongoEmbeddingCreationForCompanies?: Maybe<ScheduledMongoEmbeddingCreationForCompanies>;
  scheduledRetrievalOfOpenaiBatchJobs?: Maybe<ScheduledRetrievalOfOpenaiBatchJobs>;
  searchForSourceScrubTags?: Maybe<SearchForSourceScrubTags>;
  setCompanySize?: Maybe<SetCompanySize>;
  setProductCategoryToZero?: Maybe<SetProductCategoryToZero>;
  shortlistBuyAndBuildTarget?: Maybe<ShortlistBuyAndBuildTarget>;
  startCompanyVerificationSession?: Maybe<StartCompanyVerificationSession>;
  startCrawler?: Maybe<StartCrawlerMutation>;
  testDirectoryNameFinder?: Maybe<TestDirectoryNameFinder>;
  testS3Event?: Maybe<TestS3Event>;
  trainBuyAndBuildRelevanceModel?: Maybe<TrainBuyAndBuildRelevanceModel>;
  trainCallabilityModel?: Maybe<TrainCallabilityModel>;
  transferBuyAndBuildTargetsToPipedrive?: Maybe<TransferBuyAndBuildTargetsToPipedrive>;
  updateCortexConfig?: Maybe<UpdateCortexConfig>;
  updateCustomerSegment?: Maybe<UpdateCustomerSegmentMutation>;
  upgradeAlembic?: Maybe<UpgradeAlembic>;
  uploadExcelCell?: Maybe<UploadExcelCellMutation>;
  uploadExcelFile?: Maybe<UploadExcelFileMutation>;
  uploadExcelFileChunk?: Maybe<UploadExcelFileChunkMutation>;
  uploadExcelRow?: Maybe<UploadExcelRowMutation>;
  uploadExcelSheet?: Maybe<UploadExcelSheetMutation>;
  uploadFile?: Maybe<UploadFileResponse>;
  upvoteFindingsToken?: Maybe<UpvoteFindingsToken>;
};


export type MutationsAddBuyAndBuildTargetArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Float']['input']>;
  softwareCompanyId: Scalars['Int']['input'];
  sourcescrubTags?: InputMaybe<Scalars['String']['input']>;
  targetCompanyId?: InputMaybe<Scalars['Int']['input']>;
  targetCompanyUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsAddBuyAndBuildTargetsFromSourcescrubArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  softwareCompanyId: Scalars['Int']['input'];
};


export type MutationsAddCustomerSegmentArgs = {
  input: CustomerSegmentInput;
};


export type MutationsAddIndustryArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  referencedAt: Scalars['String']['input'];
  sicCode: Scalars['String']['input'];
};


export type MutationsAddJobDescriptionUrlArgs = {
  companyId: Scalars['Int']['input'];
  jobDescriptionUrl: Scalars['String']['input'];
};


export type MutationsAddSoftwareCategoryToCompanyArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  softwareCategoryId: Scalars['Int']['input'];
};


export type MutationsCleanupCrawlResultsArgs = {
  domain: Scalars['String']['input'];
};


export type MutationsCortexFeedbackPipedriveBridgeArgs = {
  addOnExisting?: InputMaybe<Scalars['Boolean']['input']>;
  addOnForBidId?: InputMaybe<Scalars['Int']['input']>;
  addOnFuture?: InputMaybe<Scalars['Boolean']['input']>;
  authId?: InputMaybe<Scalars['String']['input']>;
  b2c?: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['Int']['input'];
  createDeal?: InputMaybe<Scalars['Boolean']['input']>;
  createOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  newPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  otherOwnership?: InputMaybe<Scalars['Boolean']['input']>;
  partOfGroup?: InputMaybe<Scalars['Boolean']['input']>;
  reseller?: InputMaybe<Scalars['Boolean']['input']>;
  saas?: InputMaybe<Scalars['Boolean']['input']>;
  selectValue?: InputMaybe<Scalars['String']['input']>;
  sellsHardware?: InputMaybe<Scalars['Boolean']['input']>;
  tooBig?: InputMaybe<Scalars['Boolean']['input']>;
  tooMuchConsulting?: InputMaybe<Scalars['Boolean']['input']>;
  tooSmall?: InputMaybe<Scalars['Boolean']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  venture?: InputMaybe<Scalars['Boolean']['input']>;
  wrongBusinessModel?: InputMaybe<Scalars['Boolean']['input']>;
  wrongGeography?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsCreateBenchmarkBalanceSheetArgs = {
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  periodEndDate: Scalars['Date']['input'];
  periodStartDate: Scalars['Date']['input'];
  periodType: Scalars['String']['input'];
  pipedriveDealId?: InputMaybe<Scalars['Int']['input']>;
  uploadFileName: Scalars['String']['input'];
  uploadFilePath: Scalars['String']['input'];
  uploaderName: Scalars['String']['input'];
  valuationLevel: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};


export type MutationsCreateBenchmarkCustomersAggregatedArgs = {
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  periodEndDate: Scalars['Date']['input'];
  periodStartDate: Scalars['Date']['input'];
  periodType: Scalars['String']['input'];
  pipedriveDealId?: InputMaybe<Scalars['Int']['input']>;
  uploadFileName: Scalars['String']['input'];
  uploadFilePath: Scalars['String']['input'];
  uploaderName: Scalars['String']['input'];
  valuationLevel: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};


export type MutationsCreateBenchmarkCustomersListArgs = {
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  periodEndDate: Scalars['Date']['input'];
  periodStartDate: Scalars['Date']['input'];
  periodType: Scalars['String']['input'];
  pipedriveDealId?: InputMaybe<Scalars['Int']['input']>;
  uploadFileName: Scalars['String']['input'];
  uploadFilePath: Scalars['String']['input'];
  uploaderName: Scalars['String']['input'];
  valuationLevel: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};


export type MutationsCreateBenchmarkFteArgs = {
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  periodEndDate: Scalars['Date']['input'];
  periodStartDate: Scalars['Date']['input'];
  periodType: Scalars['String']['input'];
  pipedriveDealId?: InputMaybe<Scalars['Int']['input']>;
  uploadFileName: Scalars['String']['input'];
  uploadFilePath: Scalars['String']['input'];
  uploaderName: Scalars['String']['input'];
  valuationLevel: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};


export type MutationsCreateBenchmarkPlArgs = {
  category1: Scalars['String']['input'];
  category2?: InputMaybe<Scalars['String']['input']>;
  category3?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  period: Scalars['Int']['input'];
  periodEndDate?: InputMaybe<Scalars['Date']['input']>;
  periodStartDate?: InputMaybe<Scalars['Date']['input']>;
  periodType?: InputMaybe<Scalars['String']['input']>;
  pipedriveDealId?: InputMaybe<Scalars['Int']['input']>;
  uploadFileName?: InputMaybe<Scalars['String']['input']>;
  uploadFilePath?: InputMaybe<Scalars['String']['input']>;
  uploaderName?: InputMaybe<Scalars['String']['input']>;
  valuationLevel: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};


export type MutationsCreateBenchmarkPricingArgs = {
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  periodEndDate: Scalars['Date']['input'];
  periodStartDate: Scalars['Date']['input'];
  periodType: Scalars['String']['input'];
  pipedriveDealId?: InputMaybe<Scalars['Int']['input']>;
  uploadFileName: Scalars['String']['input'];
  uploadFilePath: Scalars['String']['input'];
  uploaderName: Scalars['String']['input'];
  valuationLevel: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};


export type MutationsCreateKeywordsArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  genericCrawlResultId?: InputMaybe<Scalars['Int']['input']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationsCreateUpdateCategoryArgs = {
  companyId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
  subCategory4?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsCreateUpdateCompanyTechUseArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
  findingUrl?: InputMaybe<Scalars['String']['input']>;
  genericCompanyTechUseId?: InputMaybe<Scalars['Int']['input']>;
  markedAsFalsePositive?: InputMaybe<Scalars['Boolean']['input']>;
  stackshareToolId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsCreateUpdateGenericShareholderArgs = {
  genericCompanyId?: InputMaybe<Scalars['Int']['input']>;
  genericShareholderId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  typeOfInvestor?: InputMaybe<Scalars['String']['input']>;
  yearBornOrFounded?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsCreateUpdateGenericShareholdingArgs = {
  genericCompanyId?: InputMaybe<Scalars['Int']['input']>;
  genericShareholderId?: InputMaybe<Scalars['Int']['input']>;
  genericShareholdingId?: InputMaybe<Scalars['Int']['input']>;
  holding?: InputMaybe<Scalars['Float']['input']>;
  holdingCount?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsCreateUpdateKeywordArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  genericCrawlResultId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsDeleteAllBmForCompanyArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationsDeleteCompanyTechUseArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  techUseId: Scalars['Int']['input'];
};


export type MutationsDeleteCustomerReferenceArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  customerReferenceId: Scalars['Int']['input'];
};


export type MutationsDeleteCustomerSegmentArgs = {
  segmentId: Scalars['Int']['input'];
};


export type MutationsDeleteGenericShareholderArgs = {
  genericShareholderId: Scalars['Int']['input'];
};


export type MutationsDeleteGenericShareholdingArgs = {
  genericShareholdingId: Scalars['Int']['input'];
};


export type MutationsDeleteKeywordArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  keywordId: Scalars['Int']['input'];
};


export type MutationsDeleteKeywordsArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  keywordIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationsDownvoteFindingsTokenArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  tokenId: Scalars['Int']['input'];
};


export type MutationsEditCompanyArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  benchmarkingIndex?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  court?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  hrbNumber?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  pipedriveId?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  sicCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsFinishExcelFileUploadArgs = {
  fileId: Scalars['Int']['input'];
};


export type MutationsInsertCertainProductCategoryArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  productCategorizationId: Scalars['Int']['input'];
};


export type MutationsLabelBuyAndBuildTargetQualityArgs = {
  quality: Scalars['Float']['input'];
  softwareCompanyId: Scalars['Int']['input'];
  targetCompanyId: Scalars['Int']['input'];
};


export type MutationsMarkCompanyAsNotSoftwareArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  crawlResultId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsMarkCompanyAsSoftwareArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  crawlResultId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsMarkCustomerReferenceObsoleteArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  customerReferenceId: Scalars['Int']['input'];
};


export type MutationsMarkProductAsHorizontalArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  softwareCompanyId: Scalars['Int']['input'];
};


export type MutationsMatchCompanyWithOpenRegistryArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationsPrecalculateAllKpisArgs = {
  companyId: Scalars['Int']['input'];
  onlyCreateNew?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsPrecalculateKpisArgs = {
  ageOfProgrammingLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  allBidPortfolioCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  allKpis?: InputMaybe<Scalars['Boolean']['input']>;
  allSoftwareCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  allUnvalidatedCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  allValidatedCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  buyAndBuildScore?: InputMaybe<Scalars['Boolean']['input']>;
  callabilityScore?: InputMaybe<Scalars['Boolean']['input']>;
  customerIndustries?: InputMaybe<Scalars['Boolean']['input']>;
  customerIndustryGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  employeeConcentrationRatio?: InputMaybe<Scalars['Boolean']['input']>;
  financialKpis?: InputMaybe<Scalars['Boolean']['input']>;
  onlineVisibility?: InputMaybe<Scalars['Boolean']['input']>;
  popularityOfProgrammingLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  pricePerGoogleKeywordClick?: InputMaybe<Scalars['Boolean']['input']>;
  softwareCategoryGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  teamQuality?: InputMaybe<Scalars['Boolean']['input']>;
  useOfBackendFramework?: InputMaybe<Scalars['Boolean']['input']>;
  useOfFrontendFramework?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsPredictBuyAndBuildRelevanceForCompanyArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationsPredictCallabilityForCompanyArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationsReclassifyCustomerSegmentArgs = {
  customerDomain: Scalars['String']['input'];
};


export type MutationsRemoveShortlistedBuyAndBuildTargetsArgs = {
  softwareCompanyId: Scalars['Int']['input'];
  targetCompanyId: Scalars['Int']['input'];
};


export type MutationsResegmentAllCustomersArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationsRetrieveSeoDataForKeywordsArgs = {
  companyId: Scalars['Int']['input'];
};


export type MutationsScheduledMongoEmbeddingCreationForCompaniesArgs = {
  noOfCompaniesToProcess?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsSearchForSourceScrubTagsArgs = {
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationsSetCompanySizeArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  findingUrl?: InputMaybe<Scalars['String']['input']>;
  fte: Scalars['Int']['input'];
  typeOfFinding?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsSetProductCategoryToZeroArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  productCategorizationId: Scalars['Int']['input'];
};


export type MutationsShortlistBuyAndBuildTargetArgs = {
  softwareCompanyId: Scalars['Int']['input'];
  targetCompanyId: Scalars['Int']['input'];
};


export type MutationsStartCompanyVerificationSessionArgs = {
  authId: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
};


export type MutationsStartCrawlerArgs = {
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  forceDeepAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  forceSw?: InputMaybe<Scalars['Boolean']['input']>;
  pipedriveId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationsTestDirectoryNameFinderArgs = {
  dirName: Scalars['String']['input'];
};


export type MutationsTestS3EventArgs = {
  bucket: Scalars['String']['input'];
  event?: InputMaybe<Scalars['String']['input']>;
  objectKey: Scalars['String']['input'];
};


export type MutationsTransferBuyAndBuildTargetsToPipedriveArgs = {
  softwareCompanyId: Scalars['Int']['input'];
};


export type MutationsUpdateCortexConfigArgs = {
  attributes: Scalars['JSONString']['input'];
};


export type MutationsUpdateCustomerSegmentArgs = {
  input: CustomerSegmentInput;
  segmentId: Scalars['Int']['input'];
};


export type MutationsUpgradeAlembicArgs = {
  pin: Scalars['Int']['input'];
};


export type MutationsUploadExcelCellArgs = {
  input: UploadExcelCellInput;
};


export type MutationsUploadExcelFileArgs = {
  input: UploadExcelFileInput;
};


export type MutationsUploadExcelFileChunkArgs = {
  chunk: Scalars['String']['input'];
  fileId: Scalars['Int']['input'];
  isLastChunk: Scalars['Boolean']['input'];
};


export type MutationsUploadExcelRowArgs = {
  input: UploadExcelRowInput;
};


export type MutationsUploadExcelSheetArgs = {
  input: UploadExcelSheetInput;
};


export type MutationsUploadFileArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  file: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  folder?: InputMaybe<Scalars['String']['input']>;
  isBmFile?: InputMaybe<Scalars['Boolean']['input']>;
  makePubliclyAccessible?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsUpvoteFindingsTokenArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  tokenId: Scalars['Int']['input'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type OpenAiVoteConnection = {
  __typename?: 'OpenAIVoteConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OpenAiVoteEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `OpenAIVote` and its cursor. */
export type OpenAiVoteEdge = {
  __typename?: 'OpenAIVoteEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OpenAiVoteType>;
};

export type OpenAiVoteType = {
  __typename?: 'OpenAIVoteType';
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  detectedLanguage?: Maybe<Scalars['String']['output']>;
  findingsUrl?: Maybe<Scalars['String']['output']>;
  hasBeenManuallyChecked?: Maybe<Scalars['Boolean']['output']>;
  hasBeenOverwritten?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  openAiVote?: Maybe<Scalars['Int']['output']>;
  serviceVotes?: Maybe<Scalars['Int']['output']>;
  softwareVotes?: Maybe<Scalars['Int']['output']>;
  undecidable?: Maybe<Scalars['Int']['output']>;
};

export type OpenRegCompanyConnection = {
  __typename?: 'OpenRegCompanyConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OpenRegCompanyEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OpenRegCompany` and its cursor. */
export type OpenRegCompanyEdge = {
  __typename?: 'OpenRegCompanyEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OpenRegCompanySqlType>;
};

export type OpenRegCompanySqlType = Node & {
  __typename?: 'OpenRegCompanySQLType';
  addressFulladdress?: Maybe<Scalars['String']['output']>;
  addressIscurrent?: Maybe<Scalars['String']['output']>;
  addressPlace?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  addressValidfrom?: Maybe<Scalars['String']['output']>;
  addressValidtill?: Maybe<Scalars['String']['output']>;
  addressZipandplace?: Maybe<Scalars['String']['output']>;
  addressZipcode?: Maybe<Scalars['String']['output']>;
  capitalAmount?: Maybe<Scalars['String']['output']>;
  capitalCurrency?: Maybe<Scalars['String']['output']>;
  capitalIscurrent?: Maybe<Scalars['String']['output']>;
  capitalValidfrom?: Maybe<Scalars['String']['output']>;
  capitalValidtill?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  dissolutionDate?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  firstSeenDate?: Maybe<Scalars['String']['output']>;
  foundedDate?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lastSeenDate?: Maybe<Scalars['String']['output']>;
  nameIscurrent?: Maybe<Scalars['String']['output']>;
  nameValidfrom?: Maybe<Scalars['String']['output']>;
  nameValidtill?: Maybe<Scalars['String']['output']>;
  objectiveEmbedding?: Maybe<Scalars['String']['output']>;
  objectiveIscurrent?: Maybe<Scalars['String']['output']>;
  objectiveText?: Maybe<Scalars['String']['output']>;
  objectiveValidfrom?: Maybe<Scalars['String']['output']>;
  objectiveValidtill?: Maybe<Scalars['String']['output']>;
  positionsAverageMdBirthYear?: Maybe<Scalars['String']['output']>;
  positionsMdCount?: Maybe<Scalars['String']['output']>;
  positionsOldestMd?: Maybe<Scalars['String']['output']>;
  positionsYoungestMd?: Maybe<Scalars['String']['output']>;
  referenceCourtCode?: Maybe<Scalars['String']['output']>;
  referenceCourtName?: Maybe<Scalars['String']['output']>;
  referenceFirstseen?: Maybe<Scalars['String']['output']>;
  referenceNativeReferenceNumber?: Maybe<Scalars['String']['output']>;
  referenceStdRefNo?: Maybe<Scalars['String']['output']>;
  referenceValidtill?: Maybe<Scalars['String']['output']>;
  s3LinkToFolderWithDocuments?: Maybe<Scalars['String']['output']>;
  s3LinkToShareholderList?: Maybe<Scalars['String']['output']>;
  suspectedSw?: Maybe<Scalars['Boolean']['output']>;
};

export type OpenRegCompanySqlTypeConnection = {
  __typename?: 'OpenRegCompanySQLTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OpenRegCompanySqlTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OpenRegCompanySQLType` and its cursor. */
export type OpenRegCompanySqlTypeEdge = {
  __typename?: 'OpenRegCompanySQLTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OpenRegCompanySqlType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PreCalculateAllKpIs = {
  __typename?: 'PreCalculateAllKPIs';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type PreCalculateKpIs = {
  __typename?: 'PreCalculateKPIs';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PredictBuyAndBuildRelevanceForCompany = {
  __typename?: 'PredictBuyAndBuildRelevanceForCompany';
  prediction?: Maybe<Scalars['Float']['output']>;
};

export type PredictCallabilityForCompany = {
  __typename?: 'PredictCallabilityForCompany';
  prediction?: Maybe<Scalars['Float']['output']>;
};

export type ProductCategorizationConnection = {
  __typename?: 'ProductCategorizationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCategorizationEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `ProductCategorization` and its cursor. */
export type ProductCategorizationEdge = {
  __typename?: 'ProductCategorizationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductCategorizationType>;
};

export type ProductCategorizationType = Node & {
  __typename?: 'ProductCategorizationType';
  certainty?: Maybe<Scalars['Float']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  maxDistance?: Maybe<Scalars['Float']['output']>;
  minDistance?: Maybe<Scalars['Float']['output']>;
  softwareCategory?: Maybe<SoftwareCategoryType>;
  softwareCategoryCertainty?: Maybe<Scalars['Float']['output']>;
  softwareCategoryId?: Maybe<Scalars['Int']['output']>;
};

export type ProductCategorizationTypeConnection = {
  __typename?: 'ProductCategorizationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCategorizationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProductCategorizationType` and its cursor. */
export type ProductCategorizationTypeEdge = {
  __typename?: 'ProductCategorizationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductCategorizationType>;
};

export type ProductTechnologylType = Node & {
  __typename?: 'ProductTechnologylType';
  ageOfTechnology?: Maybe<Scalars['Int']['output']>;
  breadcrumb?: Maybe<Scalars['String']['output']>;
  currentPrPopularity?: Maybe<Scalars['Float']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isApiFramework?: Maybe<Scalars['Boolean']['output']>;
  isBackendFramework?: Maybe<Scalars['Boolean']['output']>;
  isFrontendFramework?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nerName?: Maybe<Scalars['String']['output']>;
  noOfPullRequestsLtm?: Maybe<Scalars['Int']['output']>;
  stacksUsed?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  usedIn?: Maybe<GenericCompanyTechUseWithStackshareToolSqlTypeConnection>;
  votes?: Maybe<Scalars['Int']['output']>;
  yearOfInitialRelease?: Maybe<Scalars['Int']['output']>;
};


export type ProductTechnologylTypeUsedInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  allBalanceSheets?: Maybe<BenchmarkBalanceSheetConnection>;
  allBenchmarkingData?: Maybe<BenchmarkingBaseModelConnection>;
  allCompanies?: Maybe<CompanyAssesmentConnection>;
  allCustomersAggregated?: Maybe<BenchmarkCustomersAggregatedConnection>;
  allCustomersList?: Maybe<BenchmarkCustomersListConnection>;
  allFte?: Maybe<BenchmarkFteConnection>;
  allPl?: Maybe<BenchmarkPlConnection>;
  allPricing?: Maybe<BenchmarkPricingConnection>;
  chatWithCortex?: Maybe<Scalars['String']['output']>;
  companiesByCategories?: Maybe<Array<Maybe<CompanyAssessmentType>>>;
  companiesByIndustries?: Maybe<Array<Maybe<CompanyAssessmentType>>>;
  companiesByIndustryAndCategory?: Maybe<Array<Maybe<CompanyAssessmentType>>>;
  companiesForDealflowMeeting?: Maybe<CompanyAssesmentConnection>;
  companyAssesment?: Maybe<CompanyAssesmentConnection>;
  companyAssessmentBenchmark?: Maybe<Array<Maybe<CompanyAssessmentType>>>;
  companyAssessmentsForDealflowMeeting?: Maybe<CompanyAssesmentConnection>;
  companyInboxList?: Maybe<Array<Maybe<CompanyInboxType>>>;
  competitionCpcKeywords?: Maybe<KeywordResultRawConnection>;
  cortexConfig?: Maybe<CortexConfigType>;
  cortexFirms?: Maybe<CompanyAssesmentConnection>;
  customerIndustryGrowthRates?: Maybe<CustomerGrowthRateRawConnection>;
  customerSegmentation?: Maybe<Array<Maybe<CustomerSegment>>>;
  /** Fetch all Customer Segments for a given company ID */
  customerSegments?: Maybe<Array<CustomerSegmentType>>;
  employeeStatics?: Maybe<EmployeeStaticsRawConnection>;
  excelCell?: Maybe<UploadExcelCellType>;
  excelCells?: Maybe<Array<Maybe<UploadExcelCellType>>>;
  excelFile?: Maybe<UploadExcelFileType>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  excelSheet?: Maybe<UploadExcelSheetType>;
  excelSheets?: Maybe<Array<Maybe<UploadExcelSheetType>>>;
  falseNegativeInbox?: Maybe<Array<Maybe<RecentPlatformCrawlType>>>;
  getAllCompaniesForPipedriveId?: Maybe<CompanyAssesmentConnection>;
  getAllSicCodes?: Maybe<SicCodeConnection>;
  getBackendFrameworks?: Maybe<StackshareToolConnection>;
  getBenchmarkingSet?: Maybe<RecentPlatformCrawlConnection>;
  getBuyAndBuildPlatforms?: Maybe<CompanyConnection>;
  getCompany?: Maybe<CompanyConnection>;
  getCrawlResults?: Maybe<RawGenericCrawlResultConnection>;
  getCrawlerFindingTokens?: Maybe<CrawlerFindingTokenConnection>;
  getCustomerIndustrySicCodes?: Maybe<CustomerIndustrySicCodeConnection>;
  getCustomerReferences?: Maybe<CustomerReferenceRawTypeConnection>;
  getFrontendFrameworks?: Maybe<StackshareToolConnection>;
  getImageFindings?: Maybe<ImageFindingConnection>;
  getMlModelFindings?: Maybe<MlModelFindingConnection>;
  getOpenaiVotes?: Maybe<OpenAiVoteConnection>;
  getProgrammingLanguages?: Maybe<StackshareToolConnection>;
  getRecentStealthPlatformCrawls?: Maybe<RecentPlatformCrawlConnection>;
  getSoftwareCategories?: Maybe<SoftwareCategoryConnection>;
  getStackshareToolCategories?: Maybe<StackshareToolConnection>;
  getStackshareTools?: Maybe<StackshareToolConnection>;
  getTechnologiesUsed?: Maybe<StackshareToolConnection>;
  getTextFindings?: Maybe<TextFindingConnection>;
  geteLogo?: Maybe<KlazifyDomainConnection>;
  hasCompanyBeenValidated?: Maybe<GenericCrawlResultConnection>;
  lookupCortexId?: Maybe<CortexIdType>;
  node?: Maybe<Node>;
  productCategoryList?: Maybe<SoftwareCategoryConnection>;
  softwareCategoryGrowthRates?: Maybe<SoftwareCategoryGrowthRateRawConnection>;
  softwareFirms?: Maybe<CompanyAssesmentConnection>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  uploadedMetaDataByPipedriveId?: Maybe<CompanyWithLatestUpload>;
  verificationSessionUser?: Maybe<Scalars['String']['output']>;
};


export type QueryAllBalanceSheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkBalanceSheetSortEnum>>>;
};


export type QueryAllBenchmarkingDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkingBaseModelSortEnum>>>;
};


export type QueryAllCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isSoftwareCompany?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryAllCustomersAggregatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkCustomersAggregatedSortEnum>>>;
};


export type QueryAllCustomersListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkCustomersListSortEnum>>>;
};


export type QueryAllFteArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkFteSortEnum>>>;
};


export type QueryAllPlArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkPlSortEnum>>>;
};


export type QueryAllPricingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<BenchmarkPricingSortEnum>>>;
};


export type QueryChatWithCortexArgs = {
  companyId: Scalars['Int']['input'];
  userQuery: Scalars['String']['input'];
};


export type QueryCompaniesByCategoriesArgs = {
  softwareCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompaniesByIndustriesArgs = {
  sicCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCompaniesByIndustryAndCategoryArgs = {
  sicCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  softwareCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompaniesForDealflowMeetingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryCompanyAssesmentArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryCompanyAssessmentsForDealflowMeetingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryCompanyInboxListArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  falseNegatives?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newPlatforms?: InputMaybe<Scalars['Boolean']['input']>;
  platformId?: InputMaybe<Scalars['Int']['input']>;
  prioritizedPlatforms?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCompetitionCpcKeywordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<XoviKeywordsSortEnum>>>;
};


export type QueryCortexFirmsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryCustomerIndustryGrowthRatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<IndustryClassificationSampleSortEnum>>>;
};


export type QueryCustomerSegmentationArgs = {
  bidGroupCompanyId: Scalars['Int']['input'];
};


export type QueryCustomerSegmentsArgs = {
  companyId: Scalars['Int']['input'];
};


export type QueryEmployeeStaticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericEmploymentSortEnum>>>;
};


export type QueryExcelCellArgs = {
  id: Scalars['Int']['input'];
};


export type QueryExcelCellsArgs = {
  column?: InputMaybe<Scalars['Int']['input']>;
  row?: InputMaybe<Scalars['Int']['input']>;
  sheetId: Scalars['Int']['input'];
};


export type QueryExcelFileArgs = {
  id: Scalars['Int']['input'];
};


export type QueryExcelSheetArgs = {
  id: Scalars['Int']['input'];
};


export type QueryExcelSheetsArgs = {
  fileId: Scalars['Int']['input'];
};


export type QueryFalseNegativeInboxArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllCompaniesForPipedriveIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pipedriveId?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryGetAllSicCodesArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SicCodeSortEnum>>>;
};


export type QueryGetBackendFrameworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetBenchmarkingSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCrawlResultSortEnum>>>;
};


export type QueryGetBuyAndBuildPlatformsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
  sortForTargetId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCompanyArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doNotCrawl?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isBidPortfolioCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isGenericIndustryCompany?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sicCodeNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryGetCrawlResultsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recent?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCrawlResultSortEnum>>>;
};


export type QueryGetCrawlerFindingTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCustomerIndustrySicCodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCustomerReferenceSortEnum>>>;
};


export type QueryGetCustomerReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetFrontendFrameworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetImageFindingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  genericCrawlResultId?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericUrlFacesCountSortEnum>>>;
};


export type QueryGetMlModelFindingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericLanguageModelFindingsSortEnum>>>;
};


export type QueryGetOpenaiVotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericLanguageModelFindingsSortEnum>>>;
};


export type QueryGetProgrammingLanguagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<StackshareToolSortEnum>>>;
};


export type QueryGetRecentStealthPlatformCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCrawlResultSortEnum>>>;
};


export type QueryGetSoftwareCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SoftwareCategorySortEnum>>>;
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStackshareToolCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<StackshareToolSortEnum>>>;
};


export type QueryGetStackshareToolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<StackshareToolSortEnum>>>;
};


export type QueryGetTechnologiesUsedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetTextFindingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  genericCrawlResultId?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericUrlTextFindingSortEnum>>>;
};


export type QueryGeteLogoArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<KlazifyDomainSortEnum>>>;
};


export type QueryHasCompanyBeenValidatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCrawlResultSortEnum>>>;
};


export type QueryLookupCortexIdArgs = {
  pipedriveId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductCategoryListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SoftwareCategorySortEnum>>>;
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySoftwareCategoryGrowthRatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QuerySoftwareFirmsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<GenericCompanySortEnum>>>;
};


export type QueryStatusMessageArgs = {
  clientId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUploadedMetaDataByPipedriveIdArgs = {
  pipedriveId: Scalars['Int']['input'];
};


export type QueryVerificationSessionUserArgs = {
  companyId: Scalars['Int']['input'];
};

export type RawGenericCrawlResultConnection = {
  __typename?: 'RawGenericCrawlResultConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RawGenericCrawlResultEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `RawGenericCrawlResult` and its cursor. */
export type RawGenericCrawlResultEdge = {
  __typename?: 'RawGenericCrawlResultEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RawGenericCrawlResultSqlType>;
};

export type RawGenericCrawlResultSqlType = Node & {
  __typename?: 'RawGenericCrawlResultSQLType';
  company?: Maybe<CompanyType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  crawlFinishedTime?: Maybe<Scalars['DateTime']['output']>;
  crawlResultObject?: Maybe<Scalars['JSONString']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  employeeEmailCallbackHasHappened?: Maybe<Scalars['Boolean']['output']>;
  expensiveAnalysisExecutionTime?: Maybe<Scalars['DateTime']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fteAnalysisObject?: Maybe<Scalars['JSONString']['output']>;
  hadMlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hasCompanyBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinLowerFte?: Maybe<Scalars['Int']['output']>;
  linkedinUpperFte?: Maybe<Scalars['Int']['output']>;
  listOfEmails?: Maybe<Scalars['JSONString']['output']>;
  listOfMds?: Maybe<Scalars['JSONString']['output']>;
  mongoEmbeddingCreationTime?: Maybe<Scalars['DateTime']['output']>;
  socialResultObject?: Maybe<Scalars['JSONString']['output']>;
  softwareCategory?: Maybe<SoftwareCategorySqlType>;
  softwareCategoryCertainty?: Maybe<Scalars['Float']['output']>;
  softwareCategoryId?: Maybe<Scalars['Int']['output']>;
  specialXoviKeywords?: Maybe<KeywordForCompanySqlTypeConnection>;
  url?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  xoviKeywords?: Maybe<XoviKeywordConnection>;
};


export type RawGenericCrawlResultSqlTypeSpecialXoviKeywordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RawGenericCrawlResultSqlTypeXoviKeywordsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  cpc?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPlaceholderForEmptyXoviResponse?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  markedAsIrrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<XoviKeywordOrderEnum>>>;
};

export type RecentPlatformCrawlConnection = {
  __typename?: 'RecentPlatformCrawlConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RecentPlatformCrawlEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `RecentPlatformCrawl` and its cursor. */
export type RecentPlatformCrawlEdge = {
  __typename?: 'RecentPlatformCrawlEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RecentPlatformCrawlType>;
};

export type RecentPlatformCrawlType = {
  __typename?: 'RecentPlatformCrawlType';
  company?: Maybe<BuyAndBuildTargetAssessmentType>;
  companyCountry?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  crawlFinishedTime?: Maybe<Scalars['DateTime']['output']>;
  crawlResultObject?: Maybe<Scalars['JSONString']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  employeeEmailCallbackHasHappened?: Maybe<Scalars['Boolean']['output']>;
  expensiveAnalysisExecutionTime?: Maybe<Scalars['DateTime']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  fteAnalysis?: Maybe<Scalars['Int']['output']>;
  fteAnalysisObject?: Maybe<Scalars['JSONString']['output']>;
  hadMlModelApplied?: Maybe<Scalars['Boolean']['output']>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  linkedinFte?: Maybe<Scalars['Int']['output']>;
  linkedinLowerFte?: Maybe<Scalars['Int']['output']>;
  linkedinUpperFte?: Maybe<Scalars['Int']['output']>;
  listOfEmails?: Maybe<Scalars['JSONString']['output']>;
  listOfMds?: Maybe<Scalars['JSONString']['output']>;
  mongoEmbeddingCreationTime?: Maybe<Scalars['DateTime']['output']>;
  peOwned?: Maybe<Scalars['Boolean']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  privateCompany?: Maybe<Scalars['Boolean']['output']>;
  privateOwned?: Maybe<Scalars['Boolean']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  socialResultObject?: Maybe<Scalars['JSONString']['output']>;
  softwareCategory?: Maybe<SoftwareCategoryType>;
  softwareCategoryCertainty?: Maybe<Scalars['Float']['output']>;
  softwareCategoryId?: Maybe<Scalars['Int']['output']>;
  specialXoviKeywords?: Maybe<KeywordForCompanySqlTypeConnection>;
  systemType?: Maybe<Scalars['String']['output']>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  xoviKeywords?: Maybe<KeywordForCompanySqlTypeConnection>;
};


export type RecentPlatformCrawlTypeSpecialXoviKeywordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RecentPlatformCrawlTypeXoviKeywordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReclassifyCustomerSegmentMutation = {
  __typename?: 'ReclassifyCustomerSegmentMutation';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveShortlistedBuyAndBuildTarget = {
  __typename?: 'RemoveShortlistedBuyAndBuildTarget';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ResegmentAllCustomersMutation = {
  __typename?: 'ResegmentAllCustomersMutation';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RetrieveSeoDataForKeywords = {
  __typename?: 'RetrieveSeoDataForKeywords';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SicCodeConnection = {
  __typename?: 'SICCodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SicCodeEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `SICCode` and its cursor. */
export type SicCodeEdge = {
  __typename?: 'SICCodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SicCodeSqlType>;
};

export type SicCodeSqlType = Node & {
  __typename?: 'SICCodeSQLType';
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  industryGroup?: Maybe<Scalars['Int']['output']>;
  majorGroup?: Maybe<Scalars['Int']['output']>;
  sicCodeInt?: Maybe<Scalars['Int']['output']>;
  sicCodeStr?: Maybe<Scalars['String']['output']>;
};

export enum SicCodeSortEnum {
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  DivisionAsc = 'division_asc',
  DivisionDesc = 'division_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IndustryGroupAsc = 'industry_group_asc',
  IndustryGroupDesc = 'industry_group_desc',
  MajorGroupAsc = 'major_group_asc',
  MajorGroupDesc = 'major_group_desc',
  SicCodeIntAsc = 'sic_code_int_asc',
  SicCodeIntDesc = 'sic_code_int_desc',
  SicCodeStrAsc = 'sic_code_str_asc',
  SicCodeStrDesc = 'sic_code_str_desc'
}

export type SicCodeType = Node & {
  __typename?: 'SICCodeType';
  dbId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  industryGroup?: Maybe<Scalars['Int']['output']>;
  majorGroup?: Maybe<Scalars['Int']['output']>;
  sicCodeInt?: Maybe<Scalars['Int']['output']>;
  sicCodeStr?: Maybe<Scalars['String']['output']>;
};

export type ScheduledMongoEmbeddingCreationForCompanies = {
  __typename?: 'ScheduledMongoEmbeddingCreationForCompanies';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ScheduledRetrievalOfOpenaiBatchJobs = {
  __typename?: 'ScheduledRetrievalOfOpenaiBatchJobs';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SearchForSourceScrubTags = {
  __typename?: 'SearchForSourceScrubTags';
  companies?: Maybe<Array<Maybe<CompanyType>>>;
};

export type SetCompanySize = {
  __typename?: 'SetCompanySize';
  company?: Maybe<CompanyType>;
  findingUrl?: Maybe<Scalars['String']['output']>;
  fte?: Maybe<Scalars['Int']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  typeOfFinding?: Maybe<Scalars['Int']['output']>;
};

export type SetProductCategoryToZero = {
  __typename?: 'SetProductCategoryToZero';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ShortlistBuyAndBuildTarget = {
  __typename?: 'ShortlistBuyAndBuildTarget';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SoftwareCategoryConnection = {
  __typename?: 'SoftwareCategoryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SoftwareCategoryEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `SoftwareCategory` and its cursor. */
export type SoftwareCategoryEdge = {
  __typename?: 'SoftwareCategoryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SoftwareCategoryType>;
};

export type SoftwareCategoryGrowthRateRawConnection = {
  __typename?: 'SoftwareCategoryGrowthRateRawConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SoftwareCategoryGrowthRateRawEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `SoftwareCategoryGrowthRateRaw` and its cursor. */
export type SoftwareCategoryGrowthRateRawEdge = {
  __typename?: 'SoftwareCategoryGrowthRateRawEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SoftwareCategoryGrowthRateRawType>;
};

export type SoftwareCategoryGrowthRateRawType = Node & {
  __typename?: 'SoftwareCategoryGrowthRateRawType';
  address?: Maybe<Scalars['String']['output']>;
  avgAlltimeInterest?: Maybe<Scalars['Float']['output']>;
  avgInterest24To12MonthsAgo?: Maybe<Scalars['Float']['output']>;
  avgInterestLast12Months?: Maybe<Scalars['Float']['output']>;
  benchmarkingIndex?: Maybe<Scalars['Int']['output']>;
  buyAndBuildSources?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  buyAndBuildTargets?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  city?: Maybe<Scalars['String']['output']>;
  companyAnalyses?: Maybe<CompanyAnalysisTypeConnection>;
  country?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  crawlResults?: Maybe<Array<Maybe<RecentPlatformCrawlType>>>;
  crawlUrl?: Maybe<Scalars['String']['output']>;
  crawlingFinished?: Maybe<Scalars['Boolean']['output']>;
  crawlingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingInProgress?: Maybe<Scalars['Boolean']['output']>;
  crawlingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  doNotCrawl?: Maybe<Scalars['Boolean']['output']>;
  dueDilligence?: Maybe<Scalars['Boolean']['output']>;
  emailPattern?: Maybe<Scalars['String']['output']>;
  excelFiles?: Maybe<Array<Maybe<UploadExcelFileType>>>;
  foundingYear?: Maybe<Scalars['Int']['output']>;
  genericShareholdings?: Maybe<GenericShareholdingSqlTypeConnection>;
  hasBeenValidated?: Maybe<Scalars['Boolean']['output']>;
  hrbNumber?: Maybe<Scalars['String']['output']>;
  iabDomainCategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  initialSource?: Maybe<Scalars['String']['output']>;
  interestGrowthRate?: Maybe<Scalars['Float']['output']>;
  isBidPortfolioCompany?: Maybe<Scalars['Boolean']['output']>;
  isGenericIndustryCompany?: Maybe<Scalars['Boolean']['output']>;
  isItCompanyCompany?: Maybe<Scalars['Boolean']['output']>;
  isSaasCompany?: Maybe<Scalars['Boolean']['output']>;
  isSoftwareCompany?: Maybe<Scalars['Boolean']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  managingDirector?: Maybe<Scalars['String']['output']>;
  numberOfFailedCrawls?: Maybe<Scalars['Int']['output']>;
  openregCompanies?: Maybe<OpenRegCompanySqlTypeConnection>;
  parentCompany?: Maybe<BuyAndBuildTargetAssessmentType>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  precalculatedKpis?: Maybe<KpiPreCalculationTypeConnection>;
  shareholders?: Maybe<GenericShareHolderTypeConnection>;
  shortName?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  sicCodeCertainty?: Maybe<Scalars['Float']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  subCategory1?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<BuyAndBuildTargetAssessmentTypeConnection>;
  subsidiaryOf?: Maybe<Scalars['Int']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  uniqueDomain?: Maybe<Scalars['String']['output']>;
  validationDatetime?: Maybe<Scalars['DateTime']['output']>;
  validationUser?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type SoftwareCategoryGrowthRateRawTypeBuyAndBuildSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypeBuyAndBuildTargetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypeCompanyAnalysesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypeGenericShareholdingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypeOpenregCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypePrecalculatedKpisArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypeShareholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SoftwareCategoryGrowthRateRawTypeSubsidiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SoftwareCategoryGrowthRateType = Node & {
  __typename?: 'SoftwareCategoryGrowthRateType';
  avgAlltimeInterest?: Maybe<Scalars['Float']['output']>;
  avgInterest24To12MonthsAgo?: Maybe<Scalars['Float']['output']>;
  avgInterestLast12Months?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  interestGrowthRate?: Maybe<Scalars['Float']['output']>;
  subCategory1?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
};

export type SoftwareCategorySqlType = Node & {
  __typename?: 'SoftwareCategorySQLType';
  categoryDescription?: Maybe<Scalars['String']['output']>;
  categoryDescriptionEmbedding?: Maybe<Scalars['JSONString']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  g2Url?: Maybe<Scalars['String']['output']>;
  genericCrawlResults?: Maybe<Array<Maybe<RecentPlatformCrawlType>>>;
  googleTerm?: Maybe<GoogleTermType>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  productCategorizations?: Maybe<ProductCategorizationTypeConnection>;
  subCategory1?: Maybe<Scalars['String']['output']>;
  subCategory2?: Maybe<Scalars['String']['output']>;
  subCategory3?: Maybe<Scalars['String']['output']>;
  subCategory4?: Maybe<Scalars['String']['output']>;
};


export type SoftwareCategorySqlTypeProductCategorizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum SoftwareCategorySortEnum {
  CategoryDescriptionAsc = 'category_description_asc',
  CategoryDescriptionDesc = 'category_description_desc',
  CategoryDescriptionEmbeddingAsc = 'category_description_embedding_asc',
  CategoryDescriptionEmbeddingDesc = 'category_description_embedding_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  G2UrlAsc = 'g2_url_asc',
  G2UrlDesc = 'g2_url_desc',
  GoogleTermIdAsc = 'google_term_id_asc',
  GoogleTermIdDesc = 'google_term_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  SubCategory_1Asc = 'sub_category_1_asc',
  SubCategory_1Desc = 'sub_category_1_desc',
  SubCategory_2Asc = 'sub_category_2_asc',
  SubCategory_2Desc = 'sub_category_2_desc',
  SubCategory_3Asc = 'sub_category_3_asc',
  SubCategory_3Desc = 'sub_category_3_desc',
  SubCategory_4Asc = 'sub_category_4_asc',
  SubCategory_4Desc = 'sub_category_4_desc'
}

export type SoftwareCategoryType = Node & {
  __typename?: 'SoftwareCategoryType';
  categoryDescription?: Maybe<Scalars['String']['output']>;
  categoryDescriptionEmbedding?: Maybe<Scalars['JSONString']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  g2Url?: Maybe<Scalars['String']['output']>;
  genericCrawlResults?: Maybe<Array<Maybe<RecentPlatformCrawlType>>>;
  googleTerm?: Maybe<GoogleTermType>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  productCategorizations?: Maybe<ProductCategorizationTypeConnection>;
  subCategory1?: Maybe<Scalars['String']['output']>;
  subCategory2?: Maybe<Scalars['String']['output']>;
  subCategory3?: Maybe<Scalars['String']['output']>;
  subCategory4?: Maybe<Scalars['String']['output']>;
};


export type SoftwareCategoryTypeProductCategorizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SoftwareCategoryTypeConnection = {
  __typename?: 'SoftwareCategoryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SoftwareCategoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SoftwareCategoryType` and its cursor. */
export type SoftwareCategoryTypeEdge = {
  __typename?: 'SoftwareCategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SoftwareCategoryType>;
};

export type StackshareToolConnection = {
  __typename?: 'StackshareToolConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StackshareToolEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `StackshareTool` and its cursor. */
export type StackshareToolEdge = {
  __typename?: 'StackshareToolEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<StackshareToolType>;
};

export enum StackshareToolSortEnum {
  BreadcrumbAsc = 'breadcrumb_asc',
  BreadcrumbDesc = 'breadcrumb_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  FollowersAsc = 'followers_asc',
  FollowersDesc = 'followers_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsApiFrameworkAsc = 'is_api_framework_asc',
  IsApiFrameworkDesc = 'is_api_framework_desc',
  IsBackendFrameworkAsc = 'is_backend_framework_asc',
  IsBackendFrameworkDesc = 'is_backend_framework_desc',
  IsFrontendFrameworkAsc = 'is_frontend_framework_asc',
  IsFrontendFrameworkDesc = 'is_frontend_framework_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  NerNameAsc = 'ner_name_asc',
  NerNameDesc = 'ner_name_desc',
  StacksUsedAsc = 'stacks_used_asc',
  StacksUsedDesc = 'stacks_used_desc',
  TypeAsc = 'type_asc',
  TypeDesc = 'type_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc',
  VotesAsc = 'votes_asc',
  VotesDesc = 'votes_desc',
  YearOfInitialReleaseAsc = 'year_of_initial_release_asc',
  YearOfInitialReleaseDesc = 'year_of_initial_release_desc'
}

export type StackshareToolType = Node & {
  __typename?: 'StackshareToolType';
  breadcrumb?: Maybe<Scalars['String']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isApiFramework?: Maybe<Scalars['Boolean']['output']>;
  isBackendFramework?: Maybe<Scalars['Boolean']['output']>;
  isFrontendFramework?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nerName?: Maybe<Scalars['String']['output']>;
  stacksUsed?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  usedIn?: Maybe<GenericCompanyTechUseWithStackshareToolSqlTypeConnection>;
  votes?: Maybe<Scalars['Int']['output']>;
  yearOfInitialRelease?: Maybe<Scalars['Int']['output']>;
};


export type StackshareToolTypeUsedInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type StartCompanyVerificationSession = {
  __typename?: 'StartCompanyVerificationSession';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StartCrawlerMutation = {
  __typename?: 'StartCrawlerMutation';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TeamQualityType = {
  __typename?: 'TeamQualityType';
  medianEmploymentMonths?: Maybe<Scalars['Float']['output']>;
  medianPreviousEmployers?: Maybe<Scalars['Float']['output']>;
  medianTotalEmploymentMonths?: Maybe<Scalars['Float']['output']>;
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  teamQualityPercentage?: Maybe<Scalars['String']['output']>;
};

export type TechUseType = Node & {
  __typename?: 'TechUseType';
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  findingUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  markedAsFalsePositive?: Maybe<Scalars['Boolean']['output']>;
  matchRelevance?: Maybe<Scalars['Float']['output']>;
  stackshareTool?: Maybe<ProductTechnologylType>;
  stackshareToolId?: Maybe<Scalars['Int']['output']>;
};

export type TestDirectoryNameFinder = {
  __typename?: 'TestDirectoryNameFinder';
  companyId?: Maybe<Scalars['Int']['output']>;
};

export type TestS3Event = {
  __typename?: 'TestS3Event';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type TextFindingConnection = {
  __typename?: 'TextFindingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TextFindingEdge>>;
  pageInfo?: Maybe<DbIdPageInfo>;
};

/** A Relay edge containing a `TextFinding` and its cursor. */
export type TextFindingEdge = {
  __typename?: 'TextFindingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TextFindingType>;
};

export type TextFindingType = {
  __typename?: 'TextFindingType';
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  fteCount?: Maybe<Scalars['Int']['output']>;
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type TrainBuyAndBuildRelevanceModel = {
  __typename?: 'TrainBuyAndBuildRelevanceModel';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type TrainCallabilityModel = {
  __typename?: 'TrainCallabilityModel';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type TransferBuyAndBuildTargetsToPipedrive = {
  __typename?: 'TransferBuyAndBuildTargetsToPipedrive';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCortexConfig = {
  __typename?: 'UpdateCortexConfig';
  cortexConfig?: Maybe<CortexConfigType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCustomerSegmentMutation = {
  __typename?: 'UpdateCustomerSegmentMutation';
  definitionUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  segmentDescription?: Maybe<Scalars['String']['output']>;
  segmentName?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UploadExcelCellInput = {
  callPythonType: Scalars['String']['input'];
  cellFormula?: InputMaybe<Scalars['String']['input']>;
  cellType: Scalars['String']['input'];
  cellValue: Scalars['String']['input'];
  column: Scalars['Int']['input'];
  isHeader?: InputMaybe<Scalars['Boolean']['input']>;
  row: Scalars['Int']['input'];
  sheetId: Scalars['Int']['input'];
};

export type UploadExcelCellMutation = {
  __typename?: 'UploadExcelCellMutation';
  cell?: Maybe<UploadExcelCellType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UploadExcelCellType = {
  __typename?: 'UploadExcelCellType';
  callPythonType?: Maybe<Scalars['String']['output']>;
  cellFormula?: Maybe<Scalars['String']['output']>;
  cellType?: Maybe<Scalars['String']['output']>;
  cellValue?: Maybe<Scalars['String']['output']>;
  column?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isHeader?: Maybe<Scalars['Boolean']['output']>;
  row?: Maybe<Scalars['Int']['output']>;
  sheet?: Maybe<UploadExcelSheetType>;
  sheetId?: Maybe<Scalars['Int']['output']>;
};

export type UploadExcelFileChunkMutation = {
  __typename?: 'UploadExcelFileChunkMutation';
  file?: Maybe<UploadExcelFileType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UploadExcelFileInput = {
  blobContent?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  isBenchmarkingFile?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomerFile?: InputMaybe<Scalars['Boolean']['input']>;
  isLboFile?: InputMaybe<Scalars['Boolean']['input']>;
  uploaderName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadExcelFileMutation = {
  __typename?: 'UploadExcelFileMutation';
  file?: Maybe<UploadExcelFileType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UploadExcelFileType = {
  __typename?: 'UploadExcelFileType';
  blobContent?: Maybe<Scalars['JSONString']['output']>;
  company?: Maybe<BuyAndBuildTargetAssessmentType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isBenchmarkingFile?: Maybe<Scalars['Boolean']['output']>;
  isCustomerFile?: Maybe<Scalars['Boolean']['output']>;
  isLboFile?: Maybe<Scalars['Boolean']['output']>;
  sheets?: Maybe<Array<Maybe<UploadExcelSheetType>>>;
  uploadCompleted?: Maybe<Scalars['Boolean']['output']>;
  uploadDate?: Maybe<Scalars['DateTime']['output']>;
  uploaderName?: Maybe<Scalars['String']['output']>;
};

export type UploadExcelRowInput = {
  cells?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  row?: InputMaybe<Scalars['Int']['input']>;
  rows?: InputMaybe<Array<InputMaybe<ExcelRowInput>>>;
  sheetId: Scalars['Int']['input'];
  startRow?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadExcelRowMutation = {
  __typename?: 'UploadExcelRowMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UploadExcelSheetInput = {
  excelFileId: Scalars['Int']['input'];
  sheetName: Scalars['String']['input'];
};

export type UploadExcelSheetMutation = {
  __typename?: 'UploadExcelSheetMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  sheet?: Maybe<UploadExcelSheetType>;
};

export type UploadExcelSheetType = {
  __typename?: 'UploadExcelSheetType';
  cells?: Maybe<Array<Maybe<UploadExcelCellType>>>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  excelFile?: Maybe<UploadExcelFileType>;
  excelFileId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  sheetName?: Maybe<Scalars['String']['output']>;
};

export type UploadFileResponse = {
  __typename?: 'UploadFileResponse';
  url?: Maybe<Scalars['String']['output']>;
};

export type UpvoteFindingsToken = {
  __typename?: 'UpvoteFindingsToken';
  ok?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<FindingsTokenType>;
};

export enum XoviKeywordsSortEnum {
  ChangeAsc = 'change_asc',
  ChangeDesc = 'change_desc',
  CompetitorDensityAsc = 'competitorDensity_asc',
  CompetitorDensityDesc = 'competitorDensity_desc',
  CpcAsc = 'cpc_asc',
  CpcDesc = 'cpc_desc',
  CreatedFromDataForSeoAsc = 'created_from_data_for_seo_asc',
  CreatedFromDataForSeoDesc = 'created_from_data_for_seo_desc',
  DatetimeCreatedAsc = 'datetime_created_asc',
  DatetimeCreatedDesc = 'datetime_created_desc',
  DatetimeModifiedAsc = 'datetime_modified_asc',
  DatetimeModifiedDesc = 'datetime_modified_desc',
  GenericCrawlResultIdAsc = 'generic_crawl_result_id_asc',
  GenericCrawlResultIdDesc = 'generic_crawl_result_id_desc',
  GoogleTermIdAsc = 'google_term_id_asc',
  GoogleTermIdDesc = 'google_term_id_desc',
  HasBeenSearchedAsc = 'has_been_searched_asc',
  HasBeenSearchedDesc = 'has_been_searched_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  IsPlaceholderForEmptyXoviResponseAsc = 'is_placeholder_for_empty_xovi_response_asc',
  IsPlaceholderForEmptyXoviResponseDesc = 'is_placeholder_for_empty_xovi_response_desc',
  KeywordAsc = 'keyword_asc',
  KeywordDesc = 'keyword_desc',
  KeywordEmbeddingAsc = 'keyword_embedding_asc',
  KeywordEmbeddingDesc = 'keyword_embedding_desc',
  KeywordLanguageAsc = 'keyword_language_asc',
  KeywordLanguageDesc = 'keyword_language_desc',
  MarkedAsIrrelevantAsc = 'marked_as_irrelevant_asc',
  MarkedAsIrrelevantDesc = 'marked_as_irrelevant_desc',
  PositionAsc = 'position_asc',
  PositionDesc = 'position_desc',
  SearchEngineAsc = 'search_engine_asc',
  SearchEngineDesc = 'search_engine_desc',
  SeoDataAnalysisIdAsc = 'seo_data_analysis_id_asc',
  SeoDataAnalysisIdDesc = 'seo_data_analysis_id_desc',
  SevoAsc = 'sevo_asc',
  SevoDesc = 'sevo_desc',
  UrlAsc = 'url_asc',
  UrlDesc = 'url_desc'
}

export type XoviKeywordConnection = {
  __typename?: 'XoviKeywordConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<XoviKeywordEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `XoviKeyword` and its cursor. */
export type XoviKeywordEdge = {
  __typename?: 'XoviKeywordEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<XoviKeywordSqlType>;
};

export enum XoviKeywordOrderEnum {
  CpcAsc = 'CPC_ASC',
  CpcDesc = 'CPC_DESC'
}

export type XoviKeywordSqlType = Node & {
  __typename?: 'XoviKeywordSQLType';
  change?: Maybe<Scalars['Int']['output']>;
  competitorDensity?: Maybe<Scalars['Int']['output']>;
  cpc?: Maybe<Scalars['Float']['output']>;
  createdFromDataForSeo?: Maybe<Scalars['Boolean']['output']>;
  datetimeCreated?: Maybe<Scalars['DateTime']['output']>;
  datetimeModified?: Maybe<Scalars['DateTime']['output']>;
  dbId?: Maybe<Scalars['Int']['output']>;
  genericCrawlResultId?: Maybe<Scalars['Int']['output']>;
  genericCrawlResults?: Maybe<RecentPlatformCrawlType>;
  googleTermId?: Maybe<Scalars['Int']['output']>;
  hasBeenSearched?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isPlaceholderForEmptyXoviResponse?: Maybe<Scalars['Boolean']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  keywordEmbedding?: Maybe<Scalars['JSONString']['output']>;
  keywordLanguage?: Maybe<Scalars['String']['output']>;
  markedAsIrrelevant?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  searchEngine?: Maybe<Scalars['String']['output']>;
  seoDataAnalysisId?: Maybe<Scalars['Int']['output']>;
  sevo?: Maybe<Scalars['Int']['output']>;
  specialGenericCrawlResults?: Maybe<RecentPlatformCrawlType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DeleteAllBenchmarkingDataForCompany = {
  __typename?: 'deleteAllBenchmarkingDataForCompany';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FinishExcelFileUpload = {
  __typename?: 'finishExcelFileUpload';
  file?: Maybe<UploadExcelFileType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkCustomerReferenceObsolete = {
  __typename?: 'markCustomerReferenceObsolete';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpgradeAlembic = {
  __typename?: 'upgradeAlembic';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DbIdPageInfoFragmentFragment = { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null };

export type AddBuyAndBuildTargetsFromSourcescrubMutationVariables = Exact<{
  authId?: InputMaybe<Scalars['String']['input']>;
  softwareCompanyId: Scalars['Int']['input'];
}>;


export type AddBuyAndBuildTargetsFromSourcescrubMutation = { __typename?: 'Mutations', addBuyAndBuildTargetsFromSourcescrub?: { __typename?: 'AddBuyAndBuildTargetsFromSourcescrub', ok?: boolean | null } | null };

export type AddSegmentCustomerMutationVariables = Exact<{
  input: CustomerSegmentInput;
}>;


export type AddSegmentCustomerMutation = { __typename?: 'Mutations', addCustomerSegment?: { __typename?: 'AddCustomerSegmentMutation', success?: boolean | null } | null };

export type AddIndustryMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  referencedAt: Scalars['String']['input'];
  sicCode: Scalars['String']['input'];
}>;


export type AddIndustryMutation = { __typename?: 'Mutations', addIndustry?: { __typename?: 'AddIndustry', companyId?: number | null, sicCode?: string | null, referencedAt?: string | null } | null };

export type AddSoftwareCategoryToCompanyMutationVariables = Exact<{
  softwareCategoryId: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
}>;


export type AddSoftwareCategoryToCompanyMutation = { __typename?: 'Mutations', addSoftwareCategoryToCompany?: { __typename?: 'AddSoftwareCategoryToCompany', success?: boolean | null } | null };

export type CortexFeedbackPipedriveBridgeMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  addOnForBidId: Scalars['Int']['input'];
}>;


export type CortexFeedbackPipedriveBridgeMutation = { __typename?: 'Mutations', cortexFeedbackPipedriveBridge?: { __typename?: 'CortexFeedbackPipedriveBridge', success?: boolean | null } | null };

export type CreateBenchmarkPlMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3: Scalars['String']['input'];
  valuationLevel: Scalars['Int']['input'];
  period: Scalars['Int']['input'];
  periodType: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  value: Scalars['Float']['input'];
}>;


export type CreateBenchmarkPlMutation = { __typename?: 'Mutations', createBenchmarkPl?: { __typename?: 'CreateBenchmarkPL', benchmarkingData?: { __typename?: 'BenchmarkPLType', id: string, companyId?: number | null, value?: number | null } | null } | null };

export type CreateKeywordsMutationVariables = Exact<{
  genericCrawlResultId: Scalars['Int']['input'];
  keywords: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type CreateKeywordsMutation = { __typename?: 'Mutations', createKeywords?: { __typename?: 'CreateXoviKeywords', keywords?: string | null, genericCrawlResultId?: number | null } | null };

export type CreatePdOrganizationMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  authId?: InputMaybe<Scalars['String']['input']>;
  newPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  addOnExisting?: InputMaybe<Scalars['Boolean']['input']>;
  addOnFuture?: InputMaybe<Scalars['Boolean']['input']>;
  saas?: InputMaybe<Scalars['Boolean']['input']>;
  venture?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Scalars['Boolean']['input']>;
  partOfGroup?: InputMaybe<Scalars['Boolean']['input']>;
  otherOwnership?: InputMaybe<Scalars['Boolean']['input']>;
  sellsHardware?: InputMaybe<Scalars['Boolean']['input']>;
  tooMuchConsulting?: InputMaybe<Scalars['Boolean']['input']>;
  reseller?: InputMaybe<Scalars['Boolean']['input']>;
  wrongBusinessModel?: InputMaybe<Scalars['Boolean']['input']>;
  b2c?: InputMaybe<Scalars['Boolean']['input']>;
  wrongGeography?: InputMaybe<Scalars['Boolean']['input']>;
  tooBig?: InputMaybe<Scalars['Boolean']['input']>;
  tooSmall?: InputMaybe<Scalars['Boolean']['input']>;
  createOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  createDeal?: InputMaybe<Scalars['Boolean']['input']>;
  selectValue?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreatePdOrganizationMutation = { __typename?: 'Mutations', cortexFeedbackPipedriveBridge?: { __typename?: 'CortexFeedbackPipedriveBridge', success?: boolean | null, message?: string | null } | null };

export type CreateUpdateCompanyTechUseMutationVariables = Exact<{
  stackshareToolId: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
}>;


export type CreateUpdateCompanyTechUseMutation = { __typename?: 'Mutations', createUpdateCompanyTechUse?: { __typename?: 'CreateUpdateCompanyTechUse', companyId?: number | null } | null };

export type CreateUpdateGenericShareholderMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  typeOfInvestor?: InputMaybe<Scalars['String']['input']>;
  yearBornOrFounded?: InputMaybe<Scalars['Int']['input']>;
  genericCompanyId?: InputMaybe<Scalars['Int']['input']>;
  genericShareholderId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateUpdateGenericShareholderMutation = { __typename?: 'Mutations', createUpdateGenericShareholder?: { __typename?: 'CreateUpdateGenericShareholder', dbId?: number | null, name?: string | null } | null };

export type CreateUpdateGenericShareholdingMutationVariables = Exact<{
  genericCompanyId?: InputMaybe<Scalars['Int']['input']>;
  genericShareholderId?: InputMaybe<Scalars['Int']['input']>;
  genericShareholdingId?: InputMaybe<Scalars['Int']['input']>;
  holding?: InputMaybe<Scalars['Float']['input']>;
  holdingCount?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateUpdateGenericShareholdingMutation = { __typename?: 'Mutations', createUpdateGenericShareholding?: { __typename?: 'CreateUpdateGenericShareholding', genericCompanyId?: number | null, genericShareholderId?: number | null, holdingCount?: number | null, holding?: number | null } | null };

export type CreateUpdateKeywordMutationVariables = Exact<{
  genericCrawlResultId: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
}>;


export type CreateUpdateKeywordMutation = { __typename?: 'Mutations', createUpdateKeyword?: { __typename?: 'CreateUpdateXoviKeyword', keyword?: string | null } | null };

export type DeleteAllBmForCompanyMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type DeleteAllBmForCompanyMutation = { __typename?: 'Mutations', deleteAllBmForCompany?: { __typename?: 'deleteAllBenchmarkingDataForCompany', success?: boolean | null } | null };

export type DeleteCompanyTechUseMutationVariables = Exact<{
  techUseId: Scalars['Int']['input'];
}>;


export type DeleteCompanyTechUseMutation = { __typename?: 'Mutations', deleteCompanyTechUse?: { __typename?: 'DeleteCompanyTechUse', message?: string | null, success?: boolean | null } | null };

export type DeleteCustomerReferenceMutationVariables = Exact<{
  customerReferenceId: Scalars['Int']['input'];
}>;


export type DeleteCustomerReferenceMutation = { __typename?: 'Mutations', deleteCustomerReference?: { __typename?: 'DeleteCustomerReference', success?: boolean | null, message?: string | null } | null };

export type DeleteSegmentMutationVariables = Exact<{
  segmentId: Scalars['Int']['input'];
}>;


export type DeleteSegmentMutation = { __typename?: 'Mutations', deleteCustomerSegment?: { __typename?: 'DeleteCustomerSegmentMutation', success?: boolean | null } | null };

export type DeleteGenericShareholdingMutationVariables = Exact<{
  genericShareholdingId: Scalars['Int']['input'];
}>;


export type DeleteGenericShareholdingMutation = { __typename?: 'Mutations', deleteGenericShareholding?: { __typename?: 'DeleteGenericShareholding', success?: boolean | null } | null };

export type DeleteKeywordMutationVariables = Exact<{
  keywordId: Scalars['Int']['input'];
}>;


export type DeleteKeywordMutation = { __typename?: 'Mutations', deleteKeyword?: { __typename?: 'MarkXOVIKeywordAsIrrelevant', success?: boolean | null, message?: string | null } | null };

export type DeleteKeywordsMutationVariables = Exact<{
  keywordIds: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteKeywordsMutation = { __typename?: 'Mutations', deleteKeywords?: { __typename?: 'MarkXOVIKeywordsAsIrrelevant', success?: boolean | null, message?: string | null } | null };

export type DownvoteFindingsTokenMutationVariables = Exact<{
  tokenId: Scalars['Int']['input'];
}>;


export type DownvoteFindingsTokenMutation = { __typename?: 'Mutations', downvoteFindingsToken?: { __typename?: 'DownvoteFindingsToken', ok?: boolean | null, token?: { __typename?: 'FindingsTokenType', id: string } | null } | null };

export type EditCompanyMutationVariables = Exact<{
  benchmarkingIndex?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  court?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  hrbNumber?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  pipedriveId?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  sicCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditCompanyMutation = { __typename?: 'Mutations', editCompany?: { __typename?: 'EditCompany', legalName?: string | null, shortName?: string | null, street?: string | null, city?: string | null, zipCode?: string | null, country?: string | null, linkedinUrl?: string | null, pipedriveId?: number | null, hrbNumber?: string | null, court?: string | null, benchmarkingIndex?: number | null, sicCode?: string | null, foundingYear?: number | null } | null };

export type ExcelUploadFileMutationVariables = Exact<{
  input: UploadExcelFileInput;
}>;


export type ExcelUploadFileMutation = { __typename?: 'Mutations', uploadExcelFile?: { __typename?: 'UploadExcelFileMutation', ok?: boolean | null, file?: { __typename?: 'UploadExcelFileType', id: string, fileName?: string | null } | null } | null };

export type ExcelUploadRowMutationVariables = Exact<{
  input: UploadExcelRowInput;
}>;


export type ExcelUploadRowMutation = { __typename?: 'Mutations', uploadExcelRow?: { __typename?: 'UploadExcelRowMutation', ok?: boolean | null } | null };

export type ExcelUploadSheetMutationVariables = Exact<{
  input: UploadExcelSheetInput;
}>;


export type ExcelUploadSheetMutation = { __typename?: 'Mutations', uploadExcelSheet?: { __typename?: 'UploadExcelSheetMutation', ok?: boolean | null, sheet?: { __typename?: 'UploadExcelSheetType', id: string, sheetName?: string | null } | null } | null };

export type FinishExcelFileUploadMutationVariables = Exact<{
  input: Scalars['Int']['input'];
}>;


export type FinishExcelFileUploadMutation = { __typename?: 'Mutations', finishExcelFileUpload?: { __typename?: 'finishExcelFileUpload', ok?: boolean | null } | null };

export type InsertCertainProductCategoryMutationVariables = Exact<{
  productCategorizationId: Scalars['Int']['input'];
}>;


export type InsertCertainProductCategoryMutation = { __typename?: 'Mutations', insertCertainProductCategory?: { __typename?: 'InsertCertainProductCategory', message?: string | null, success?: boolean | null } | null };

export type LabelBuyAndBuildTargetQualityMutationVariables = Exact<{
  quality: Scalars['Float']['input'];
  softwareCompanyId: Scalars['Int']['input'];
  targetCompanyId: Scalars['Int']['input'];
}>;


export type LabelBuyAndBuildTargetQualityMutation = { __typename?: 'Mutations', labelBuyAndBuildTargetQuality?: { __typename?: 'LabelBuyAndBuildTargetQuality', ok?: boolean | null } | null };

export type MarkCompanyAsNotSoftwareMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type MarkCompanyAsNotSoftwareMutation = { __typename?: 'Mutations', markCompanyAsNotSoftware?: { __typename?: 'MarkCompanyAsNotSoftware', ok?: boolean | null } | null };

export type MarkCompanyAsSoftwareMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  crawlResultId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MarkCompanyAsSoftwareMutation = { __typename?: 'Mutations', markCompanyAsSoftware?: { __typename?: 'MarkCompanyAsSoftware', ok?: boolean | null } | null };

export type MarkCustomerReferenceObsoleteMutationVariables = Exact<{
  customerReferenceId: Scalars['Int']['input'];
}>;


export type MarkCustomerReferenceObsoleteMutation = { __typename?: 'Mutations', markCustomerReferenceObsolete?: { __typename?: 'markCustomerReferenceObsolete', success?: boolean | null, message?: string | null } | null };

export type MarkProductAsHorizontalMutationVariables = Exact<{
  softwareCompanyId: Scalars['Int']['input'];
}>;


export type MarkProductAsHorizontalMutation = { __typename?: 'Mutations', markProductAsHorizontal?: { __typename?: 'MarkProductAsHorizontal', message?: string | null, success?: boolean | null } | null };

export type PrecalculateAllKpisMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type PrecalculateAllKpisMutation = { __typename?: 'Mutations', precalculateAllKpis?: { __typename?: 'PreCalculateAllKPIs', ok?: boolean | null } | null };

export type RemoveShortlistedBuyAndBuildTargetsMutationVariables = Exact<{
  softwareCompanyId: Scalars['Int']['input'];
  targetCompanyId: Scalars['Int']['input'];
}>;


export type RemoveShortlistedBuyAndBuildTargetsMutation = { __typename?: 'Mutations', removeShortlistedBuyAndBuildTargets?: { __typename?: 'RemoveShortlistedBuyAndBuildTarget', ok?: boolean | null } | null };

export type ResegmentCustomersMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type ResegmentCustomersMutation = { __typename?: 'Mutations', resegmentAllCustomers?: { __typename?: 'ResegmentAllCustomersMutation', success?: boolean | null } | null };

export type SetCompanySizeMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  fte: Scalars['Int']['input'];
  findingUrl: Scalars['String']['input'];
  typeOfFinding: Scalars['Int']['input'];
}>;


export type SetCompanySizeMutation = { __typename?: 'Mutations', setCompanySize?: { __typename?: 'SetCompanySize', ok?: boolean | null, typeOfFinding?: number | null, findingUrl?: string | null, fte?: number | null } | null };

export type SetProductCategoryToZeroMutationVariables = Exact<{
  productCategorizationId: Scalars['Int']['input'];
}>;


export type SetProductCategoryToZeroMutation = { __typename?: 'Mutations', setProductCategoryToZero?: { __typename?: 'SetProductCategoryToZero', message?: string | null, success?: boolean | null } | null };

export type ShortlistBuyAndBuildTargetMutationVariables = Exact<{
  softwareCompanyId: Scalars['Int']['input'];
  targetCompanyId: Scalars['Int']['input'];
}>;


export type ShortlistBuyAndBuildTargetMutation = { __typename?: 'Mutations', shortlistBuyAndBuildTarget?: { __typename?: 'ShortlistBuyAndBuildTarget', ok?: boolean | null } | null };

export type StartCrawlMutationVariables = Exact<{
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  forceDeepAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  forceSw?: InputMaybe<Scalars['Boolean']['input']>;
  pipedriveId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type StartCrawlMutation = { __typename?: 'Mutations', startCrawler?: { __typename?: 'StartCrawlerMutation', success?: boolean | null } | null };

export type StartVerificationSessionMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  authId: Scalars['String']['input'];
}>;


export type StartVerificationSessionMutation = { __typename?: 'Mutations', startCompanyVerificationSession?: { __typename?: 'StartCompanyVerificationSession', success?: boolean | null, message?: string | null } | null };

export type TransferBuyAndBuildTargetsToPipedriveMutationVariables = Exact<{
  softwareCompanyId: Scalars['Int']['input'];
}>;


export type TransferBuyAndBuildTargetsToPipedriveMutation = { __typename?: 'Mutations', transferBuyAndBuildTargetsToPipedrive?: { __typename?: 'TransferBuyAndBuildTargetsToPipedrive', ok?: boolean | null } | null };

export type UpdateCortexConfigMutationVariables = Exact<{
  attributes: Scalars['JSONString']['input'];
}>;


export type UpdateCortexConfigMutation = { __typename?: 'Mutations', updateCortexConfig?: { __typename?: 'UpdateCortexConfig', ok?: boolean | null } | null };

export type UpdateSegmentForCustomerMutationVariables = Exact<{
  input: CustomerSegmentInput;
  segmentId: Scalars['Int']['input'];
}>;


export type UpdateSegmentForCustomerMutation = { __typename?: 'Mutations', updateCustomerSegment?: { __typename?: 'UpdateCustomerSegmentMutation', success?: boolean | null } | null };

export type UploadFileMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
  file: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  isBmFile: Scalars['Boolean']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutations', uploadFile?: { __typename?: 'UploadFileResponse', url?: string | null } | null };

export type UpvoteFindingsTokenMutationVariables = Exact<{
  tokenId: Scalars['Int']['input'];
}>;


export type UpvoteFindingsTokenMutation = { __typename?: 'Mutations', upvoteFindingsToken?: { __typename?: 'UpvoteFindingsToken', ok?: boolean | null, token?: { __typename?: 'FindingsTokenType', id: string } | null } | null };

export type BuyAndBuildListQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
  isShortlisted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BuyAndBuildListQuery = { __typename?: 'Query', companyAssesment?: { __typename?: 'CompanyAssesmentConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyAssesmentEdge', node?: { __typename?: 'CompanyAssessmentType', dbId?: number | null, buyAndBuildTargets?: { __typename?: 'GenericBuyAndBuildRelevanceConnection', pageInfo?: { __typename?: 'DbIdPageInfo', startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'GenericBuyAndBuildRelevanceEdge', node?: { __typename?: 'GenericBuyAndBuildRelevanceSQLType', isShortlistedCompany1?: boolean | null, manuallyLabeledScore?: number | null, buyAndBuildRelevance?: number | null, company?: { __typename?: 'BABCompanyType', dbId?: number | null, uniqueDomain?: string | null, iabDomainCategory?: string | null, fte?: number | null, country?: string | null, summaryDescription?: string | null, latestCrawlResult?: { __typename?: 'GenericCrawlResultSQLType', xoviKeywords?: { __typename?: 'XoviKeywordConnection', edges: Array<{ __typename?: 'XoviKeywordEdge', node?: { __typename?: 'XoviKeywordSQLType', keyword?: string | null, cpc?: number | null } | null } | null> } | null } | null } | null } | null } | null> } | null } | null } | null> } | null };

export type CompaniesByCategoriesQueryVariables = Exact<{
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
}>;


export type CompaniesByCategoriesQuery = { __typename?: 'Query', companiesByCategories?: Array<{ __typename?: 'CompanyAssessmentType', id: string, dbId?: number | null, shortName?: string | null, country?: string | null, uniqueDomain?: string | null } | null> | null };

export type CompaniesByIndustriesQueryVariables = Exact<{
  sicCodes: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type CompaniesByIndustriesQuery = { __typename?: 'Query', companiesByIndustries?: Array<{ __typename?: 'CompanyAssessmentType', id: string, dbId?: number | null, shortName?: string | null, country?: string | null, uniqueDomain?: string | null } | null> | null };

export type CompaniesByIndustryAndCategoryQueryVariables = Exact<{
  sicCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
}>;


export type CompaniesByIndustryAndCategoryQuery = { __typename?: 'Query', companiesByIndustryAndCategory?: Array<{ __typename?: 'CompanyAssessmentType', id: string, dbId?: number | null, shortName?: string | null, country?: string | null, uniqueDomain?: string | null } | null> | null };

export type CompanyAssesmentQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type CompanyAssesmentQuery = { __typename?: 'Query', companyAssesment?: { __typename?: 'CompanyAssesmentConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyAssesmentEdge', cursor: string, node?: { __typename?: 'CompanyAssessmentType', id: string, maxFte?: number | null } | null } | null> } | null };

export type CompanyInboxListQueryVariables = Exact<{
  country?: InputMaybe<Scalars['String']['input']>;
  platformId?: InputMaybe<Scalars['Int']['input']>;
  newPlatforms?: InputMaybe<Scalars['Boolean']['input']>;
  prioritizedPlatforms?: InputMaybe<Scalars['Boolean']['input']>;
  falseNegatives?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CompanyInboxListQuery = { __typename?: 'Query', companyInboxList?: Array<{ __typename?: 'CompanyInboxType', dbId?: number | null, uniqueDomain?: string | null, country?: string | null, sicCode?: string | null, sicCodeCertainty?: number | null, fte?: number | null, privateOwned?: boolean | null, datetimeCreated?: any | null, iabDomainCategory?: string | null, peOwned?: boolean | null, productCategorization?: { __typename?: 'ProductCategorizationConnection', edges: Array<{ __typename?: 'ProductCategorizationEdge', node?: { __typename?: 'ProductCategorizationType', id: string, softwareCategory?: { __typename?: 'SoftwareCategoryType', subCategory1?: string | null } | null } | null } | null> } | null } | null> | null };

export type CompetitionCpcKeywordsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CompetitionCpcKeywordsQuery = { __typename?: 'Query', competitionCpcKeywords?: { __typename?: 'KeywordResultRawConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'KeywordResultRawEdge', cursor: string, node?: { __typename?: 'KeywordResultRawType', cpc?: number | null, keyword?: string | null, dbId?: any | null } | null } | null> } | null };

export type CortexConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type CortexConfigQuery = { __typename?: 'Query', cortexConfig?: { __typename?: 'CortexConfigType', postToZapier?: boolean | null, maxRecursionDepth?: number | null, mongoSiteCacheInDays?: number | null, minFacesForGroupPicture?: number | null, maxCrawledPagesPerDomain?: number | null, macAcceptableWebsitePopularity?: number | null, assessmentXlsUploadTime?: any | null, assessmentXlsTemplateId?: string | null, initialBbWeightForSourcescrubTags?: number | null, pipedriveDfmTargetDealsFilterId?: number | null, pipedriveOpenTargetDealsLimit?: number | null, pipedriveSoftwareCompanyOrgTypeIndicator?: number | null, pipedriveCortexLabelId?: number | null, pipedriveHotLeadLabelId?: number | null, sourcescrubTagIdForGenericGenericCompanyLabel?: number | null } | null };

export type CustomerIndustryGrowthRatesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CustomerIndustryGrowthRatesQuery = { __typename?: 'Query', customerIndustryGrowthRates?: { __typename?: 'CustomerGrowthRateRawConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CustomerGrowthRateRawEdge', cursor: string, node?: { __typename?: 'CustomerGrowthRateRawType', adjustedMedianGrowth?: number | null, sampleSize?: number | null, sicDesc?: string | null, dbId?: number | null } | null } | null> } | null };

export type CustomerSegmentsQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type CustomerSegmentsQuery = { __typename?: 'Query', customerSegments?: Array<{ __typename?: 'CustomerSegmentType', id?: number | null, segmentName?: string | null, segmentDescription?: string | null, keyword1?: string | null, bidGroupCompanyId?: number | null }> | null };

export type DealAssessmentFileUrlQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DealAssessmentFileUrlQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', id: string, dbId?: number | null, dealAssessmentFileUrl?: { __typename?: 'DealAssessmentFileType', downloadUrl?: string | null, generationTriggered?: boolean | null, noDataUploaded?: boolean | null, dateOfFile?: any | null } | null } | null } | null> } | null };

export type EmployeeStaticsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type EmployeeStaticsQuery = { __typename?: 'Query', employeeStatics?: { __typename?: 'EmployeeStaticsRawConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'EmployeeStaticsRawEdge', cursor: string, node?: { __typename?: 'EmployeeStaticsRawType', medianEmploymentMonths?: number | null, medianTotalEmploymentMonths?: number | null, medianPreviousEmployers?: number | null, numberOfEmployees?: number | null, teamQualityPercentage?: string | null, dbId?: number | null } | null } | null> } | null };

export type GetAllShareholdersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetAllShareholdersQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'CompanyType', shareholders?: { __typename?: 'GenericShareHolderTypeConnection', edges: Array<{ __typename?: 'GenericShareHolderTypeEdge', node?: { __typename?: 'GenericShareHolderType', dbId?: number | null, name?: string | null } | null } | null> } | null } | null } | null> } | null };

export type GetAllShareholdingsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetAllShareholdingsQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'CompanyType', genericShareholdings?: { __typename?: 'GenericShareholdingConnection', edges: Array<{ __typename?: 'GenericShareholdingEdge', node?: { __typename?: 'GenericShareholdingSQLType', id: string, dbId?: number | null, genericShareholderId?: number | null, genericCompanyId?: number | null, holding?: number | null, holdingCount?: number | null, genericShareholder?: { __typename?: 'GenericShareHolderType', dbId?: number | null, name?: string | null, isPe?: boolean | null, isTradeBuyer?: boolean | null, isNaturalPerson?: boolean | null, yearBornOrFounded?: number | null, typeOfInvestor?: string | null } | null } | null } | null> } | null } | null } | null> } | null };

export type GetAllSicCodesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllSicCodesQuery = { __typename?: 'Query', getAllSicCodes?: { __typename?: 'SICCodeConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SICCodeEdge', cursor: string, node?: { __typename?: 'SICCodeSQLType', sicCodeStr?: string | null, description?: string | null, id: string } | null } | null> } | null };

export type GetBuyAndBuildPlatformsQueryVariables = Exact<{
  sortForTargetId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetBuyAndBuildPlatformsQuery = { __typename?: 'Query', getBuyAndBuildPlatforms?: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', dbId?: number | null, shortName?: string | null, pipedriveId?: number | null } | null } | null> } | null };

export type GetCompanyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCompanyQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', id: string, legalName?: string | null, shortName?: string | null, street?: string | null, city?: string | null, zipCode?: string | null, country?: string | null, linkedinUrl?: string | null, pipedriveId?: number | null, hrbNumber?: string | null, court?: string | null, benchmarkingIndex?: number | null, sicCode?: string | null, foundingYear?: number | null, isSoftwareCompany?: boolean | null, sicCodeCertainty?: number | null, uniqueDomain?: string | null } | null } | null> } | null };

export type GetCompanyForDfmQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCompanyForDfmQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', id: string, dbId?: number | null, shortName?: string | null, pipedriveId?: number | null, uniqueDomain?: string | null, legalName?: string | null, address?: string | null, sicCode?: string | null, validated?: boolean | null, managingDirector?: string | null, linkedinDescription?: string | null, hrbNumber?: string | null, court?: string | null, city?: string | null, street?: string | null, zipCode?: string | null, country?: string | null, crawlUrl?: string | null, linkedinUrl?: string | null, emailPattern?: string | null, foundingYear?: number | null, latestUrl?: string | null, iabDomainCategory?: string | null, isRelevant?: boolean | null, datetimeCreated?: any | null, datetimeModified?: any | null, companyAnalyses?: { __typename?: 'CompanyAnalysisTypeConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'CompanyAnalysisTypeEdge', cursor: string, node?: { __typename?: 'CompanyAnalysisType', id: string, fte?: number | null, ebitda?: number | null, revenue?: number | null } | null } | null> } | null } | null } | null> } | null };

export type GetCompanySizeQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCompanySizeQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', maxFte?: number | null, linkedinFte?: number | null, id: string } | null } | null> } | null };

export type GetCrawlerFindingTokensQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCrawlerFindingTokensQuery = { __typename?: 'Query', getCrawlerFindingTokens?: { __typename?: 'CrawlerFindingTokenConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CrawlerFindingTokenEdge', cursor: string, node?: { __typename?: 'CrawlerFindingTokenType', id: string, url?: string | null, fte?: number | null } | null } | null> } | null };

export type GetCustomerReferencesQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type GetCustomerReferencesQuery = { __typename?: 'Query', getCustomerReferences?: { __typename?: 'CustomerReferenceRawTypeConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CustomerReferenceRawTypeEdge', cursor: string, node?: { __typename?: 'CustomerReferenceRawType', IAB1?: string | null, shortName?: string | null, crawlUrl?: string | null, findingUrl?: string | null, genericCustomer?: boolean | null, ISDd?: boolean | null, sicCode?: string | null, IAB2?: string | null, IAB3?: string | null, dbId?: number | null } | null } | null> } | null };

export type GetGenericCrawlResultIdQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type GetGenericCrawlResultIdQuery = { __typename?: 'Query', getCrawlResults?: { __typename?: 'RawGenericCrawlResultConnection', edges: Array<{ __typename?: 'RawGenericCrawlResultEdge', node?: { __typename?: 'RawGenericCrawlResultSQLType', dbId?: number | null } | null } | null> } | null };

export type GetKeywordsForCompanyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetKeywordsForCompanyQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', crawlResults?: { __typename?: 'GenericCrawlResultConnection', edges: Array<{ __typename?: 'GenericCrawlResultEdge', node?: { __typename?: 'GenericCrawlResultSQLType', dbId?: number | null, xoviKeywords?: { __typename?: 'XoviKeywordConnection', edges: Array<{ __typename?: 'XoviKeywordEdge', node?: { __typename?: 'XoviKeywordSQLType', dbId?: number | null, id: string, keyword?: string | null, searchEngine?: string | null, position?: number | null, cpc?: number | null, sevo?: number | null, competitorDensity?: number | null, markedAsIrrelevant?: boolean | null } | null } | null> } | null } | null } | null> } | null } | null } | null> } | null };

export type GetOpenaiVotesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetOpenaiVotesQuery = { __typename?: 'Query', getOpenaiVotes?: { __typename?: 'OpenAIVoteConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'OpenAIVoteEdge', cursor: string, node?: { __typename?: 'OpenAIVoteType', softwareVotes?: number | null, serviceVotes?: number | null, undecidable?: number | null } | null } | null> } | null };

export type GetProductCategoriesForCompanyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetProductCategoriesForCompanyQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', productCategorization?: { __typename?: 'ProductCategorizationConnection', edges: Array<{ __typename?: 'ProductCategorizationEdge', node?: { __typename?: 'ProductCategorizationType', id: string, dbId?: number | null, softwareCategoryCertainty?: number | null, softwareCategory?: { __typename?: 'SoftwareCategoryType', subCategory1?: string | null, subCategory2?: string | null, subCategory3?: string | null, g2Url?: string | null } | null } | null } | null> } | null } | null } | null> } | null };

export type GetProgrammingLanguagesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetProgrammingLanguagesQuery = { __typename?: 'Query', getProgrammingLanguages?: { __typename?: 'StackshareToolConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'StackshareToolEdge', cursor: string, node?: { __typename?: 'StackshareToolType', dbId?: number | null, name?: string | null } | null } | null> } | null };

export type GetResearchSoftwareCategoriesQueryVariables = Exact<{
  subCategory1?: InputMaybe<Scalars['String']['input']>;
  subCategory2?: InputMaybe<Scalars['String']['input']>;
  subCategory3?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetResearchSoftwareCategoriesQuery = { __typename?: 'Query', getSoftwareCategories?: { __typename?: 'SoftwareCategoryConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SoftwareCategoryEdge', cursor: string, node?: { __typename?: 'SoftwareCategoryType', id: string, subCategory1?: string | null, subCategory2?: string | null, subCategory3?: string | null, categoryDescription?: string | null, googleTerm?: { __typename?: 'GoogleTermType', googleTrends?: Array<{ __typename?: 'GoogleTrendType', interest?: number | null, date?: string | null } | null> | null } | null } | null } | null> } | null };

export type GetShortCompanyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetShortCompanyQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', id: string, dbId?: number | null, shortName?: string | null, uniqueDomain?: string | null, linkedinUrl?: string | null } | null } | null> } | null };

export type GetSoftwareCategoriesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSoftwareCategoriesQuery = { __typename?: 'Query', getSoftwareCategories?: { __typename?: 'SoftwareCategoryConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SoftwareCategoryEdge', cursor: string, node?: { __typename?: 'SoftwareCategoryType', id: string, dbId?: number | null, subCategory1?: string | null, subCategory2?: string | null, subCategory3?: string | null, subCategory4?: string | null } | null } | null> } | null };

export type GetSoftwareFirmsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetSoftwareFirmsQuery = { __typename?: 'Query', softwareFirms?: { __typename?: 'CompanyAssesmentConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyAssesmentEdge', cursor: string, node?: { __typename?: 'CompanyAssessmentType', id: string, dbId?: number | null, shortName?: string | null, uniqueDomain?: string | null, country?: string | null, validated?: boolean | null, linkedinUrl?: string | null, foundingYear?: number | null, sicCode?: string | null, datetimeCreated?: any | null, datetimeModified?: any | null } | null } | null> } | null };

export type GetStackshareToolCategoriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStackshareToolCategoriesQuery = { __typename?: 'Query', getStackshareToolCategories?: { __typename?: 'StackshareToolConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'StackshareToolEdge', cursor: string, node?: { __typename?: 'StackshareToolType', breadcrumb?: string | null, id: string, dbId?: number | null } | null } | null> } | null };

export type GetStackshareToolsQueryVariables = Exact<{
  category: Scalars['String']['input'];
}>;


export type GetStackshareToolsQuery = { __typename?: 'Query', getStackshareTools?: { __typename?: 'StackshareToolConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'StackshareToolEdge', cursor: string, node?: { __typename?: 'StackshareToolType', name?: string | null, id: string, dbId?: number | null } | null } | null> } | null };

export type GetTechnologyUseQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTechnologyUseQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node?: { __typename?: 'CompanyType', id: string, crawlUrl?: string | null, techUse?: { __typename?: 'GenericCompanyTechUseConnection', edges: Array<{ __typename?: 'GenericCompanyTechUseEdge', node?: { __typename?: 'TechUseType', dbId?: number | null, findingUrl?: string | null, stackshareTool?: { __typename?: 'ProductTechnologylType', id: string, breadcrumb?: string | null, name?: string | null, stacksUsed?: number | null, votes?: number | null, url?: string | null } | null } | null } | null> } | null } | null } | null> } | null };

export type GetUploadedMetaDataQueryVariables = Exact<{
  pipedriveId: Scalars['Int']['input'];
}>;


export type GetUploadedMetaDataQuery = { __typename?: 'Query', uploadedMetaDataByPipedriveId?: { __typename?: 'CompanyWithLatestUpload', uploadTime?: any | null, uploaderName?: string | null, company?: { __typename?: 'CompanyType', dbId?: number | null, shortName?: string | null, uniqueDomain?: string | null } | null } | null };

export type OpenregCompaniesQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type OpenregCompaniesQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'CompanyType', legalName?: string | null, street?: string | null, city?: string | null, openregCompanies?: { __typename?: 'OpenRegCompanyConnection', edges: Array<{ __typename?: 'OpenRegCompanyEdge', node?: { __typename?: 'OpenRegCompanySQLType', companyName?: string | null, addressStreet?: string | null, addressPlace?: string | null, addressZipcode?: string | null, referenceCourtCode?: string | null, referenceCourtName?: string | null, foundedDate?: string | null, dbId?: number | null } | null } | null> } | null } | null } | null> } | null };

export type PrecalculatedKpisQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type PrecalculatedKpisQuery = { __typename?: 'Query', companyAssesment?: { __typename?: 'CompanyAssesmentConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyAssesmentEdge', node?: { __typename?: 'CompanyAssessmentType', precalculatedKpis?: { __typename?: 'KPIPrecalculationConnection', edges: Array<{ __typename?: 'KPIPrecalculationEdge', node?: { __typename?: 'KPIPreCalculationType', id: string, softwareShare?: number | null, salesGrowth?: number | null, absEbit?: number | null, recurringRevenueShare?: number | null, cogsRatio?: number | null, top3CustomerShare?: number | null, netNewCustomerSalesGrowth?: number | null, customerLifetimeValueDistribution?: number | null, cLevelQuality?: number | null, softwareCategoryGrowth?: number | null, onlineVisibility?: number | null, pricePerGoogleKeywordClick?: number | null, consultingManRate?: number | null, mostExpensiveKeyword?: string | null, customerIndustryGrowth?: number | null, employeeConcentrationRatio?: number | null, teamQuality?: number | null, ageOfProgrammingLanguage?: number | null, popularityOfProgrammingLanguage?: number | null, useOfBackendFramework?: boolean | null, useOfFrontendFramework?: boolean | null, buyAndBuildScore?: number | null, callabilityScore?: number | null } | null } | null> } | null } | null } | null> } | null };

export type ProductCategoryListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProductCategoryListQuery = { __typename?: 'Query', productCategoryList?: { __typename?: 'SoftwareCategoryConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SoftwareCategoryEdge', cursor: string, node?: { __typename?: 'SoftwareCategoryType', id: string, dbId?: number | null, subCategory1?: string | null } | null } | null> } | null };

export type ProductSubCategory3ListQueryVariables = Exact<{
  subCategory2?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProductSubCategory3ListQuery = { __typename?: 'Query', productCategoryList?: { __typename?: 'SoftwareCategoryConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SoftwareCategoryEdge', cursor: string, node?: { __typename?: 'SoftwareCategoryType', id: string, dbId?: number | null, subCategory3?: string | null } | null } | null> } | null };

export type ProductSubCategoryListQueryVariables = Exact<{
  subCategory1?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProductSubCategoryListQuery = { __typename?: 'Query', productCategoryList?: { __typename?: 'SoftwareCategoryConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SoftwareCategoryEdge', cursor: string, node?: { __typename?: 'SoftwareCategoryType', id: string, dbId?: number | null, subCategory2?: string | null } | null } | null> } | null };

export type SearchCompanyQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchCompanyQuery = { __typename?: 'Query', cortexFirms?: { __typename?: 'CompanyAssesmentConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyAssesmentEdge', node?: { __typename?: 'CompanyAssessmentType', dbId?: number | null, shortName?: string | null, uniqueDomain?: string | null, country?: string | null, validated?: boolean | null, isSoftwareCompany?: boolean | null, sicCode?: string | null, doNotCrawl?: boolean | null } | null } | null> } | null };

export type SearchCompanyForEvaluationQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchCompanyForEvaluationQuery = { __typename?: 'Query', cortexFirms?: { __typename?: 'CompanyAssesmentConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyAssesmentEdge', node?: { __typename?: 'CompanyAssessmentType', dbId?: number | null, shortName?: string | null, uniqueDomain?: string | null, country?: string | null, validated?: boolean | null } | null } | null> } | null };

export type SoftwareCategoryGrowthRatesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SoftwareCategoryGrowthRatesQuery = { __typename?: 'Query', softwareCategoryGrowthRates?: { __typename?: 'SoftwareCategoryGrowthRateRawConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'SoftwareCategoryGrowthRateRawEdge', cursor: string, node?: { __typename?: 'SoftwareCategoryGrowthRateRawType', dbId?: number | null, term?: string | null, avgAlltimeInterest?: number | null, avgInterest24To12MonthsAgo?: number | null, avgInterestLast12Months?: number | null, interestGrowthRate?: number | null } | null } | null> } | null };

export type SpecialCpcKeywordsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SpecialCpcKeywordsQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'CompanyType', crawlResults?: { __typename?: 'GenericCrawlResultConnection', edges: Array<{ __typename?: 'GenericCrawlResultEdge', node?: { __typename?: 'GenericCrawlResultSQLType', dbId?: number | null, xoviKeywords?: { __typename?: 'XoviKeywordConnection', edges: Array<{ __typename?: 'XoviKeywordEdge', node?: { __typename?: 'XoviKeywordSQLType', id: string, keyword?: string | null } | null } | null> } | null } | null } | null> } | null } | null } | null> } | null };

export type StatusMessageQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
}>;


export type StatusMessageQuery = { __typename?: 'Query', statusMessage?: string | null };

export type TopCpcKeywordQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TopCpcKeywordQuery = { __typename?: 'Query', getCompany?: { __typename?: 'CompanyConnection', pageInfo?: { __typename?: 'DbIdPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startId?: number | null, endId?: number | null } | null, edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'CompanyType', crawlResults?: { __typename?: 'GenericCrawlResultConnection', edges: Array<{ __typename?: 'GenericCrawlResultEdge', node?: { __typename?: 'GenericCrawlResultSQLType', dbId?: number | null, xoviKeywords?: { __typename?: 'XoviKeywordConnection', edges: Array<{ __typename?: 'XoviKeywordEdge', node?: { __typename?: 'XoviKeywordSQLType', id: string, keyword?: string | null } | null } | null> } | null } | null } | null> } | null } | null } | null> } | null };

export const DbIdPageInfoFragmentFragmentDoc = gql`
    fragment DbIdPageInfoFragment on DbIdPageInfo {
  hasNextPage
  hasPreviousPage
  startId
  endId
}
    `;
export const AddBuyAndBuildTargetsFromSourcescrubDocument = gql`
    mutation addBuyAndBuildTargetsFromSourcescrub($authId: String, $softwareCompanyId: Int!) {
  addBuyAndBuildTargetsFromSourcescrub(
    authId: $authId
    softwareCompanyId: $softwareCompanyId
  ) {
    ok
  }
}
    `;
export type AddBuyAndBuildTargetsFromSourcescrubMutationFn = Apollo.MutationFunction<AddBuyAndBuildTargetsFromSourcescrubMutation, AddBuyAndBuildTargetsFromSourcescrubMutationVariables>;

/**
 * __useAddBuyAndBuildTargetsFromSourcescrubMutation__
 *
 * To run a mutation, you first call `useAddBuyAndBuildTargetsFromSourcescrubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuyAndBuildTargetsFromSourcescrubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuyAndBuildTargetsFromSourcescrubMutation, { data, loading, error }] = useAddBuyAndBuildTargetsFromSourcescrubMutation({
 *   variables: {
 *      authId: // value for 'authId'
 *      softwareCompanyId: // value for 'softwareCompanyId'
 *   },
 * });
 */
export function useAddBuyAndBuildTargetsFromSourcescrubMutation(baseOptions?: Apollo.MutationHookOptions<AddBuyAndBuildTargetsFromSourcescrubMutation, AddBuyAndBuildTargetsFromSourcescrubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBuyAndBuildTargetsFromSourcescrubMutation, AddBuyAndBuildTargetsFromSourcescrubMutationVariables>(AddBuyAndBuildTargetsFromSourcescrubDocument, options);
      }
export type AddBuyAndBuildTargetsFromSourcescrubMutationHookResult = ReturnType<typeof useAddBuyAndBuildTargetsFromSourcescrubMutation>;
export type AddBuyAndBuildTargetsFromSourcescrubMutationResult = Apollo.MutationResult<AddBuyAndBuildTargetsFromSourcescrubMutation>;
export type AddBuyAndBuildTargetsFromSourcescrubMutationOptions = Apollo.BaseMutationOptions<AddBuyAndBuildTargetsFromSourcescrubMutation, AddBuyAndBuildTargetsFromSourcescrubMutationVariables>;
export const AddSegmentCustomerDocument = gql`
    mutation addSegmentCustomer($input: CustomerSegmentInput!) {
  addCustomerSegment(input: $input) {
    success
  }
}
    `;
export type AddSegmentCustomerMutationFn = Apollo.MutationFunction<AddSegmentCustomerMutation, AddSegmentCustomerMutationVariables>;

/**
 * __useAddSegmentCustomerMutation__
 *
 * To run a mutation, you first call `useAddSegmentCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSegmentCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSegmentCustomerMutation, { data, loading, error }] = useAddSegmentCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSegmentCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddSegmentCustomerMutation, AddSegmentCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSegmentCustomerMutation, AddSegmentCustomerMutationVariables>(AddSegmentCustomerDocument, options);
      }
export type AddSegmentCustomerMutationHookResult = ReturnType<typeof useAddSegmentCustomerMutation>;
export type AddSegmentCustomerMutationResult = Apollo.MutationResult<AddSegmentCustomerMutation>;
export type AddSegmentCustomerMutationOptions = Apollo.BaseMutationOptions<AddSegmentCustomerMutation, AddSegmentCustomerMutationVariables>;
export const AddIndustryDocument = gql`
    mutation addIndustry($companyId: Int!, $referencedAt: String!, $sicCode: String!) {
  addIndustry(
    companyId: $companyId
    referencedAt: $referencedAt
    sicCode: $sicCode
  ) {
    companyId
    sicCode
    referencedAt
  }
}
    `;
export type AddIndustryMutationFn = Apollo.MutationFunction<AddIndustryMutation, AddIndustryMutationVariables>;

/**
 * __useAddIndustryMutation__
 *
 * To run a mutation, you first call `useAddIndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIndustryMutation, { data, loading, error }] = useAddIndustryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      referencedAt: // value for 'referencedAt'
 *      sicCode: // value for 'sicCode'
 *   },
 * });
 */
export function useAddIndustryMutation(baseOptions?: Apollo.MutationHookOptions<AddIndustryMutation, AddIndustryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIndustryMutation, AddIndustryMutationVariables>(AddIndustryDocument, options);
      }
export type AddIndustryMutationHookResult = ReturnType<typeof useAddIndustryMutation>;
export type AddIndustryMutationResult = Apollo.MutationResult<AddIndustryMutation>;
export type AddIndustryMutationOptions = Apollo.BaseMutationOptions<AddIndustryMutation, AddIndustryMutationVariables>;
export const AddSoftwareCategoryToCompanyDocument = gql`
    mutation addSoftwareCategoryToCompany($softwareCategoryId: Int!, $companyId: Int!) {
  addSoftwareCategoryToCompany(
    softwareCategoryId: $softwareCategoryId
    companyId: $companyId
  ) {
    success
  }
}
    `;
export type AddSoftwareCategoryToCompanyMutationFn = Apollo.MutationFunction<AddSoftwareCategoryToCompanyMutation, AddSoftwareCategoryToCompanyMutationVariables>;

/**
 * __useAddSoftwareCategoryToCompanyMutation__
 *
 * To run a mutation, you first call `useAddSoftwareCategoryToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSoftwareCategoryToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSoftwareCategoryToCompanyMutation, { data, loading, error }] = useAddSoftwareCategoryToCompanyMutation({
 *   variables: {
 *      softwareCategoryId: // value for 'softwareCategoryId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddSoftwareCategoryToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddSoftwareCategoryToCompanyMutation, AddSoftwareCategoryToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSoftwareCategoryToCompanyMutation, AddSoftwareCategoryToCompanyMutationVariables>(AddSoftwareCategoryToCompanyDocument, options);
      }
export type AddSoftwareCategoryToCompanyMutationHookResult = ReturnType<typeof useAddSoftwareCategoryToCompanyMutation>;
export type AddSoftwareCategoryToCompanyMutationResult = Apollo.MutationResult<AddSoftwareCategoryToCompanyMutation>;
export type AddSoftwareCategoryToCompanyMutationOptions = Apollo.BaseMutationOptions<AddSoftwareCategoryToCompanyMutation, AddSoftwareCategoryToCompanyMutationVariables>;
export const CortexFeedbackPipedriveBridgeDocument = gql`
    mutation cortexFeedbackPipedriveBridge($companyId: Int!, $addOnForBidId: Int!) {
  cortexFeedbackPipedriveBridge(
    companyId: $companyId
    addOnForBidId: $addOnForBidId
  ) {
    success
  }
}
    `;
export type CortexFeedbackPipedriveBridgeMutationFn = Apollo.MutationFunction<CortexFeedbackPipedriveBridgeMutation, CortexFeedbackPipedriveBridgeMutationVariables>;

/**
 * __useCortexFeedbackPipedriveBridgeMutation__
 *
 * To run a mutation, you first call `useCortexFeedbackPipedriveBridgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCortexFeedbackPipedriveBridgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cortexFeedbackPipedriveBridgeMutation, { data, loading, error }] = useCortexFeedbackPipedriveBridgeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      addOnForBidId: // value for 'addOnForBidId'
 *   },
 * });
 */
export function useCortexFeedbackPipedriveBridgeMutation(baseOptions?: Apollo.MutationHookOptions<CortexFeedbackPipedriveBridgeMutation, CortexFeedbackPipedriveBridgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CortexFeedbackPipedriveBridgeMutation, CortexFeedbackPipedriveBridgeMutationVariables>(CortexFeedbackPipedriveBridgeDocument, options);
      }
export type CortexFeedbackPipedriveBridgeMutationHookResult = ReturnType<typeof useCortexFeedbackPipedriveBridgeMutation>;
export type CortexFeedbackPipedriveBridgeMutationResult = Apollo.MutationResult<CortexFeedbackPipedriveBridgeMutation>;
export type CortexFeedbackPipedriveBridgeMutationOptions = Apollo.BaseMutationOptions<CortexFeedbackPipedriveBridgeMutation, CortexFeedbackPipedriveBridgeMutationVariables>;
export const CreateBenchmarkPlDocument = gql`
    mutation createBenchmarkPL($companyId: Int!, $category1: String!, $category2: String!, $category3: String!, $valuationLevel: Int!, $period: Int!, $periodType: String!, $currency: String!, $value: Float!) {
  createBenchmarkPl(
    companyId: $companyId
    category1: $category1
    category2: $category2
    category3: $category3
    valuationLevel: $valuationLevel
    period: $period
    periodType: $periodType
    currency: $currency
    value: $value
  ) {
    benchmarkingData {
      id
      companyId
      value
    }
  }
}
    `;
export type CreateBenchmarkPlMutationFn = Apollo.MutationFunction<CreateBenchmarkPlMutation, CreateBenchmarkPlMutationVariables>;

/**
 * __useCreateBenchmarkPlMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkPlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkPlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkPlMutation, { data, loading, error }] = useCreateBenchmarkPlMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      category1: // value for 'category1'
 *      category2: // value for 'category2'
 *      category3: // value for 'category3'
 *      valuationLevel: // value for 'valuationLevel'
 *      period: // value for 'period'
 *      periodType: // value for 'periodType'
 *      currency: // value for 'currency'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateBenchmarkPlMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenchmarkPlMutation, CreateBenchmarkPlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenchmarkPlMutation, CreateBenchmarkPlMutationVariables>(CreateBenchmarkPlDocument, options);
      }
export type CreateBenchmarkPlMutationHookResult = ReturnType<typeof useCreateBenchmarkPlMutation>;
export type CreateBenchmarkPlMutationResult = Apollo.MutationResult<CreateBenchmarkPlMutation>;
export type CreateBenchmarkPlMutationOptions = Apollo.BaseMutationOptions<CreateBenchmarkPlMutation, CreateBenchmarkPlMutationVariables>;
export const CreateKeywordsDocument = gql`
    mutation createKeywords($genericCrawlResultId: Int!, $keywords: [String]!) {
  createKeywords(genericCrawlResultId: $genericCrawlResultId, keywords: $keywords) {
    keywords
    genericCrawlResultId
  }
}
    `;
export type CreateKeywordsMutationFn = Apollo.MutationFunction<CreateKeywordsMutation, CreateKeywordsMutationVariables>;

/**
 * __useCreateKeywordsMutation__
 *
 * To run a mutation, you first call `useCreateKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordsMutation, { data, loading, error }] = useCreateKeywordsMutation({
 *   variables: {
 *      genericCrawlResultId: // value for 'genericCrawlResultId'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useCreateKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeywordsMutation, CreateKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKeywordsMutation, CreateKeywordsMutationVariables>(CreateKeywordsDocument, options);
      }
export type CreateKeywordsMutationHookResult = ReturnType<typeof useCreateKeywordsMutation>;
export type CreateKeywordsMutationResult = Apollo.MutationResult<CreateKeywordsMutation>;
export type CreateKeywordsMutationOptions = Apollo.BaseMutationOptions<CreateKeywordsMutation, CreateKeywordsMutationVariables>;
export const CreatePdOrganizationDocument = gql`
    mutation CreatePDOrganization($companyId: Int!, $authId: String, $newPlatform: Boolean, $addOnExisting: Boolean, $addOnFuture: Boolean, $saas: Boolean, $venture: Boolean, $none: Boolean, $partOfGroup: Boolean, $otherOwnership: Boolean, $sellsHardware: Boolean, $tooMuchConsulting: Boolean, $reseller: Boolean, $wrongBusinessModel: Boolean, $b2c: Boolean, $wrongGeography: Boolean, $tooBig: Boolean, $tooSmall: Boolean, $createOrganization: Boolean, $createDeal: Boolean, $selectValue: String, $note: String) {
  cortexFeedbackPipedriveBridge(
    companyId: $companyId
    authId: $authId
    newPlatform: $newPlatform
    addOnExisting: $addOnExisting
    addOnFuture: $addOnFuture
    saas: $saas
    venture: $venture
    none: $none
    partOfGroup: $partOfGroup
    otherOwnership: $otherOwnership
    sellsHardware: $sellsHardware
    tooMuchConsulting: $tooMuchConsulting
    reseller: $reseller
    wrongBusinessModel: $wrongBusinessModel
    b2c: $b2c
    wrongGeography: $wrongGeography
    tooBig: $tooBig
    tooSmall: $tooSmall
    createOrganization: $createOrganization
    createDeal: $createDeal
    selectValue: $selectValue
    note: $note
  ) {
    success
    message
  }
}
    `;
export type CreatePdOrganizationMutationFn = Apollo.MutationFunction<CreatePdOrganizationMutation, CreatePdOrganizationMutationVariables>;

/**
 * __useCreatePdOrganizationMutation__
 *
 * To run a mutation, you first call `useCreatePdOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePdOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPdOrganizationMutation, { data, loading, error }] = useCreatePdOrganizationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      authId: // value for 'authId'
 *      newPlatform: // value for 'newPlatform'
 *      addOnExisting: // value for 'addOnExisting'
 *      addOnFuture: // value for 'addOnFuture'
 *      saas: // value for 'saas'
 *      venture: // value for 'venture'
 *      none: // value for 'none'
 *      partOfGroup: // value for 'partOfGroup'
 *      otherOwnership: // value for 'otherOwnership'
 *      sellsHardware: // value for 'sellsHardware'
 *      tooMuchConsulting: // value for 'tooMuchConsulting'
 *      reseller: // value for 'reseller'
 *      wrongBusinessModel: // value for 'wrongBusinessModel'
 *      b2c: // value for 'b2c'
 *      wrongGeography: // value for 'wrongGeography'
 *      tooBig: // value for 'tooBig'
 *      tooSmall: // value for 'tooSmall'
 *      createOrganization: // value for 'createOrganization'
 *      createDeal: // value for 'createDeal'
 *      selectValue: // value for 'selectValue'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreatePdOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePdOrganizationMutation, CreatePdOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePdOrganizationMutation, CreatePdOrganizationMutationVariables>(CreatePdOrganizationDocument, options);
      }
export type CreatePdOrganizationMutationHookResult = ReturnType<typeof useCreatePdOrganizationMutation>;
export type CreatePdOrganizationMutationResult = Apollo.MutationResult<CreatePdOrganizationMutation>;
export type CreatePdOrganizationMutationOptions = Apollo.BaseMutationOptions<CreatePdOrganizationMutation, CreatePdOrganizationMutationVariables>;
export const CreateUpdateCompanyTechUseDocument = gql`
    mutation createUpdateCompanyTechUse($stackshareToolId: Int!, $companyId: Int!) {
  createUpdateCompanyTechUse(
    stackshareToolId: $stackshareToolId
    companyId: $companyId
  ) {
    companyId
  }
}
    `;
export type CreateUpdateCompanyTechUseMutationFn = Apollo.MutationFunction<CreateUpdateCompanyTechUseMutation, CreateUpdateCompanyTechUseMutationVariables>;

/**
 * __useCreateUpdateCompanyTechUseMutation__
 *
 * To run a mutation, you first call `useCreateUpdateCompanyTechUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateCompanyTechUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateCompanyTechUseMutation, { data, loading, error }] = useCreateUpdateCompanyTechUseMutation({
 *   variables: {
 *      stackshareToolId: // value for 'stackshareToolId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateUpdateCompanyTechUseMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateCompanyTechUseMutation, CreateUpdateCompanyTechUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateCompanyTechUseMutation, CreateUpdateCompanyTechUseMutationVariables>(CreateUpdateCompanyTechUseDocument, options);
      }
export type CreateUpdateCompanyTechUseMutationHookResult = ReturnType<typeof useCreateUpdateCompanyTechUseMutation>;
export type CreateUpdateCompanyTechUseMutationResult = Apollo.MutationResult<CreateUpdateCompanyTechUseMutation>;
export type CreateUpdateCompanyTechUseMutationOptions = Apollo.BaseMutationOptions<CreateUpdateCompanyTechUseMutation, CreateUpdateCompanyTechUseMutationVariables>;
export const CreateUpdateGenericShareholderDocument = gql`
    mutation createUpdateGenericShareholder($name: String, $typeOfInvestor: String, $yearBornOrFounded: Int, $genericCompanyId: Int, $genericShareholderId: Int) {
  createUpdateGenericShareholder(
    name: $name
    typeOfInvestor: $typeOfInvestor
    yearBornOrFounded: $yearBornOrFounded
    genericCompanyId: $genericCompanyId
    genericShareholderId: $genericShareholderId
  ) {
    dbId
    name
  }
}
    `;
export type CreateUpdateGenericShareholderMutationFn = Apollo.MutationFunction<CreateUpdateGenericShareholderMutation, CreateUpdateGenericShareholderMutationVariables>;

/**
 * __useCreateUpdateGenericShareholderMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGenericShareholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGenericShareholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGenericShareholderMutation, { data, loading, error }] = useCreateUpdateGenericShareholderMutation({
 *   variables: {
 *      name: // value for 'name'
 *      typeOfInvestor: // value for 'typeOfInvestor'
 *      yearBornOrFounded: // value for 'yearBornOrFounded'
 *      genericCompanyId: // value for 'genericCompanyId'
 *      genericShareholderId: // value for 'genericShareholderId'
 *   },
 * });
 */
export function useCreateUpdateGenericShareholderMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateGenericShareholderMutation, CreateUpdateGenericShareholderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateGenericShareholderMutation, CreateUpdateGenericShareholderMutationVariables>(CreateUpdateGenericShareholderDocument, options);
      }
export type CreateUpdateGenericShareholderMutationHookResult = ReturnType<typeof useCreateUpdateGenericShareholderMutation>;
export type CreateUpdateGenericShareholderMutationResult = Apollo.MutationResult<CreateUpdateGenericShareholderMutation>;
export type CreateUpdateGenericShareholderMutationOptions = Apollo.BaseMutationOptions<CreateUpdateGenericShareholderMutation, CreateUpdateGenericShareholderMutationVariables>;
export const CreateUpdateGenericShareholdingDocument = gql`
    mutation createUpdateGenericShareholding($genericCompanyId: Int, $genericShareholderId: Int, $genericShareholdingId: Int, $holding: Float, $holdingCount: Int) {
  createUpdateGenericShareholding(
    genericCompanyId: $genericCompanyId
    genericShareholderId: $genericShareholderId
    genericShareholdingId: $genericShareholdingId
    holding: $holding
    holdingCount: $holdingCount
  ) {
    genericCompanyId
    genericShareholderId
    holdingCount
    holding
  }
}
    `;
export type CreateUpdateGenericShareholdingMutationFn = Apollo.MutationFunction<CreateUpdateGenericShareholdingMutation, CreateUpdateGenericShareholdingMutationVariables>;

/**
 * __useCreateUpdateGenericShareholdingMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGenericShareholdingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGenericShareholdingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGenericShareholdingMutation, { data, loading, error }] = useCreateUpdateGenericShareholdingMutation({
 *   variables: {
 *      genericCompanyId: // value for 'genericCompanyId'
 *      genericShareholderId: // value for 'genericShareholderId'
 *      genericShareholdingId: // value for 'genericShareholdingId'
 *      holding: // value for 'holding'
 *      holdingCount: // value for 'holdingCount'
 *   },
 * });
 */
export function useCreateUpdateGenericShareholdingMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateGenericShareholdingMutation, CreateUpdateGenericShareholdingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateGenericShareholdingMutation, CreateUpdateGenericShareholdingMutationVariables>(CreateUpdateGenericShareholdingDocument, options);
      }
export type CreateUpdateGenericShareholdingMutationHookResult = ReturnType<typeof useCreateUpdateGenericShareholdingMutation>;
export type CreateUpdateGenericShareholdingMutationResult = Apollo.MutationResult<CreateUpdateGenericShareholdingMutation>;
export type CreateUpdateGenericShareholdingMutationOptions = Apollo.BaseMutationOptions<CreateUpdateGenericShareholdingMutation, CreateUpdateGenericShareholdingMutationVariables>;
export const CreateUpdateKeywordDocument = gql`
    mutation createUpdateKeyword($genericCrawlResultId: Int!, $keyword: String!) {
  createUpdateKeyword(
    genericCrawlResultId: $genericCrawlResultId
    keyword: $keyword
  ) {
    keyword
  }
}
    `;
export type CreateUpdateKeywordMutationFn = Apollo.MutationFunction<CreateUpdateKeywordMutation, CreateUpdateKeywordMutationVariables>;

/**
 * __useCreateUpdateKeywordMutation__
 *
 * To run a mutation, you first call `useCreateUpdateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateKeywordMutation, { data, loading, error }] = useCreateUpdateKeywordMutation({
 *   variables: {
 *      genericCrawlResultId: // value for 'genericCrawlResultId'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useCreateUpdateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateKeywordMutation, CreateUpdateKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateKeywordMutation, CreateUpdateKeywordMutationVariables>(CreateUpdateKeywordDocument, options);
      }
export type CreateUpdateKeywordMutationHookResult = ReturnType<typeof useCreateUpdateKeywordMutation>;
export type CreateUpdateKeywordMutationResult = Apollo.MutationResult<CreateUpdateKeywordMutation>;
export type CreateUpdateKeywordMutationOptions = Apollo.BaseMutationOptions<CreateUpdateKeywordMutation, CreateUpdateKeywordMutationVariables>;
export const DeleteAllBmForCompanyDocument = gql`
    mutation deleteAllBmForCompany($companyId: Int!) {
  deleteAllBmForCompany(companyId: $companyId) {
    success
  }
}
    `;
export type DeleteAllBmForCompanyMutationFn = Apollo.MutationFunction<DeleteAllBmForCompanyMutation, DeleteAllBmForCompanyMutationVariables>;

/**
 * __useDeleteAllBmForCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteAllBmForCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllBmForCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllBmForCompanyMutation, { data, loading, error }] = useDeleteAllBmForCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteAllBmForCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllBmForCompanyMutation, DeleteAllBmForCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllBmForCompanyMutation, DeleteAllBmForCompanyMutationVariables>(DeleteAllBmForCompanyDocument, options);
      }
export type DeleteAllBmForCompanyMutationHookResult = ReturnType<typeof useDeleteAllBmForCompanyMutation>;
export type DeleteAllBmForCompanyMutationResult = Apollo.MutationResult<DeleteAllBmForCompanyMutation>;
export type DeleteAllBmForCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteAllBmForCompanyMutation, DeleteAllBmForCompanyMutationVariables>;
export const DeleteCompanyTechUseDocument = gql`
    mutation deleteCompanyTechUse($techUseId: Int!) {
  deleteCompanyTechUse(techUseId: $techUseId) {
    message
    success
  }
}
    `;
export type DeleteCompanyTechUseMutationFn = Apollo.MutationFunction<DeleteCompanyTechUseMutation, DeleteCompanyTechUseMutationVariables>;

/**
 * __useDeleteCompanyTechUseMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyTechUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyTechUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyTechUseMutation, { data, loading, error }] = useDeleteCompanyTechUseMutation({
 *   variables: {
 *      techUseId: // value for 'techUseId'
 *   },
 * });
 */
export function useDeleteCompanyTechUseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyTechUseMutation, DeleteCompanyTechUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyTechUseMutation, DeleteCompanyTechUseMutationVariables>(DeleteCompanyTechUseDocument, options);
      }
export type DeleteCompanyTechUseMutationHookResult = ReturnType<typeof useDeleteCompanyTechUseMutation>;
export type DeleteCompanyTechUseMutationResult = Apollo.MutationResult<DeleteCompanyTechUseMutation>;
export type DeleteCompanyTechUseMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyTechUseMutation, DeleteCompanyTechUseMutationVariables>;
export const DeleteCustomerReferenceDocument = gql`
    mutation deleteCustomerReference($customerReferenceId: Int!) {
  deleteCustomerReference(customerReferenceId: $customerReferenceId) {
    success
    message
  }
}
    `;
export type DeleteCustomerReferenceMutationFn = Apollo.MutationFunction<DeleteCustomerReferenceMutation, DeleteCustomerReferenceMutationVariables>;

/**
 * __useDeleteCustomerReferenceMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerReferenceMutation, { data, loading, error }] = useDeleteCustomerReferenceMutation({
 *   variables: {
 *      customerReferenceId: // value for 'customerReferenceId'
 *   },
 * });
 */
export function useDeleteCustomerReferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerReferenceMutation, DeleteCustomerReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerReferenceMutation, DeleteCustomerReferenceMutationVariables>(DeleteCustomerReferenceDocument, options);
      }
export type DeleteCustomerReferenceMutationHookResult = ReturnType<typeof useDeleteCustomerReferenceMutation>;
export type DeleteCustomerReferenceMutationResult = Apollo.MutationResult<DeleteCustomerReferenceMutation>;
export type DeleteCustomerReferenceMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerReferenceMutation, DeleteCustomerReferenceMutationVariables>;
export const DeleteSegmentDocument = gql`
    mutation deleteSegment($segmentId: Int!) {
  deleteCustomerSegment(segmentId: $segmentId) {
    success
  }
}
    `;
export type DeleteSegmentMutationFn = Apollo.MutationFunction<DeleteSegmentMutation, DeleteSegmentMutationVariables>;

/**
 * __useDeleteSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentMutation, { data, loading, error }] = useDeleteSegmentMutation({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *   },
 * });
 */
export function useDeleteSegmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(DeleteSegmentDocument, options);
      }
export type DeleteSegmentMutationHookResult = ReturnType<typeof useDeleteSegmentMutation>;
export type DeleteSegmentMutationResult = Apollo.MutationResult<DeleteSegmentMutation>;
export type DeleteSegmentMutationOptions = Apollo.BaseMutationOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>;
export const DeleteGenericShareholdingDocument = gql`
    mutation deleteGenericShareholding($genericShareholdingId: Int!) {
  deleteGenericShareholding(genericShareholdingId: $genericShareholdingId) {
    success
  }
}
    `;
export type DeleteGenericShareholdingMutationFn = Apollo.MutationFunction<DeleteGenericShareholdingMutation, DeleteGenericShareholdingMutationVariables>;

/**
 * __useDeleteGenericShareholdingMutation__
 *
 * To run a mutation, you first call `useDeleteGenericShareholdingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGenericShareholdingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGenericShareholdingMutation, { data, loading, error }] = useDeleteGenericShareholdingMutation({
 *   variables: {
 *      genericShareholdingId: // value for 'genericShareholdingId'
 *   },
 * });
 */
export function useDeleteGenericShareholdingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGenericShareholdingMutation, DeleteGenericShareholdingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGenericShareholdingMutation, DeleteGenericShareholdingMutationVariables>(DeleteGenericShareholdingDocument, options);
      }
export type DeleteGenericShareholdingMutationHookResult = ReturnType<typeof useDeleteGenericShareholdingMutation>;
export type DeleteGenericShareholdingMutationResult = Apollo.MutationResult<DeleteGenericShareholdingMutation>;
export type DeleteGenericShareholdingMutationOptions = Apollo.BaseMutationOptions<DeleteGenericShareholdingMutation, DeleteGenericShareholdingMutationVariables>;
export const DeleteKeywordDocument = gql`
    mutation deleteKeyword($keywordId: Int!) {
  deleteKeyword(keywordId: $keywordId) {
    success
    message
  }
}
    `;
export type DeleteKeywordMutationFn = Apollo.MutationFunction<DeleteKeywordMutation, DeleteKeywordMutationVariables>;

/**
 * __useDeleteKeywordMutation__
 *
 * To run a mutation, you first call `useDeleteKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeywordMutation, { data, loading, error }] = useDeleteKeywordMutation({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useDeleteKeywordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeywordMutation, DeleteKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeywordMutation, DeleteKeywordMutationVariables>(DeleteKeywordDocument, options);
      }
export type DeleteKeywordMutationHookResult = ReturnType<typeof useDeleteKeywordMutation>;
export type DeleteKeywordMutationResult = Apollo.MutationResult<DeleteKeywordMutation>;
export type DeleteKeywordMutationOptions = Apollo.BaseMutationOptions<DeleteKeywordMutation, DeleteKeywordMutationVariables>;
export const DeleteKeywordsDocument = gql`
    mutation deleteKeywords($keywordIds: [Int]!) {
  deleteKeywords(keywordIds: $keywordIds) {
    success
    message
  }
}
    `;
export type DeleteKeywordsMutationFn = Apollo.MutationFunction<DeleteKeywordsMutation, DeleteKeywordsMutationVariables>;

/**
 * __useDeleteKeywordsMutation__
 *
 * To run a mutation, you first call `useDeleteKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeywordsMutation, { data, loading, error }] = useDeleteKeywordsMutation({
 *   variables: {
 *      keywordIds: // value for 'keywordIds'
 *   },
 * });
 */
export function useDeleteKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeywordsMutation, DeleteKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeywordsMutation, DeleteKeywordsMutationVariables>(DeleteKeywordsDocument, options);
      }
export type DeleteKeywordsMutationHookResult = ReturnType<typeof useDeleteKeywordsMutation>;
export type DeleteKeywordsMutationResult = Apollo.MutationResult<DeleteKeywordsMutation>;
export type DeleteKeywordsMutationOptions = Apollo.BaseMutationOptions<DeleteKeywordsMutation, DeleteKeywordsMutationVariables>;
export const DownvoteFindingsTokenDocument = gql`
    mutation downvoteFindingsToken($tokenId: Int!) {
  downvoteFindingsToken(tokenId: $tokenId) {
    ok
    token {
      id
    }
  }
}
    `;
export type DownvoteFindingsTokenMutationFn = Apollo.MutationFunction<DownvoteFindingsTokenMutation, DownvoteFindingsTokenMutationVariables>;

/**
 * __useDownvoteFindingsTokenMutation__
 *
 * To run a mutation, you first call `useDownvoteFindingsTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownvoteFindingsTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downvoteFindingsTokenMutation, { data, loading, error }] = useDownvoteFindingsTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useDownvoteFindingsTokenMutation(baseOptions?: Apollo.MutationHookOptions<DownvoteFindingsTokenMutation, DownvoteFindingsTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownvoteFindingsTokenMutation, DownvoteFindingsTokenMutationVariables>(DownvoteFindingsTokenDocument, options);
      }
export type DownvoteFindingsTokenMutationHookResult = ReturnType<typeof useDownvoteFindingsTokenMutation>;
export type DownvoteFindingsTokenMutationResult = Apollo.MutationResult<DownvoteFindingsTokenMutation>;
export type DownvoteFindingsTokenMutationOptions = Apollo.BaseMutationOptions<DownvoteFindingsTokenMutation, DownvoteFindingsTokenMutationVariables>;
export const EditCompanyDocument = gql`
    mutation editCompany($benchmarkingIndex: Int, $city: String, $companyId: Int!, $country: String, $court: String, $foundingYear: Int, $hrbNumber: String, $legalName: String, $linkedinUrl: String, $pipedriveId: Int, $shortName: String, $sicCode: String, $street: String, $zipCode: String) {
  editCompany(
    benchmarkingIndex: $benchmarkingIndex
    city: $city
    companyId: $companyId
    country: $country
    court: $court
    foundingYear: $foundingYear
    hrbNumber: $hrbNumber
    legalName: $legalName
    linkedinUrl: $linkedinUrl
    pipedriveId: $pipedriveId
    shortName: $shortName
    sicCode: $sicCode
    street: $street
    zipCode: $zipCode
  ) {
    legalName
    shortName
    street
    city
    zipCode
    country
    linkedinUrl
    pipedriveId
    hrbNumber
    court
    benchmarkingIndex
    sicCode
    foundingYear
  }
}
    `;
export type EditCompanyMutationFn = Apollo.MutationFunction<EditCompanyMutation, EditCompanyMutationVariables>;

/**
 * __useEditCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyMutation, { data, loading, error }] = useEditCompanyMutation({
 *   variables: {
 *      benchmarkingIndex: // value for 'benchmarkingIndex'
 *      city: // value for 'city'
 *      companyId: // value for 'companyId'
 *      country: // value for 'country'
 *      court: // value for 'court'
 *      foundingYear: // value for 'foundingYear'
 *      hrbNumber: // value for 'hrbNumber'
 *      legalName: // value for 'legalName'
 *      linkedinUrl: // value for 'linkedinUrl'
 *      pipedriveId: // value for 'pipedriveId'
 *      shortName: // value for 'shortName'
 *      sicCode: // value for 'sicCode'
 *      street: // value for 'street'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useEditCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditCompanyMutation, EditCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCompanyMutation, EditCompanyMutationVariables>(EditCompanyDocument, options);
      }
export type EditCompanyMutationHookResult = ReturnType<typeof useEditCompanyMutation>;
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompanyMutation>;
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompanyMutation, EditCompanyMutationVariables>;
export const ExcelUploadFileDocument = gql`
    mutation excelUploadFile($input: UploadExcelFileInput!) {
  uploadExcelFile(input: $input) {
    ok
    file {
      id
      fileName
    }
  }
}
    `;
export type ExcelUploadFileMutationFn = Apollo.MutationFunction<ExcelUploadFileMutation, ExcelUploadFileMutationVariables>;

/**
 * __useExcelUploadFileMutation__
 *
 * To run a mutation, you first call `useExcelUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcelUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excelUploadFileMutation, { data, loading, error }] = useExcelUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExcelUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<ExcelUploadFileMutation, ExcelUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcelUploadFileMutation, ExcelUploadFileMutationVariables>(ExcelUploadFileDocument, options);
      }
export type ExcelUploadFileMutationHookResult = ReturnType<typeof useExcelUploadFileMutation>;
export type ExcelUploadFileMutationResult = Apollo.MutationResult<ExcelUploadFileMutation>;
export type ExcelUploadFileMutationOptions = Apollo.BaseMutationOptions<ExcelUploadFileMutation, ExcelUploadFileMutationVariables>;
export const ExcelUploadRowDocument = gql`
    mutation excelUploadRow($input: UploadExcelRowInput!) {
  uploadExcelRow(input: $input) {
    ok
  }
}
    `;
export type ExcelUploadRowMutationFn = Apollo.MutationFunction<ExcelUploadRowMutation, ExcelUploadRowMutationVariables>;

/**
 * __useExcelUploadRowMutation__
 *
 * To run a mutation, you first call `useExcelUploadRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcelUploadRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excelUploadRowMutation, { data, loading, error }] = useExcelUploadRowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExcelUploadRowMutation(baseOptions?: Apollo.MutationHookOptions<ExcelUploadRowMutation, ExcelUploadRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcelUploadRowMutation, ExcelUploadRowMutationVariables>(ExcelUploadRowDocument, options);
      }
export type ExcelUploadRowMutationHookResult = ReturnType<typeof useExcelUploadRowMutation>;
export type ExcelUploadRowMutationResult = Apollo.MutationResult<ExcelUploadRowMutation>;
export type ExcelUploadRowMutationOptions = Apollo.BaseMutationOptions<ExcelUploadRowMutation, ExcelUploadRowMutationVariables>;
export const ExcelUploadSheetDocument = gql`
    mutation excelUploadSheet($input: UploadExcelSheetInput!) {
  uploadExcelSheet(input: $input) {
    ok
    sheet {
      id
      sheetName
    }
  }
}
    `;
export type ExcelUploadSheetMutationFn = Apollo.MutationFunction<ExcelUploadSheetMutation, ExcelUploadSheetMutationVariables>;

/**
 * __useExcelUploadSheetMutation__
 *
 * To run a mutation, you first call `useExcelUploadSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcelUploadSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excelUploadSheetMutation, { data, loading, error }] = useExcelUploadSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExcelUploadSheetMutation(baseOptions?: Apollo.MutationHookOptions<ExcelUploadSheetMutation, ExcelUploadSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcelUploadSheetMutation, ExcelUploadSheetMutationVariables>(ExcelUploadSheetDocument, options);
      }
export type ExcelUploadSheetMutationHookResult = ReturnType<typeof useExcelUploadSheetMutation>;
export type ExcelUploadSheetMutationResult = Apollo.MutationResult<ExcelUploadSheetMutation>;
export type ExcelUploadSheetMutationOptions = Apollo.BaseMutationOptions<ExcelUploadSheetMutation, ExcelUploadSheetMutationVariables>;
export const FinishExcelFileUploadDocument = gql`
    mutation finishExcelFileUpload($input: Int!) {
  finishExcelFileUpload(fileId: $input) {
    ok
  }
}
    `;
export type FinishExcelFileUploadMutationFn = Apollo.MutationFunction<FinishExcelFileUploadMutation, FinishExcelFileUploadMutationVariables>;

/**
 * __useFinishExcelFileUploadMutation__
 *
 * To run a mutation, you first call `useFinishExcelFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishExcelFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishExcelFileUploadMutation, { data, loading, error }] = useFinishExcelFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishExcelFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<FinishExcelFileUploadMutation, FinishExcelFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishExcelFileUploadMutation, FinishExcelFileUploadMutationVariables>(FinishExcelFileUploadDocument, options);
      }
export type FinishExcelFileUploadMutationHookResult = ReturnType<typeof useFinishExcelFileUploadMutation>;
export type FinishExcelFileUploadMutationResult = Apollo.MutationResult<FinishExcelFileUploadMutation>;
export type FinishExcelFileUploadMutationOptions = Apollo.BaseMutationOptions<FinishExcelFileUploadMutation, FinishExcelFileUploadMutationVariables>;
export const InsertCertainProductCategoryDocument = gql`
    mutation insertCertainProductCategory($productCategorizationId: Int!) {
  insertCertainProductCategory(productCategorizationId: $productCategorizationId) {
    message
    success
  }
}
    `;
export type InsertCertainProductCategoryMutationFn = Apollo.MutationFunction<InsertCertainProductCategoryMutation, InsertCertainProductCategoryMutationVariables>;

/**
 * __useInsertCertainProductCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCertainProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCertainProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCertainProductCategoryMutation, { data, loading, error }] = useInsertCertainProductCategoryMutation({
 *   variables: {
 *      productCategorizationId: // value for 'productCategorizationId'
 *   },
 * });
 */
export function useInsertCertainProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertCertainProductCategoryMutation, InsertCertainProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCertainProductCategoryMutation, InsertCertainProductCategoryMutationVariables>(InsertCertainProductCategoryDocument, options);
      }
export type InsertCertainProductCategoryMutationHookResult = ReturnType<typeof useInsertCertainProductCategoryMutation>;
export type InsertCertainProductCategoryMutationResult = Apollo.MutationResult<InsertCertainProductCategoryMutation>;
export type InsertCertainProductCategoryMutationOptions = Apollo.BaseMutationOptions<InsertCertainProductCategoryMutation, InsertCertainProductCategoryMutationVariables>;
export const LabelBuyAndBuildTargetQualityDocument = gql`
    mutation labelBuyAndBuildTargetQuality($quality: Float!, $softwareCompanyId: Int!, $targetCompanyId: Int!) {
  labelBuyAndBuildTargetQuality(
    quality: $quality
    softwareCompanyId: $softwareCompanyId
    targetCompanyId: $targetCompanyId
  ) {
    ok
  }
}
    `;
export type LabelBuyAndBuildTargetQualityMutationFn = Apollo.MutationFunction<LabelBuyAndBuildTargetQualityMutation, LabelBuyAndBuildTargetQualityMutationVariables>;

/**
 * __useLabelBuyAndBuildTargetQualityMutation__
 *
 * To run a mutation, you first call `useLabelBuyAndBuildTargetQualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLabelBuyAndBuildTargetQualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [labelBuyAndBuildTargetQualityMutation, { data, loading, error }] = useLabelBuyAndBuildTargetQualityMutation({
 *   variables: {
 *      quality: // value for 'quality'
 *      softwareCompanyId: // value for 'softwareCompanyId'
 *      targetCompanyId: // value for 'targetCompanyId'
 *   },
 * });
 */
export function useLabelBuyAndBuildTargetQualityMutation(baseOptions?: Apollo.MutationHookOptions<LabelBuyAndBuildTargetQualityMutation, LabelBuyAndBuildTargetQualityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LabelBuyAndBuildTargetQualityMutation, LabelBuyAndBuildTargetQualityMutationVariables>(LabelBuyAndBuildTargetQualityDocument, options);
      }
export type LabelBuyAndBuildTargetQualityMutationHookResult = ReturnType<typeof useLabelBuyAndBuildTargetQualityMutation>;
export type LabelBuyAndBuildTargetQualityMutationResult = Apollo.MutationResult<LabelBuyAndBuildTargetQualityMutation>;
export type LabelBuyAndBuildTargetQualityMutationOptions = Apollo.BaseMutationOptions<LabelBuyAndBuildTargetQualityMutation, LabelBuyAndBuildTargetQualityMutationVariables>;
export const MarkCompanyAsNotSoftwareDocument = gql`
    mutation markCompanyAsNotSoftware($companyId: Int!) {
  markCompanyAsNotSoftware(companyId: $companyId) {
    ok
  }
}
    `;
export type MarkCompanyAsNotSoftwareMutationFn = Apollo.MutationFunction<MarkCompanyAsNotSoftwareMutation, MarkCompanyAsNotSoftwareMutationVariables>;

/**
 * __useMarkCompanyAsNotSoftwareMutation__
 *
 * To run a mutation, you first call `useMarkCompanyAsNotSoftwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCompanyAsNotSoftwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCompanyAsNotSoftwareMutation, { data, loading, error }] = useMarkCompanyAsNotSoftwareMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMarkCompanyAsNotSoftwareMutation(baseOptions?: Apollo.MutationHookOptions<MarkCompanyAsNotSoftwareMutation, MarkCompanyAsNotSoftwareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCompanyAsNotSoftwareMutation, MarkCompanyAsNotSoftwareMutationVariables>(MarkCompanyAsNotSoftwareDocument, options);
      }
export type MarkCompanyAsNotSoftwareMutationHookResult = ReturnType<typeof useMarkCompanyAsNotSoftwareMutation>;
export type MarkCompanyAsNotSoftwareMutationResult = Apollo.MutationResult<MarkCompanyAsNotSoftwareMutation>;
export type MarkCompanyAsNotSoftwareMutationOptions = Apollo.BaseMutationOptions<MarkCompanyAsNotSoftwareMutation, MarkCompanyAsNotSoftwareMutationVariables>;
export const MarkCompanyAsSoftwareDocument = gql`
    mutation markCompanyAsSoftware($companyId: Int!, $crawlResultId: Int) {
  markCompanyAsSoftware(companyId: $companyId, crawlResultId: $crawlResultId) {
    ok
  }
}
    `;
export type MarkCompanyAsSoftwareMutationFn = Apollo.MutationFunction<MarkCompanyAsSoftwareMutation, MarkCompanyAsSoftwareMutationVariables>;

/**
 * __useMarkCompanyAsSoftwareMutation__
 *
 * To run a mutation, you first call `useMarkCompanyAsSoftwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCompanyAsSoftwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCompanyAsSoftwareMutation, { data, loading, error }] = useMarkCompanyAsSoftwareMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      crawlResultId: // value for 'crawlResultId'
 *   },
 * });
 */
export function useMarkCompanyAsSoftwareMutation(baseOptions?: Apollo.MutationHookOptions<MarkCompanyAsSoftwareMutation, MarkCompanyAsSoftwareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCompanyAsSoftwareMutation, MarkCompanyAsSoftwareMutationVariables>(MarkCompanyAsSoftwareDocument, options);
      }
export type MarkCompanyAsSoftwareMutationHookResult = ReturnType<typeof useMarkCompanyAsSoftwareMutation>;
export type MarkCompanyAsSoftwareMutationResult = Apollo.MutationResult<MarkCompanyAsSoftwareMutation>;
export type MarkCompanyAsSoftwareMutationOptions = Apollo.BaseMutationOptions<MarkCompanyAsSoftwareMutation, MarkCompanyAsSoftwareMutationVariables>;
export const MarkCustomerReferenceObsoleteDocument = gql`
    mutation markCustomerReferenceObsolete($customerReferenceId: Int!) {
  markCustomerReferenceObsolete(customerReferenceId: $customerReferenceId) {
    success
    message
  }
}
    `;
export type MarkCustomerReferenceObsoleteMutationFn = Apollo.MutationFunction<MarkCustomerReferenceObsoleteMutation, MarkCustomerReferenceObsoleteMutationVariables>;

/**
 * __useMarkCustomerReferenceObsoleteMutation__
 *
 * To run a mutation, you first call `useMarkCustomerReferenceObsoleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCustomerReferenceObsoleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCustomerReferenceObsoleteMutation, { data, loading, error }] = useMarkCustomerReferenceObsoleteMutation({
 *   variables: {
 *      customerReferenceId: // value for 'customerReferenceId'
 *   },
 * });
 */
export function useMarkCustomerReferenceObsoleteMutation(baseOptions?: Apollo.MutationHookOptions<MarkCustomerReferenceObsoleteMutation, MarkCustomerReferenceObsoleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCustomerReferenceObsoleteMutation, MarkCustomerReferenceObsoleteMutationVariables>(MarkCustomerReferenceObsoleteDocument, options);
      }
export type MarkCustomerReferenceObsoleteMutationHookResult = ReturnType<typeof useMarkCustomerReferenceObsoleteMutation>;
export type MarkCustomerReferenceObsoleteMutationResult = Apollo.MutationResult<MarkCustomerReferenceObsoleteMutation>;
export type MarkCustomerReferenceObsoleteMutationOptions = Apollo.BaseMutationOptions<MarkCustomerReferenceObsoleteMutation, MarkCustomerReferenceObsoleteMutationVariables>;
export const MarkProductAsHorizontalDocument = gql`
    mutation markProductAsHorizontal($softwareCompanyId: Int!) {
  markProductAsHorizontal(softwareCompanyId: $softwareCompanyId) {
    message
    success
  }
}
    `;
export type MarkProductAsHorizontalMutationFn = Apollo.MutationFunction<MarkProductAsHorizontalMutation, MarkProductAsHorizontalMutationVariables>;

/**
 * __useMarkProductAsHorizontalMutation__
 *
 * To run a mutation, you first call `useMarkProductAsHorizontalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkProductAsHorizontalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markProductAsHorizontalMutation, { data, loading, error }] = useMarkProductAsHorizontalMutation({
 *   variables: {
 *      softwareCompanyId: // value for 'softwareCompanyId'
 *   },
 * });
 */
export function useMarkProductAsHorizontalMutation(baseOptions?: Apollo.MutationHookOptions<MarkProductAsHorizontalMutation, MarkProductAsHorizontalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkProductAsHorizontalMutation, MarkProductAsHorizontalMutationVariables>(MarkProductAsHorizontalDocument, options);
      }
export type MarkProductAsHorizontalMutationHookResult = ReturnType<typeof useMarkProductAsHorizontalMutation>;
export type MarkProductAsHorizontalMutationResult = Apollo.MutationResult<MarkProductAsHorizontalMutation>;
export type MarkProductAsHorizontalMutationOptions = Apollo.BaseMutationOptions<MarkProductAsHorizontalMutation, MarkProductAsHorizontalMutationVariables>;
export const PrecalculateAllKpisDocument = gql`
    mutation precalculateAllKpis($companyId: Int!) {
  precalculateAllKpis(companyId: $companyId) {
    ok
  }
}
    `;
export type PrecalculateAllKpisMutationFn = Apollo.MutationFunction<PrecalculateAllKpisMutation, PrecalculateAllKpisMutationVariables>;

/**
 * __usePrecalculateAllKpisMutation__
 *
 * To run a mutation, you first call `usePrecalculateAllKpisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrecalculateAllKpisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [precalculateAllKpisMutation, { data, loading, error }] = usePrecalculateAllKpisMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePrecalculateAllKpisMutation(baseOptions?: Apollo.MutationHookOptions<PrecalculateAllKpisMutation, PrecalculateAllKpisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrecalculateAllKpisMutation, PrecalculateAllKpisMutationVariables>(PrecalculateAllKpisDocument, options);
      }
export type PrecalculateAllKpisMutationHookResult = ReturnType<typeof usePrecalculateAllKpisMutation>;
export type PrecalculateAllKpisMutationResult = Apollo.MutationResult<PrecalculateAllKpisMutation>;
export type PrecalculateAllKpisMutationOptions = Apollo.BaseMutationOptions<PrecalculateAllKpisMutation, PrecalculateAllKpisMutationVariables>;
export const RemoveShortlistedBuyAndBuildTargetsDocument = gql`
    mutation removeShortlistedBuyAndBuildTargets($softwareCompanyId: Int!, $targetCompanyId: Int!) {
  removeShortlistedBuyAndBuildTargets(
    softwareCompanyId: $softwareCompanyId
    targetCompanyId: $targetCompanyId
  ) {
    ok
  }
}
    `;
export type RemoveShortlistedBuyAndBuildTargetsMutationFn = Apollo.MutationFunction<RemoveShortlistedBuyAndBuildTargetsMutation, RemoveShortlistedBuyAndBuildTargetsMutationVariables>;

/**
 * __useRemoveShortlistedBuyAndBuildTargetsMutation__
 *
 * To run a mutation, you first call `useRemoveShortlistedBuyAndBuildTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShortlistedBuyAndBuildTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShortlistedBuyAndBuildTargetsMutation, { data, loading, error }] = useRemoveShortlistedBuyAndBuildTargetsMutation({
 *   variables: {
 *      softwareCompanyId: // value for 'softwareCompanyId'
 *      targetCompanyId: // value for 'targetCompanyId'
 *   },
 * });
 */
export function useRemoveShortlistedBuyAndBuildTargetsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveShortlistedBuyAndBuildTargetsMutation, RemoveShortlistedBuyAndBuildTargetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveShortlistedBuyAndBuildTargetsMutation, RemoveShortlistedBuyAndBuildTargetsMutationVariables>(RemoveShortlistedBuyAndBuildTargetsDocument, options);
      }
export type RemoveShortlistedBuyAndBuildTargetsMutationHookResult = ReturnType<typeof useRemoveShortlistedBuyAndBuildTargetsMutation>;
export type RemoveShortlistedBuyAndBuildTargetsMutationResult = Apollo.MutationResult<RemoveShortlistedBuyAndBuildTargetsMutation>;
export type RemoveShortlistedBuyAndBuildTargetsMutationOptions = Apollo.BaseMutationOptions<RemoveShortlistedBuyAndBuildTargetsMutation, RemoveShortlistedBuyAndBuildTargetsMutationVariables>;
export const ResegmentCustomersDocument = gql`
    mutation resegmentCustomers($companyId: Int!) {
  resegmentAllCustomers(companyId: $companyId) {
    success
  }
}
    `;
export type ResegmentCustomersMutationFn = Apollo.MutationFunction<ResegmentCustomersMutation, ResegmentCustomersMutationVariables>;

/**
 * __useResegmentCustomersMutation__
 *
 * To run a mutation, you first call `useResegmentCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResegmentCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resegmentCustomersMutation, { data, loading, error }] = useResegmentCustomersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useResegmentCustomersMutation(baseOptions?: Apollo.MutationHookOptions<ResegmentCustomersMutation, ResegmentCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResegmentCustomersMutation, ResegmentCustomersMutationVariables>(ResegmentCustomersDocument, options);
      }
export type ResegmentCustomersMutationHookResult = ReturnType<typeof useResegmentCustomersMutation>;
export type ResegmentCustomersMutationResult = Apollo.MutationResult<ResegmentCustomersMutation>;
export type ResegmentCustomersMutationOptions = Apollo.BaseMutationOptions<ResegmentCustomersMutation, ResegmentCustomersMutationVariables>;
export const SetCompanySizeDocument = gql`
    mutation setCompanySize($companyId: Int!, $fte: Int!, $findingUrl: String!, $typeOfFinding: Int!) {
  setCompanySize(
    companyId: $companyId
    fte: $fte
    findingUrl: $findingUrl
    typeOfFinding: $typeOfFinding
  ) {
    ok
    typeOfFinding
    findingUrl
    fte
  }
}
    `;
export type SetCompanySizeMutationFn = Apollo.MutationFunction<SetCompanySizeMutation, SetCompanySizeMutationVariables>;

/**
 * __useSetCompanySizeMutation__
 *
 * To run a mutation, you first call `useSetCompanySizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanySizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanySizeMutation, { data, loading, error }] = useSetCompanySizeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      fte: // value for 'fte'
 *      findingUrl: // value for 'findingUrl'
 *      typeOfFinding: // value for 'typeOfFinding'
 *   },
 * });
 */
export function useSetCompanySizeMutation(baseOptions?: Apollo.MutationHookOptions<SetCompanySizeMutation, SetCompanySizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCompanySizeMutation, SetCompanySizeMutationVariables>(SetCompanySizeDocument, options);
      }
export type SetCompanySizeMutationHookResult = ReturnType<typeof useSetCompanySizeMutation>;
export type SetCompanySizeMutationResult = Apollo.MutationResult<SetCompanySizeMutation>;
export type SetCompanySizeMutationOptions = Apollo.BaseMutationOptions<SetCompanySizeMutation, SetCompanySizeMutationVariables>;
export const SetProductCategoryToZeroDocument = gql`
    mutation setProductCategoryToZero($productCategorizationId: Int!) {
  setProductCategoryToZero(productCategorizationId: $productCategorizationId) {
    message
    success
  }
}
    `;
export type SetProductCategoryToZeroMutationFn = Apollo.MutationFunction<SetProductCategoryToZeroMutation, SetProductCategoryToZeroMutationVariables>;

/**
 * __useSetProductCategoryToZeroMutation__
 *
 * To run a mutation, you first call `useSetProductCategoryToZeroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProductCategoryToZeroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProductCategoryToZeroMutation, { data, loading, error }] = useSetProductCategoryToZeroMutation({
 *   variables: {
 *      productCategorizationId: // value for 'productCategorizationId'
 *   },
 * });
 */
export function useSetProductCategoryToZeroMutation(baseOptions?: Apollo.MutationHookOptions<SetProductCategoryToZeroMutation, SetProductCategoryToZeroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProductCategoryToZeroMutation, SetProductCategoryToZeroMutationVariables>(SetProductCategoryToZeroDocument, options);
      }
export type SetProductCategoryToZeroMutationHookResult = ReturnType<typeof useSetProductCategoryToZeroMutation>;
export type SetProductCategoryToZeroMutationResult = Apollo.MutationResult<SetProductCategoryToZeroMutation>;
export type SetProductCategoryToZeroMutationOptions = Apollo.BaseMutationOptions<SetProductCategoryToZeroMutation, SetProductCategoryToZeroMutationVariables>;
export const ShortlistBuyAndBuildTargetDocument = gql`
    mutation shortlistBuyAndBuildTarget($softwareCompanyId: Int!, $targetCompanyId: Int!) {
  shortlistBuyAndBuildTarget(
    softwareCompanyId: $softwareCompanyId
    targetCompanyId: $targetCompanyId
  ) {
    ok
  }
}
    `;
export type ShortlistBuyAndBuildTargetMutationFn = Apollo.MutationFunction<ShortlistBuyAndBuildTargetMutation, ShortlistBuyAndBuildTargetMutationVariables>;

/**
 * __useShortlistBuyAndBuildTargetMutation__
 *
 * To run a mutation, you first call `useShortlistBuyAndBuildTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortlistBuyAndBuildTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortlistBuyAndBuildTargetMutation, { data, loading, error }] = useShortlistBuyAndBuildTargetMutation({
 *   variables: {
 *      softwareCompanyId: // value for 'softwareCompanyId'
 *      targetCompanyId: // value for 'targetCompanyId'
 *   },
 * });
 */
export function useShortlistBuyAndBuildTargetMutation(baseOptions?: Apollo.MutationHookOptions<ShortlistBuyAndBuildTargetMutation, ShortlistBuyAndBuildTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShortlistBuyAndBuildTargetMutation, ShortlistBuyAndBuildTargetMutationVariables>(ShortlistBuyAndBuildTargetDocument, options);
      }
export type ShortlistBuyAndBuildTargetMutationHookResult = ReturnType<typeof useShortlistBuyAndBuildTargetMutation>;
export type ShortlistBuyAndBuildTargetMutationResult = Apollo.MutationResult<ShortlistBuyAndBuildTargetMutation>;
export type ShortlistBuyAndBuildTargetMutationOptions = Apollo.BaseMutationOptions<ShortlistBuyAndBuildTargetMutation, ShortlistBuyAndBuildTargetMutationVariables>;
export const StartCrawlDocument = gql`
    mutation startCrawl($companyAddress: String, $companyName: String, $domain: String!, $forceDeepAnalysis: Boolean, $forceSw: Boolean, $pipedriveId: Int) {
  startCrawler(
    companyAddress: $companyAddress
    companyName: $companyName
    domain: $domain
    forceDeepAnalysis: $forceDeepAnalysis
    forceSw: $forceSw
    pipedriveId: $pipedriveId
  ) {
    success
  }
}
    `;
export type StartCrawlMutationFn = Apollo.MutationFunction<StartCrawlMutation, StartCrawlMutationVariables>;

/**
 * __useStartCrawlMutation__
 *
 * To run a mutation, you first call `useStartCrawlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCrawlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCrawlMutation, { data, loading, error }] = useStartCrawlMutation({
 *   variables: {
 *      companyAddress: // value for 'companyAddress'
 *      companyName: // value for 'companyName'
 *      domain: // value for 'domain'
 *      forceDeepAnalysis: // value for 'forceDeepAnalysis'
 *      forceSw: // value for 'forceSw'
 *      pipedriveId: // value for 'pipedriveId'
 *   },
 * });
 */
export function useStartCrawlMutation(baseOptions?: Apollo.MutationHookOptions<StartCrawlMutation, StartCrawlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartCrawlMutation, StartCrawlMutationVariables>(StartCrawlDocument, options);
      }
export type StartCrawlMutationHookResult = ReturnType<typeof useStartCrawlMutation>;
export type StartCrawlMutationResult = Apollo.MutationResult<StartCrawlMutation>;
export type StartCrawlMutationOptions = Apollo.BaseMutationOptions<StartCrawlMutation, StartCrawlMutationVariables>;
export const StartVerificationSessionDocument = gql`
    mutation startVerificationSession($companyId: Int!, $authId: String!) {
  startCompanyVerificationSession(companyId: $companyId, authId: $authId) {
    success
    message
  }
}
    `;
export type StartVerificationSessionMutationFn = Apollo.MutationFunction<StartVerificationSessionMutation, StartVerificationSessionMutationVariables>;

/**
 * __useStartVerificationSessionMutation__
 *
 * To run a mutation, you first call `useStartVerificationSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVerificationSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVerificationSessionMutation, { data, loading, error }] = useStartVerificationSessionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      authId: // value for 'authId'
 *   },
 * });
 */
export function useStartVerificationSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartVerificationSessionMutation, StartVerificationSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartVerificationSessionMutation, StartVerificationSessionMutationVariables>(StartVerificationSessionDocument, options);
      }
export type StartVerificationSessionMutationHookResult = ReturnType<typeof useStartVerificationSessionMutation>;
export type StartVerificationSessionMutationResult = Apollo.MutationResult<StartVerificationSessionMutation>;
export type StartVerificationSessionMutationOptions = Apollo.BaseMutationOptions<StartVerificationSessionMutation, StartVerificationSessionMutationVariables>;
export const TransferBuyAndBuildTargetsToPipedriveDocument = gql`
    mutation transferBuyAndBuildTargetsToPipedrive($softwareCompanyId: Int!) {
  transferBuyAndBuildTargetsToPipedrive(softwareCompanyId: $softwareCompanyId) {
    ok
  }
}
    `;
export type TransferBuyAndBuildTargetsToPipedriveMutationFn = Apollo.MutationFunction<TransferBuyAndBuildTargetsToPipedriveMutation, TransferBuyAndBuildTargetsToPipedriveMutationVariables>;

/**
 * __useTransferBuyAndBuildTargetsToPipedriveMutation__
 *
 * To run a mutation, you first call `useTransferBuyAndBuildTargetsToPipedriveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferBuyAndBuildTargetsToPipedriveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferBuyAndBuildTargetsToPipedriveMutation, { data, loading, error }] = useTransferBuyAndBuildTargetsToPipedriveMutation({
 *   variables: {
 *      softwareCompanyId: // value for 'softwareCompanyId'
 *   },
 * });
 */
export function useTransferBuyAndBuildTargetsToPipedriveMutation(baseOptions?: Apollo.MutationHookOptions<TransferBuyAndBuildTargetsToPipedriveMutation, TransferBuyAndBuildTargetsToPipedriveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferBuyAndBuildTargetsToPipedriveMutation, TransferBuyAndBuildTargetsToPipedriveMutationVariables>(TransferBuyAndBuildTargetsToPipedriveDocument, options);
      }
export type TransferBuyAndBuildTargetsToPipedriveMutationHookResult = ReturnType<typeof useTransferBuyAndBuildTargetsToPipedriveMutation>;
export type TransferBuyAndBuildTargetsToPipedriveMutationResult = Apollo.MutationResult<TransferBuyAndBuildTargetsToPipedriveMutation>;
export type TransferBuyAndBuildTargetsToPipedriveMutationOptions = Apollo.BaseMutationOptions<TransferBuyAndBuildTargetsToPipedriveMutation, TransferBuyAndBuildTargetsToPipedriveMutationVariables>;
export const UpdateCortexConfigDocument = gql`
    mutation UpdateCortexConfig($attributes: JSONString!) {
  updateCortexConfig(attributes: $attributes) {
    ok
  }
}
    `;
export type UpdateCortexConfigMutationFn = Apollo.MutationFunction<UpdateCortexConfigMutation, UpdateCortexConfigMutationVariables>;

/**
 * __useUpdateCortexConfigMutation__
 *
 * To run a mutation, you first call `useUpdateCortexConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCortexConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCortexConfigMutation, { data, loading, error }] = useUpdateCortexConfigMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateCortexConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCortexConfigMutation, UpdateCortexConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCortexConfigMutation, UpdateCortexConfigMutationVariables>(UpdateCortexConfigDocument, options);
      }
export type UpdateCortexConfigMutationHookResult = ReturnType<typeof useUpdateCortexConfigMutation>;
export type UpdateCortexConfigMutationResult = Apollo.MutationResult<UpdateCortexConfigMutation>;
export type UpdateCortexConfigMutationOptions = Apollo.BaseMutationOptions<UpdateCortexConfigMutation, UpdateCortexConfigMutationVariables>;
export const UpdateSegmentForCustomerDocument = gql`
    mutation updateSegmentForCustomer($input: CustomerSegmentInput!, $segmentId: Int!) {
  updateCustomerSegment(input: $input, segmentId: $segmentId) {
    success
  }
}
    `;
export type UpdateSegmentForCustomerMutationFn = Apollo.MutationFunction<UpdateSegmentForCustomerMutation, UpdateSegmentForCustomerMutationVariables>;

/**
 * __useUpdateSegmentForCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentForCustomerMutation, { data, loading, error }] = useUpdateSegmentForCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      segmentId: // value for 'segmentId'
 *   },
 * });
 */
export function useUpdateSegmentForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSegmentForCustomerMutation, UpdateSegmentForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSegmentForCustomerMutation, UpdateSegmentForCustomerMutationVariables>(UpdateSegmentForCustomerDocument, options);
      }
export type UpdateSegmentForCustomerMutationHookResult = ReturnType<typeof useUpdateSegmentForCustomerMutation>;
export type UpdateSegmentForCustomerMutationResult = Apollo.MutationResult<UpdateSegmentForCustomerMutation>;
export type UpdateSegmentForCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateSegmentForCustomerMutation, UpdateSegmentForCustomerMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($companyId: Int!, $file: String!, $fileName: String!, $isBmFile: Boolean!) {
  uploadFile(
    companyId: $companyId
    file: $file
    fileName: $fileName
    isBmFile: $isBmFile
  ) {
    url
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *      isBmFile: // value for 'isBmFile'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UpvoteFindingsTokenDocument = gql`
    mutation upvoteFindingsToken($tokenId: Int!) {
  upvoteFindingsToken(tokenId: $tokenId) {
    ok
    token {
      id
    }
  }
}
    `;
export type UpvoteFindingsTokenMutationFn = Apollo.MutationFunction<UpvoteFindingsTokenMutation, UpvoteFindingsTokenMutationVariables>;

/**
 * __useUpvoteFindingsTokenMutation__
 *
 * To run a mutation, you first call `useUpvoteFindingsTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpvoteFindingsTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upvoteFindingsTokenMutation, { data, loading, error }] = useUpvoteFindingsTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useUpvoteFindingsTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpvoteFindingsTokenMutation, UpvoteFindingsTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpvoteFindingsTokenMutation, UpvoteFindingsTokenMutationVariables>(UpvoteFindingsTokenDocument, options);
      }
export type UpvoteFindingsTokenMutationHookResult = ReturnType<typeof useUpvoteFindingsTokenMutation>;
export type UpvoteFindingsTokenMutationResult = Apollo.MutationResult<UpvoteFindingsTokenMutation>;
export type UpvoteFindingsTokenMutationOptions = Apollo.BaseMutationOptions<UpvoteFindingsTokenMutation, UpvoteFindingsTokenMutationVariables>;
export const BuyAndBuildListDocument = gql`
    query buyAndBuildList($companyId: Int!, $first: Int, $after: Int, $before: Int, $isShortlisted: Boolean) {
  companyAssesment(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      node {
        dbId
        buyAndBuildTargets(
          first: $first
          before: $before
          after: $after
          isShortlisted: $isShortlisted
        ) {
          pageInfo {
            startId
            endId
          }
          edges {
            node {
              isShortlistedCompany1
              manuallyLabeledScore
              buyAndBuildRelevance
              company {
                dbId
                uniqueDomain
                iabDomainCategory
                fte
                country
                summaryDescription
                latestCrawlResult {
                  xoviKeywords {
                    edges {
                      node {
                        keyword
                        cpc
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useBuyAndBuildListQuery__
 *
 * To run a query within a React component, call `useBuyAndBuildListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyAndBuildListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyAndBuildListQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      isShortlisted: // value for 'isShortlisted'
 *   },
 * });
 */
export function useBuyAndBuildListQuery(baseOptions: Apollo.QueryHookOptions<BuyAndBuildListQuery, BuyAndBuildListQueryVariables> & ({ variables: BuyAndBuildListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuyAndBuildListQuery, BuyAndBuildListQueryVariables>(BuyAndBuildListDocument, options);
      }
export function useBuyAndBuildListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuyAndBuildListQuery, BuyAndBuildListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuyAndBuildListQuery, BuyAndBuildListQueryVariables>(BuyAndBuildListDocument, options);
        }
export function useBuyAndBuildListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuyAndBuildListQuery, BuyAndBuildListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuyAndBuildListQuery, BuyAndBuildListQueryVariables>(BuyAndBuildListDocument, options);
        }
export type BuyAndBuildListQueryHookResult = ReturnType<typeof useBuyAndBuildListQuery>;
export type BuyAndBuildListLazyQueryHookResult = ReturnType<typeof useBuyAndBuildListLazyQuery>;
export type BuyAndBuildListSuspenseQueryHookResult = ReturnType<typeof useBuyAndBuildListSuspenseQuery>;
export type BuyAndBuildListQueryResult = Apollo.QueryResult<BuyAndBuildListQuery, BuyAndBuildListQueryVariables>;
export const CompaniesByCategoriesDocument = gql`
    query companiesByCategories($subCategory1: String, $subCategory2: String, $subCategory3: String) {
  companiesByCategories(
    subCategory1: $subCategory1
    subCategory2: $subCategory2
    subCategory3: $subCategory3
  ) {
    id
    dbId
    shortName
    country
    uniqueDomain
  }
}
    `;

/**
 * __useCompaniesByCategoriesQuery__
 *
 * To run a query within a React component, call `useCompaniesByCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByCategoriesQuery({
 *   variables: {
 *      subCategory1: // value for 'subCategory1'
 *      subCategory2: // value for 'subCategory2'
 *      subCategory3: // value for 'subCategory3'
 *   },
 * });
 */
export function useCompaniesByCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>(CompaniesByCategoriesDocument, options);
      }
export function useCompaniesByCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>(CompaniesByCategoriesDocument, options);
        }
export function useCompaniesByCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>(CompaniesByCategoriesDocument, options);
        }
export type CompaniesByCategoriesQueryHookResult = ReturnType<typeof useCompaniesByCategoriesQuery>;
export type CompaniesByCategoriesLazyQueryHookResult = ReturnType<typeof useCompaniesByCategoriesLazyQuery>;
export type CompaniesByCategoriesSuspenseQueryHookResult = ReturnType<typeof useCompaniesByCategoriesSuspenseQuery>;
export type CompaniesByCategoriesQueryResult = Apollo.QueryResult<CompaniesByCategoriesQuery, CompaniesByCategoriesQueryVariables>;
export const CompaniesByIndustriesDocument = gql`
    query companiesByIndustries($sicCodes: [String]!) {
  companiesByIndustries(sicCodes: $sicCodes) {
    id
    dbId
    shortName
    country
    uniqueDomain
  }
}
    `;

/**
 * __useCompaniesByIndustriesQuery__
 *
 * To run a query within a React component, call `useCompaniesByIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByIndustriesQuery({
 *   variables: {
 *      sicCodes: // value for 'sicCodes'
 *   },
 * });
 */
export function useCompaniesByIndustriesQuery(baseOptions: Apollo.QueryHookOptions<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables> & ({ variables: CompaniesByIndustriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables>(CompaniesByIndustriesDocument, options);
      }
export function useCompaniesByIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables>(CompaniesByIndustriesDocument, options);
        }
export function useCompaniesByIndustriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables>(CompaniesByIndustriesDocument, options);
        }
export type CompaniesByIndustriesQueryHookResult = ReturnType<typeof useCompaniesByIndustriesQuery>;
export type CompaniesByIndustriesLazyQueryHookResult = ReturnType<typeof useCompaniesByIndustriesLazyQuery>;
export type CompaniesByIndustriesSuspenseQueryHookResult = ReturnType<typeof useCompaniesByIndustriesSuspenseQuery>;
export type CompaniesByIndustriesQueryResult = Apollo.QueryResult<CompaniesByIndustriesQuery, CompaniesByIndustriesQueryVariables>;
export const CompaniesByIndustryAndCategoryDocument = gql`
    query companiesByIndustryAndCategory($sicCodes: [String], $subCategory1: String, $subCategory2: String, $subCategory3: String) {
  companiesByIndustryAndCategory(
    sicCodes: $sicCodes
    subCategory1: $subCategory1
    subCategory2: $subCategory2
    subCategory3: $subCategory3
  ) {
    id
    dbId
    shortName
    country
    uniqueDomain
  }
}
    `;

/**
 * __useCompaniesByIndustryAndCategoryQuery__
 *
 * To run a query within a React component, call `useCompaniesByIndustryAndCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByIndustryAndCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByIndustryAndCategoryQuery({
 *   variables: {
 *      sicCodes: // value for 'sicCodes'
 *      subCategory1: // value for 'subCategory1'
 *      subCategory2: // value for 'subCategory2'
 *      subCategory3: // value for 'subCategory3'
 *   },
 * });
 */
export function useCompaniesByIndustryAndCategoryQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>(CompaniesByIndustryAndCategoryDocument, options);
      }
export function useCompaniesByIndustryAndCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>(CompaniesByIndustryAndCategoryDocument, options);
        }
export function useCompaniesByIndustryAndCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>(CompaniesByIndustryAndCategoryDocument, options);
        }
export type CompaniesByIndustryAndCategoryQueryHookResult = ReturnType<typeof useCompaniesByIndustryAndCategoryQuery>;
export type CompaniesByIndustryAndCategoryLazyQueryHookResult = ReturnType<typeof useCompaniesByIndustryAndCategoryLazyQuery>;
export type CompaniesByIndustryAndCategorySuspenseQueryHookResult = ReturnType<typeof useCompaniesByIndustryAndCategorySuspenseQuery>;
export type CompaniesByIndustryAndCategoryQueryResult = Apollo.QueryResult<CompaniesByIndustryAndCategoryQuery, CompaniesByIndustryAndCategoryQueryVariables>;
export const CompanyAssesmentDocument = gql`
    query companyAssesment($domain: String!) {
  companyAssesment(domain: $domain) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        maxFte
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useCompanyAssesmentQuery__
 *
 * To run a query within a React component, call `useCompanyAssesmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAssesmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAssesmentQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useCompanyAssesmentQuery(baseOptions: Apollo.QueryHookOptions<CompanyAssesmentQuery, CompanyAssesmentQueryVariables> & ({ variables: CompanyAssesmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyAssesmentQuery, CompanyAssesmentQueryVariables>(CompanyAssesmentDocument, options);
      }
export function useCompanyAssesmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyAssesmentQuery, CompanyAssesmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyAssesmentQuery, CompanyAssesmentQueryVariables>(CompanyAssesmentDocument, options);
        }
export function useCompanyAssesmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanyAssesmentQuery, CompanyAssesmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyAssesmentQuery, CompanyAssesmentQueryVariables>(CompanyAssesmentDocument, options);
        }
export type CompanyAssesmentQueryHookResult = ReturnType<typeof useCompanyAssesmentQuery>;
export type CompanyAssesmentLazyQueryHookResult = ReturnType<typeof useCompanyAssesmentLazyQuery>;
export type CompanyAssesmentSuspenseQueryHookResult = ReturnType<typeof useCompanyAssesmentSuspenseQuery>;
export type CompanyAssesmentQueryResult = Apollo.QueryResult<CompanyAssesmentQuery, CompanyAssesmentQueryVariables>;
export const CompanyInboxListDocument = gql`
    query companyInboxList($country: String, $platformId: Int, $newPlatforms: Boolean, $prioritizedPlatforms: Boolean, $falseNegatives: Boolean) {
  companyInboxList(
    country: $country
    platformId: $platformId
    newPlatforms: $newPlatforms
    prioritizedPlatforms: $prioritizedPlatforms
    falseNegatives: $falseNegatives
  ) {
    dbId
    uniqueDomain
    country
    sicCode
    sicCodeCertainty
    fte
    privateOwned
    datetimeCreated
    productCategorization(first: 1) {
      edges {
        node {
          id
          softwareCategory {
            subCategory1
          }
        }
      }
    }
    iabDomainCategory
    peOwned
  }
}
    `;

/**
 * __useCompanyInboxListQuery__
 *
 * To run a query within a React component, call `useCompanyInboxListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInboxListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInboxListQuery({
 *   variables: {
 *      country: // value for 'country'
 *      platformId: // value for 'platformId'
 *      newPlatforms: // value for 'newPlatforms'
 *      prioritizedPlatforms: // value for 'prioritizedPlatforms'
 *      falseNegatives: // value for 'falseNegatives'
 *   },
 * });
 */
export function useCompanyInboxListQuery(baseOptions?: Apollo.QueryHookOptions<CompanyInboxListQuery, CompanyInboxListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyInboxListQuery, CompanyInboxListQueryVariables>(CompanyInboxListDocument, options);
      }
export function useCompanyInboxListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyInboxListQuery, CompanyInboxListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyInboxListQuery, CompanyInboxListQueryVariables>(CompanyInboxListDocument, options);
        }
export function useCompanyInboxListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanyInboxListQuery, CompanyInboxListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyInboxListQuery, CompanyInboxListQueryVariables>(CompanyInboxListDocument, options);
        }
export type CompanyInboxListQueryHookResult = ReturnType<typeof useCompanyInboxListQuery>;
export type CompanyInboxListLazyQueryHookResult = ReturnType<typeof useCompanyInboxListLazyQuery>;
export type CompanyInboxListSuspenseQueryHookResult = ReturnType<typeof useCompanyInboxListSuspenseQuery>;
export type CompanyInboxListQueryResult = Apollo.QueryResult<CompanyInboxListQuery, CompanyInboxListQueryVariables>;
export const CompetitionCpcKeywordsDocument = gql`
    query competitionCpcKeywords($companyId: Int) {
  competitionCpcKeywords(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        cpc
        keyword
        dbId
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useCompetitionCpcKeywordsQuery__
 *
 * To run a query within a React component, call `useCompetitionCpcKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitionCpcKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitionCpcKeywordsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompetitionCpcKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>(CompetitionCpcKeywordsDocument, options);
      }
export function useCompetitionCpcKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>(CompetitionCpcKeywordsDocument, options);
        }
export function useCompetitionCpcKeywordsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>(CompetitionCpcKeywordsDocument, options);
        }
export type CompetitionCpcKeywordsQueryHookResult = ReturnType<typeof useCompetitionCpcKeywordsQuery>;
export type CompetitionCpcKeywordsLazyQueryHookResult = ReturnType<typeof useCompetitionCpcKeywordsLazyQuery>;
export type CompetitionCpcKeywordsSuspenseQueryHookResult = ReturnType<typeof useCompetitionCpcKeywordsSuspenseQuery>;
export type CompetitionCpcKeywordsQueryResult = Apollo.QueryResult<CompetitionCpcKeywordsQuery, CompetitionCpcKeywordsQueryVariables>;
export const CortexConfigDocument = gql`
    query cortexConfig {
  cortexConfig {
    postToZapier
    maxRecursionDepth
    mongoSiteCacheInDays
    minFacesForGroupPicture
    maxCrawledPagesPerDomain
    macAcceptableWebsitePopularity
    assessmentXlsUploadTime
    assessmentXlsTemplateId
    initialBbWeightForSourcescrubTags
    pipedriveDfmTargetDealsFilterId
    pipedriveOpenTargetDealsLimit
    pipedriveSoftwareCompanyOrgTypeIndicator
    pipedriveCortexLabelId
    pipedriveHotLeadLabelId
    sourcescrubTagIdForGenericGenericCompanyLabel
  }
}
    `;

/**
 * __useCortexConfigQuery__
 *
 * To run a query within a React component, call `useCortexConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCortexConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCortexConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useCortexConfigQuery(baseOptions?: Apollo.QueryHookOptions<CortexConfigQuery, CortexConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CortexConfigQuery, CortexConfigQueryVariables>(CortexConfigDocument, options);
      }
export function useCortexConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CortexConfigQuery, CortexConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CortexConfigQuery, CortexConfigQueryVariables>(CortexConfigDocument, options);
        }
export function useCortexConfigSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CortexConfigQuery, CortexConfigQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CortexConfigQuery, CortexConfigQueryVariables>(CortexConfigDocument, options);
        }
export type CortexConfigQueryHookResult = ReturnType<typeof useCortexConfigQuery>;
export type CortexConfigLazyQueryHookResult = ReturnType<typeof useCortexConfigLazyQuery>;
export type CortexConfigSuspenseQueryHookResult = ReturnType<typeof useCortexConfigSuspenseQuery>;
export type CortexConfigQueryResult = Apollo.QueryResult<CortexConfigQuery, CortexConfigQueryVariables>;
export const CustomerIndustryGrowthRatesDocument = gql`
    query customerIndustryGrowthRates($companyId: Int) {
  customerIndustryGrowthRates(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        adjustedMedianGrowth
        sampleSize
        sicDesc
        dbId
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useCustomerIndustryGrowthRatesQuery__
 *
 * To run a query within a React component, call `useCustomerIndustryGrowthRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerIndustryGrowthRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerIndustryGrowthRatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCustomerIndustryGrowthRatesQuery(baseOptions?: Apollo.QueryHookOptions<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>(CustomerIndustryGrowthRatesDocument, options);
      }
export function useCustomerIndustryGrowthRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>(CustomerIndustryGrowthRatesDocument, options);
        }
export function useCustomerIndustryGrowthRatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>(CustomerIndustryGrowthRatesDocument, options);
        }
export type CustomerIndustryGrowthRatesQueryHookResult = ReturnType<typeof useCustomerIndustryGrowthRatesQuery>;
export type CustomerIndustryGrowthRatesLazyQueryHookResult = ReturnType<typeof useCustomerIndustryGrowthRatesLazyQuery>;
export type CustomerIndustryGrowthRatesSuspenseQueryHookResult = ReturnType<typeof useCustomerIndustryGrowthRatesSuspenseQuery>;
export type CustomerIndustryGrowthRatesQueryResult = Apollo.QueryResult<CustomerIndustryGrowthRatesQuery, CustomerIndustryGrowthRatesQueryVariables>;
export const CustomerSegmentsDocument = gql`
    query customerSegments($companyId: Int!) {
  customerSegments(companyId: $companyId) {
    id
    segmentName
    segmentDescription
    keyword1
    bidGroupCompanyId
  }
}
    `;

/**
 * __useCustomerSegmentsQuery__
 *
 * To run a query within a React component, call `useCustomerSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSegmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCustomerSegmentsQuery(baseOptions: Apollo.QueryHookOptions<CustomerSegmentsQuery, CustomerSegmentsQueryVariables> & ({ variables: CustomerSegmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerSegmentsQuery, CustomerSegmentsQueryVariables>(CustomerSegmentsDocument, options);
      }
export function useCustomerSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerSegmentsQuery, CustomerSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerSegmentsQuery, CustomerSegmentsQueryVariables>(CustomerSegmentsDocument, options);
        }
export function useCustomerSegmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomerSegmentsQuery, CustomerSegmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerSegmentsQuery, CustomerSegmentsQueryVariables>(CustomerSegmentsDocument, options);
        }
export type CustomerSegmentsQueryHookResult = ReturnType<typeof useCustomerSegmentsQuery>;
export type CustomerSegmentsLazyQueryHookResult = ReturnType<typeof useCustomerSegmentsLazyQuery>;
export type CustomerSegmentsSuspenseQueryHookResult = ReturnType<typeof useCustomerSegmentsSuspenseQuery>;
export type CustomerSegmentsQueryResult = Apollo.QueryResult<CustomerSegmentsQuery, CustomerSegmentsQueryVariables>;
export const DealAssessmentFileUrlDocument = gql`
    query dealAssessmentFileUrl($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        dealAssessmentFileUrl {
          downloadUrl
          generationTriggered
          noDataUploaded
          dateOfFile
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useDealAssessmentFileUrlQuery__
 *
 * To run a query within a React component, call `useDealAssessmentFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealAssessmentFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealAssessmentFileUrlQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDealAssessmentFileUrlQuery(baseOptions?: Apollo.QueryHookOptions<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>(DealAssessmentFileUrlDocument, options);
      }
export function useDealAssessmentFileUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>(DealAssessmentFileUrlDocument, options);
        }
export function useDealAssessmentFileUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>(DealAssessmentFileUrlDocument, options);
        }
export type DealAssessmentFileUrlQueryHookResult = ReturnType<typeof useDealAssessmentFileUrlQuery>;
export type DealAssessmentFileUrlLazyQueryHookResult = ReturnType<typeof useDealAssessmentFileUrlLazyQuery>;
export type DealAssessmentFileUrlSuspenseQueryHookResult = ReturnType<typeof useDealAssessmentFileUrlSuspenseQuery>;
export type DealAssessmentFileUrlQueryResult = Apollo.QueryResult<DealAssessmentFileUrlQuery, DealAssessmentFileUrlQueryVariables>;
export const EmployeeStaticsDocument = gql`
    query employeeStatics($companyId: Int) {
  employeeStatics(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        medianEmploymentMonths
        medianTotalEmploymentMonths
        medianPreviousEmployers
        numberOfEmployees
        teamQualityPercentage
        dbId
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useEmployeeStaticsQuery__
 *
 * To run a query within a React component, call `useEmployeeStaticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeStaticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeStaticsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEmployeeStaticsQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>(EmployeeStaticsDocument, options);
      }
export function useEmployeeStaticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>(EmployeeStaticsDocument, options);
        }
export function useEmployeeStaticsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>(EmployeeStaticsDocument, options);
        }
export type EmployeeStaticsQueryHookResult = ReturnType<typeof useEmployeeStaticsQuery>;
export type EmployeeStaticsLazyQueryHookResult = ReturnType<typeof useEmployeeStaticsLazyQuery>;
export type EmployeeStaticsSuspenseQueryHookResult = ReturnType<typeof useEmployeeStaticsSuspenseQuery>;
export type EmployeeStaticsQueryResult = Apollo.QueryResult<EmployeeStaticsQuery, EmployeeStaticsQueryVariables>;
export const GetAllShareholdersDocument = gql`
    query getAllShareholders($companyId: Int) {
  getCompany(companyId: $companyId) {
    edges {
      node {
        shareholders {
          edges {
            node {
              dbId
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllShareholdersQuery__
 *
 * To run a query within a React component, call `useGetAllShareholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShareholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShareholdersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllShareholdersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>(GetAllShareholdersDocument, options);
      }
export function useGetAllShareholdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>(GetAllShareholdersDocument, options);
        }
export function useGetAllShareholdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>(GetAllShareholdersDocument, options);
        }
export type GetAllShareholdersQueryHookResult = ReturnType<typeof useGetAllShareholdersQuery>;
export type GetAllShareholdersLazyQueryHookResult = ReturnType<typeof useGetAllShareholdersLazyQuery>;
export type GetAllShareholdersSuspenseQueryHookResult = ReturnType<typeof useGetAllShareholdersSuspenseQuery>;
export type GetAllShareholdersQueryResult = Apollo.QueryResult<GetAllShareholdersQuery, GetAllShareholdersQueryVariables>;
export const GetAllShareholdingsDocument = gql`
    query getAllShareholdings($companyId: Int) {
  getCompany(companyId: $companyId) {
    edges {
      node {
        genericShareholdings {
          edges {
            node {
              id
              dbId
              genericShareholderId
              genericCompanyId
              holding
              holdingCount
              genericShareholder {
                dbId
                name
                isPe
                isTradeBuyer
                isNaturalPerson
                yearBornOrFounded
                typeOfInvestor
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllShareholdingsQuery__
 *
 * To run a query within a React component, call `useGetAllShareholdingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShareholdingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShareholdingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllShareholdingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>(GetAllShareholdingsDocument, options);
      }
export function useGetAllShareholdingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>(GetAllShareholdingsDocument, options);
        }
export function useGetAllShareholdingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>(GetAllShareholdingsDocument, options);
        }
export type GetAllShareholdingsQueryHookResult = ReturnType<typeof useGetAllShareholdingsQuery>;
export type GetAllShareholdingsLazyQueryHookResult = ReturnType<typeof useGetAllShareholdingsLazyQuery>;
export type GetAllShareholdingsSuspenseQueryHookResult = ReturnType<typeof useGetAllShareholdingsSuspenseQuery>;
export type GetAllShareholdingsQueryResult = Apollo.QueryResult<GetAllShareholdingsQuery, GetAllShareholdingsQueryVariables>;
export const GetAllSicCodesDocument = gql`
    query getAllSicCodes($first: Int, $searchTerm: String) {
  getAllSicCodes(first: $first, searchTerm: $searchTerm) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        sicCodeStr
        description
        id
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetAllSicCodesQuery__
 *
 * To run a query within a React component, call `useGetAllSicCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSicCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSicCodesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetAllSicCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>(GetAllSicCodesDocument, options);
      }
export function useGetAllSicCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>(GetAllSicCodesDocument, options);
        }
export function useGetAllSicCodesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>(GetAllSicCodesDocument, options);
        }
export type GetAllSicCodesQueryHookResult = ReturnType<typeof useGetAllSicCodesQuery>;
export type GetAllSicCodesLazyQueryHookResult = ReturnType<typeof useGetAllSicCodesLazyQuery>;
export type GetAllSicCodesSuspenseQueryHookResult = ReturnType<typeof useGetAllSicCodesSuspenseQuery>;
export type GetAllSicCodesQueryResult = Apollo.QueryResult<GetAllSicCodesQuery, GetAllSicCodesQueryVariables>;
export const GetBuyAndBuildPlatformsDocument = gql`
    query getBuyAndBuildPlatforms($sortForTargetId: Int) {
  getBuyAndBuildPlatforms(sortForTargetId: $sortForTargetId) {
    edges {
      cursor
      node {
        dbId
        shortName
        pipedriveId
      }
    }
  }
}
    `;

/**
 * __useGetBuyAndBuildPlatformsQuery__
 *
 * To run a query within a React component, call `useGetBuyAndBuildPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyAndBuildPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyAndBuildPlatformsQuery({
 *   variables: {
 *      sortForTargetId: // value for 'sortForTargetId'
 *   },
 * });
 */
export function useGetBuyAndBuildPlatformsQuery(baseOptions?: Apollo.QueryHookOptions<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>(GetBuyAndBuildPlatformsDocument, options);
      }
export function useGetBuyAndBuildPlatformsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>(GetBuyAndBuildPlatformsDocument, options);
        }
export function useGetBuyAndBuildPlatformsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>(GetBuyAndBuildPlatformsDocument, options);
        }
export type GetBuyAndBuildPlatformsQueryHookResult = ReturnType<typeof useGetBuyAndBuildPlatformsQuery>;
export type GetBuyAndBuildPlatformsLazyQueryHookResult = ReturnType<typeof useGetBuyAndBuildPlatformsLazyQuery>;
export type GetBuyAndBuildPlatformsSuspenseQueryHookResult = ReturnType<typeof useGetBuyAndBuildPlatformsSuspenseQuery>;
export type GetBuyAndBuildPlatformsQueryResult = Apollo.QueryResult<GetBuyAndBuildPlatformsQuery, GetBuyAndBuildPlatformsQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        legalName
        shortName
        street
        city
        zipCode
        country
        linkedinUrl
        pipedriveId
        hrbNumber
        court
        benchmarkingIndex
        sicCode
        foundingYear
        isSoftwareCompany
        sicCodeCertainty
        uniqueDomain
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export function useGetCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanySuspenseQueryHookResult = ReturnType<typeof useGetCompanySuspenseQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyForDfmDocument = gql`
    query getCompanyForDFM($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        shortName
        pipedriveId
        uniqueDomain
        legalName
        address
        sicCode
        validated
        managingDirector
        linkedinDescription
        hrbNumber
        court
        city
        street
        zipCode
        country
        crawlUrl
        linkedinUrl
        emailPattern
        foundingYear
        latestUrl
        linkedinDescription
        iabDomainCategory
        isRelevant
        datetimeCreated
        datetimeModified
        companyAnalyses {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              fte
              ebitda
              revenue
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetCompanyForDfmQuery__
 *
 * To run a query within a React component, call `useGetCompanyForDfmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForDfmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForDfmQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyForDfmQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>(GetCompanyForDfmDocument, options);
      }
export function useGetCompanyForDfmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>(GetCompanyForDfmDocument, options);
        }
export function useGetCompanyForDfmSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>(GetCompanyForDfmDocument, options);
        }
export type GetCompanyForDfmQueryHookResult = ReturnType<typeof useGetCompanyForDfmQuery>;
export type GetCompanyForDfmLazyQueryHookResult = ReturnType<typeof useGetCompanyForDfmLazyQuery>;
export type GetCompanyForDfmSuspenseQueryHookResult = ReturnType<typeof useGetCompanyForDfmSuspenseQuery>;
export type GetCompanyForDfmQueryResult = Apollo.QueryResult<GetCompanyForDfmQuery, GetCompanyForDfmQueryVariables>;
export const GetCompanySizeDocument = gql`
    query getCompanySize($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        maxFte
        linkedinFte
        id
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetCompanySizeQuery__
 *
 * To run a query within a React component, call `useGetCompanySizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySizeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanySizeQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanySizeQuery, GetCompanySizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySizeQuery, GetCompanySizeQueryVariables>(GetCompanySizeDocument, options);
      }
export function useGetCompanySizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySizeQuery, GetCompanySizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySizeQuery, GetCompanySizeQueryVariables>(GetCompanySizeDocument, options);
        }
export function useGetCompanySizeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanySizeQuery, GetCompanySizeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanySizeQuery, GetCompanySizeQueryVariables>(GetCompanySizeDocument, options);
        }
export type GetCompanySizeQueryHookResult = ReturnType<typeof useGetCompanySizeQuery>;
export type GetCompanySizeLazyQueryHookResult = ReturnType<typeof useGetCompanySizeLazyQuery>;
export type GetCompanySizeSuspenseQueryHookResult = ReturnType<typeof useGetCompanySizeSuspenseQuery>;
export type GetCompanySizeQueryResult = Apollo.QueryResult<GetCompanySizeQuery, GetCompanySizeQueryVariables>;
export const GetCrawlerFindingTokensDocument = gql`
    query getCrawlerFindingTokens($companyId: Int) {
  getCrawlerFindingTokens(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        url
        fte
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetCrawlerFindingTokensQuery__
 *
 * To run a query within a React component, call `useGetCrawlerFindingTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrawlerFindingTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrawlerFindingTokensQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCrawlerFindingTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>(GetCrawlerFindingTokensDocument, options);
      }
export function useGetCrawlerFindingTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>(GetCrawlerFindingTokensDocument, options);
        }
export function useGetCrawlerFindingTokensSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>(GetCrawlerFindingTokensDocument, options);
        }
export type GetCrawlerFindingTokensQueryHookResult = ReturnType<typeof useGetCrawlerFindingTokensQuery>;
export type GetCrawlerFindingTokensLazyQueryHookResult = ReturnType<typeof useGetCrawlerFindingTokensLazyQuery>;
export type GetCrawlerFindingTokensSuspenseQueryHookResult = ReturnType<typeof useGetCrawlerFindingTokensSuspenseQuery>;
export type GetCrawlerFindingTokensQueryResult = Apollo.QueryResult<GetCrawlerFindingTokensQuery, GetCrawlerFindingTokensQueryVariables>;
export const GetCustomerReferencesDocument = gql`
    query getCustomerReferences($companyId: Int!) {
  getCustomerReferences(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        IAB1
        shortName
        crawlUrl
        findingUrl
        genericCustomer
        ISDd
        sicCode
        IAB2
        IAB3
        dbId
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetCustomerReferencesQuery__
 *
 * To run a query within a React component, call `useGetCustomerReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReferencesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCustomerReferencesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables> & ({ variables: GetCustomerReferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables>(GetCustomerReferencesDocument, options);
      }
export function useGetCustomerReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables>(GetCustomerReferencesDocument, options);
        }
export function useGetCustomerReferencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables>(GetCustomerReferencesDocument, options);
        }
export type GetCustomerReferencesQueryHookResult = ReturnType<typeof useGetCustomerReferencesQuery>;
export type GetCustomerReferencesLazyQueryHookResult = ReturnType<typeof useGetCustomerReferencesLazyQuery>;
export type GetCustomerReferencesSuspenseQueryHookResult = ReturnType<typeof useGetCustomerReferencesSuspenseQuery>;
export type GetCustomerReferencesQueryResult = Apollo.QueryResult<GetCustomerReferencesQuery, GetCustomerReferencesQueryVariables>;
export const GetGenericCrawlResultIdDocument = gql`
    query getGenericCrawlResultId($companyId: Int!) {
  getCrawlResults(companyId: $companyId) {
    edges {
      node {
        dbId
      }
    }
  }
}
    `;

/**
 * __useGetGenericCrawlResultIdQuery__
 *
 * To run a query within a React component, call `useGetGenericCrawlResultIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenericCrawlResultIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenericCrawlResultIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGenericCrawlResultIdQuery(baseOptions: Apollo.QueryHookOptions<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables> & ({ variables: GetGenericCrawlResultIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables>(GetGenericCrawlResultIdDocument, options);
      }
export function useGetGenericCrawlResultIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables>(GetGenericCrawlResultIdDocument, options);
        }
export function useGetGenericCrawlResultIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables>(GetGenericCrawlResultIdDocument, options);
        }
export type GetGenericCrawlResultIdQueryHookResult = ReturnType<typeof useGetGenericCrawlResultIdQuery>;
export type GetGenericCrawlResultIdLazyQueryHookResult = ReturnType<typeof useGetGenericCrawlResultIdLazyQuery>;
export type GetGenericCrawlResultIdSuspenseQueryHookResult = ReturnType<typeof useGetGenericCrawlResultIdSuspenseQuery>;
export type GetGenericCrawlResultIdQueryResult = Apollo.QueryResult<GetGenericCrawlResultIdQuery, GetGenericCrawlResultIdQueryVariables>;
export const GetKeywordsForCompanyDocument = gql`
    query getKeywordsForCompany($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        crawlResults {
          edges {
            node {
              dbId
              xoviKeywords {
                edges {
                  node {
                    dbId
                    id
                    keyword
                    searchEngine
                    position
                    cpc
                    sevo
                    competitorDensity
                    markedAsIrrelevant
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetKeywordsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetKeywordsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordsForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetKeywordsForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>(GetKeywordsForCompanyDocument, options);
      }
export function useGetKeywordsForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>(GetKeywordsForCompanyDocument, options);
        }
export function useGetKeywordsForCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>(GetKeywordsForCompanyDocument, options);
        }
export type GetKeywordsForCompanyQueryHookResult = ReturnType<typeof useGetKeywordsForCompanyQuery>;
export type GetKeywordsForCompanyLazyQueryHookResult = ReturnType<typeof useGetKeywordsForCompanyLazyQuery>;
export type GetKeywordsForCompanySuspenseQueryHookResult = ReturnType<typeof useGetKeywordsForCompanySuspenseQuery>;
export type GetKeywordsForCompanyQueryResult = Apollo.QueryResult<GetKeywordsForCompanyQuery, GetKeywordsForCompanyQueryVariables>;
export const GetOpenaiVotesDocument = gql`
    query getOpenaiVotes($companyId: Int) {
  getOpenaiVotes(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        softwareVotes
        serviceVotes
        undecidable
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetOpenaiVotesQuery__
 *
 * To run a query within a React component, call `useGetOpenaiVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenaiVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenaiVotesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOpenaiVotesQuery(baseOptions?: Apollo.QueryHookOptions<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>(GetOpenaiVotesDocument, options);
      }
export function useGetOpenaiVotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>(GetOpenaiVotesDocument, options);
        }
export function useGetOpenaiVotesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>(GetOpenaiVotesDocument, options);
        }
export type GetOpenaiVotesQueryHookResult = ReturnType<typeof useGetOpenaiVotesQuery>;
export type GetOpenaiVotesLazyQueryHookResult = ReturnType<typeof useGetOpenaiVotesLazyQuery>;
export type GetOpenaiVotesSuspenseQueryHookResult = ReturnType<typeof useGetOpenaiVotesSuspenseQuery>;
export type GetOpenaiVotesQueryResult = Apollo.QueryResult<GetOpenaiVotesQuery, GetOpenaiVotesQueryVariables>;
export const GetProductCategoriesForCompanyDocument = gql`
    query getProductCategoriesForCompany($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        productCategorization {
          edges {
            node {
              id
              dbId
              softwareCategory {
                subCategory1
                subCategory2
                subCategory3
                g2Url
              }
              softwareCategoryCertainty
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetProductCategoriesForCompanyQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetProductCategoriesForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>(GetProductCategoriesForCompanyDocument, options);
      }
export function useGetProductCategoriesForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>(GetProductCategoriesForCompanyDocument, options);
        }
export function useGetProductCategoriesForCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>(GetProductCategoriesForCompanyDocument, options);
        }
export type GetProductCategoriesForCompanyQueryHookResult = ReturnType<typeof useGetProductCategoriesForCompanyQuery>;
export type GetProductCategoriesForCompanyLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesForCompanyLazyQuery>;
export type GetProductCategoriesForCompanySuspenseQueryHookResult = ReturnType<typeof useGetProductCategoriesForCompanySuspenseQuery>;
export type GetProductCategoriesForCompanyQueryResult = Apollo.QueryResult<GetProductCategoriesForCompanyQuery, GetProductCategoriesForCompanyQueryVariables>;
export const GetProgrammingLanguagesDocument = gql`
    query getProgrammingLanguages($companyId: Int) {
  getProgrammingLanguages(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        dbId
        name
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetProgrammingLanguagesQuery__
 *
 * To run a query within a React component, call `useGetProgrammingLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgrammingLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgrammingLanguagesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetProgrammingLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>(GetProgrammingLanguagesDocument, options);
      }
export function useGetProgrammingLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>(GetProgrammingLanguagesDocument, options);
        }
export function useGetProgrammingLanguagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>(GetProgrammingLanguagesDocument, options);
        }
export type GetProgrammingLanguagesQueryHookResult = ReturnType<typeof useGetProgrammingLanguagesQuery>;
export type GetProgrammingLanguagesLazyQueryHookResult = ReturnType<typeof useGetProgrammingLanguagesLazyQuery>;
export type GetProgrammingLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetProgrammingLanguagesSuspenseQuery>;
export type GetProgrammingLanguagesQueryResult = Apollo.QueryResult<GetProgrammingLanguagesQuery, GetProgrammingLanguagesQueryVariables>;
export const GetResearchSoftwareCategoriesDocument = gql`
    query getResearchSoftwareCategories($subCategory1: String, $subCategory2: String, $subCategory3: String) {
  getSoftwareCategories(
    subCategory1: $subCategory1
    subCategory2: $subCategory2
    subCategory3: $subCategory3
  ) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        subCategory1
        subCategory2
        subCategory3
        categoryDescription
        googleTerm {
          googleTrends {
            interest
            date
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetResearchSoftwareCategoriesQuery__
 *
 * To run a query within a React component, call `useGetResearchSoftwareCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResearchSoftwareCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResearchSoftwareCategoriesQuery({
 *   variables: {
 *      subCategory1: // value for 'subCategory1'
 *      subCategory2: // value for 'subCategory2'
 *      subCategory3: // value for 'subCategory3'
 *   },
 * });
 */
export function useGetResearchSoftwareCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>(GetResearchSoftwareCategoriesDocument, options);
      }
export function useGetResearchSoftwareCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>(GetResearchSoftwareCategoriesDocument, options);
        }
export function useGetResearchSoftwareCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>(GetResearchSoftwareCategoriesDocument, options);
        }
export type GetResearchSoftwareCategoriesQueryHookResult = ReturnType<typeof useGetResearchSoftwareCategoriesQuery>;
export type GetResearchSoftwareCategoriesLazyQueryHookResult = ReturnType<typeof useGetResearchSoftwareCategoriesLazyQuery>;
export type GetResearchSoftwareCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetResearchSoftwareCategoriesSuspenseQuery>;
export type GetResearchSoftwareCategoriesQueryResult = Apollo.QueryResult<GetResearchSoftwareCategoriesQuery, GetResearchSoftwareCategoriesQueryVariables>;
export const GetShortCompanyDocument = gql`
    query getShortCompany($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        shortName
        uniqueDomain
        linkedinUrl
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetShortCompanyQuery__
 *
 * To run a query within a React component, call `useGetShortCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetShortCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetShortCompanyQuery, GetShortCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShortCompanyQuery, GetShortCompanyQueryVariables>(GetShortCompanyDocument, options);
      }
export function useGetShortCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShortCompanyQuery, GetShortCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShortCompanyQuery, GetShortCompanyQueryVariables>(GetShortCompanyDocument, options);
        }
export function useGetShortCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetShortCompanyQuery, GetShortCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShortCompanyQuery, GetShortCompanyQueryVariables>(GetShortCompanyDocument, options);
        }
export type GetShortCompanyQueryHookResult = ReturnType<typeof useGetShortCompanyQuery>;
export type GetShortCompanyLazyQueryHookResult = ReturnType<typeof useGetShortCompanyLazyQuery>;
export type GetShortCompanySuspenseQueryHookResult = ReturnType<typeof useGetShortCompanySuspenseQuery>;
export type GetShortCompanyQueryResult = Apollo.QueryResult<GetShortCompanyQuery, GetShortCompanyQueryVariables>;
export const GetSoftwareCategoriesDocument = gql`
    query getSoftwareCategories($name: String) {
  getSoftwareCategories(name: $name) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        subCategory1
        subCategory2
        subCategory3
        subCategory4
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetSoftwareCategoriesQuery__
 *
 * To run a query within a React component, call `useGetSoftwareCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoftwareCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoftwareCategoriesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetSoftwareCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>(GetSoftwareCategoriesDocument, options);
      }
export function useGetSoftwareCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>(GetSoftwareCategoriesDocument, options);
        }
export function useGetSoftwareCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>(GetSoftwareCategoriesDocument, options);
        }
export type GetSoftwareCategoriesQueryHookResult = ReturnType<typeof useGetSoftwareCategoriesQuery>;
export type GetSoftwareCategoriesLazyQueryHookResult = ReturnType<typeof useGetSoftwareCategoriesLazyQuery>;
export type GetSoftwareCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetSoftwareCategoriesSuspenseQuery>;
export type GetSoftwareCategoriesQueryResult = Apollo.QueryResult<GetSoftwareCategoriesQuery, GetSoftwareCategoriesQueryVariables>;
export const GetSoftwareFirmsDocument = gql`
    query getSoftwareFirms($first: Int, $last: Int, $searchTerm: String, $after: Int, $before: Int) {
  softwareFirms(
    first: $first
    last: $last
    searchTerm: $searchTerm
    after: $after
    before: $before
  ) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        shortName
        uniqueDomain
        country
        validated
        linkedinUrl
        foundingYear
        sicCode
        datetimeCreated
        datetimeModified
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetSoftwareFirmsQuery__
 *
 * To run a query within a React component, call `useGetSoftwareFirmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoftwareFirmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoftwareFirmsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchTerm: // value for 'searchTerm'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetSoftwareFirmsQuery(baseOptions?: Apollo.QueryHookOptions<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>(GetSoftwareFirmsDocument, options);
      }
export function useGetSoftwareFirmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>(GetSoftwareFirmsDocument, options);
        }
export function useGetSoftwareFirmsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>(GetSoftwareFirmsDocument, options);
        }
export type GetSoftwareFirmsQueryHookResult = ReturnType<typeof useGetSoftwareFirmsQuery>;
export type GetSoftwareFirmsLazyQueryHookResult = ReturnType<typeof useGetSoftwareFirmsLazyQuery>;
export type GetSoftwareFirmsSuspenseQueryHookResult = ReturnType<typeof useGetSoftwareFirmsSuspenseQuery>;
export type GetSoftwareFirmsQueryResult = Apollo.QueryResult<GetSoftwareFirmsQuery, GetSoftwareFirmsQueryVariables>;
export const GetStackshareToolCategoriesDocument = gql`
    query getStackshareToolCategories($first: Int) {
  getStackshareToolCategories(first: $first) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        breadcrumb
        id
        dbId
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetStackshareToolCategoriesQuery__
 *
 * To run a query within a React component, call `useGetStackshareToolCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStackshareToolCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStackshareToolCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetStackshareToolCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>(GetStackshareToolCategoriesDocument, options);
      }
export function useGetStackshareToolCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>(GetStackshareToolCategoriesDocument, options);
        }
export function useGetStackshareToolCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>(GetStackshareToolCategoriesDocument, options);
        }
export type GetStackshareToolCategoriesQueryHookResult = ReturnType<typeof useGetStackshareToolCategoriesQuery>;
export type GetStackshareToolCategoriesLazyQueryHookResult = ReturnType<typeof useGetStackshareToolCategoriesLazyQuery>;
export type GetStackshareToolCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetStackshareToolCategoriesSuspenseQuery>;
export type GetStackshareToolCategoriesQueryResult = Apollo.QueryResult<GetStackshareToolCategoriesQuery, GetStackshareToolCategoriesQueryVariables>;
export const GetStackshareToolsDocument = gql`
    query getStackshareTools($category: String!) {
  getStackshareTools(category: $category) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        name
        id
        dbId
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetStackshareToolsQuery__
 *
 * To run a query within a React component, call `useGetStackshareToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStackshareToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStackshareToolsQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetStackshareToolsQuery(baseOptions: Apollo.QueryHookOptions<GetStackshareToolsQuery, GetStackshareToolsQueryVariables> & ({ variables: GetStackshareToolsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStackshareToolsQuery, GetStackshareToolsQueryVariables>(GetStackshareToolsDocument, options);
      }
export function useGetStackshareToolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStackshareToolsQuery, GetStackshareToolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStackshareToolsQuery, GetStackshareToolsQueryVariables>(GetStackshareToolsDocument, options);
        }
export function useGetStackshareToolsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStackshareToolsQuery, GetStackshareToolsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStackshareToolsQuery, GetStackshareToolsQueryVariables>(GetStackshareToolsDocument, options);
        }
export type GetStackshareToolsQueryHookResult = ReturnType<typeof useGetStackshareToolsQuery>;
export type GetStackshareToolsLazyQueryHookResult = ReturnType<typeof useGetStackshareToolsLazyQuery>;
export type GetStackshareToolsSuspenseQueryHookResult = ReturnType<typeof useGetStackshareToolsSuspenseQuery>;
export type GetStackshareToolsQueryResult = Apollo.QueryResult<GetStackshareToolsQuery, GetStackshareToolsQueryVariables>;
export const GetTechnologyUseDocument = gql`
    query getTechnologyUse($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        crawlUrl
        techUse {
          edges {
            node {
              dbId
              findingUrl
              stackshareTool {
                id
                breadcrumb
                name
                stacksUsed
                votes
                url
              }
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useGetTechnologyUseQuery__
 *
 * To run a query within a React component, call `useGetTechnologyUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologyUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologyUseQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetTechnologyUseQuery(baseOptions?: Apollo.QueryHookOptions<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>(GetTechnologyUseDocument, options);
      }
export function useGetTechnologyUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>(GetTechnologyUseDocument, options);
        }
export function useGetTechnologyUseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>(GetTechnologyUseDocument, options);
        }
export type GetTechnologyUseQueryHookResult = ReturnType<typeof useGetTechnologyUseQuery>;
export type GetTechnologyUseLazyQueryHookResult = ReturnType<typeof useGetTechnologyUseLazyQuery>;
export type GetTechnologyUseSuspenseQueryHookResult = ReturnType<typeof useGetTechnologyUseSuspenseQuery>;
export type GetTechnologyUseQueryResult = Apollo.QueryResult<GetTechnologyUseQuery, GetTechnologyUseQueryVariables>;
export const GetUploadedMetaDataDocument = gql`
    query getUploadedMetaData($pipedriveId: Int!) {
  uploadedMetaDataByPipedriveId(pipedriveId: $pipedriveId) {
    company {
      dbId
      shortName
      uniqueDomain
    }
    uploadTime
    uploaderName
  }
}
    `;

/**
 * __useGetUploadedMetaDataQuery__
 *
 * To run a query within a React component, call `useGetUploadedMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedMetaDataQuery({
 *   variables: {
 *      pipedriveId: // value for 'pipedriveId'
 *   },
 * });
 */
export function useGetUploadedMetaDataQuery(baseOptions: Apollo.QueryHookOptions<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables> & ({ variables: GetUploadedMetaDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables>(GetUploadedMetaDataDocument, options);
      }
export function useGetUploadedMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables>(GetUploadedMetaDataDocument, options);
        }
export function useGetUploadedMetaDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables>(GetUploadedMetaDataDocument, options);
        }
export type GetUploadedMetaDataQueryHookResult = ReturnType<typeof useGetUploadedMetaDataQuery>;
export type GetUploadedMetaDataLazyQueryHookResult = ReturnType<typeof useGetUploadedMetaDataLazyQuery>;
export type GetUploadedMetaDataSuspenseQueryHookResult = ReturnType<typeof useGetUploadedMetaDataSuspenseQuery>;
export type GetUploadedMetaDataQueryResult = Apollo.QueryResult<GetUploadedMetaDataQuery, GetUploadedMetaDataQueryVariables>;
export const OpenregCompaniesDocument = gql`
    query openregCompanies($companyId: Int!) {
  getCompany(companyId: $companyId) {
    edges {
      node {
        legalName
        street
        city
        openregCompanies {
          edges {
            node {
              companyName
              addressStreet
              addressPlace
              addressZipcode
              referenceCourtCode
              referenceCourtName
              foundedDate
              dbId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOpenregCompaniesQuery__
 *
 * To run a query within a React component, call `useOpenregCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenregCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenregCompaniesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOpenregCompaniesQuery(baseOptions: Apollo.QueryHookOptions<OpenregCompaniesQuery, OpenregCompaniesQueryVariables> & ({ variables: OpenregCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenregCompaniesQuery, OpenregCompaniesQueryVariables>(OpenregCompaniesDocument, options);
      }
export function useOpenregCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenregCompaniesQuery, OpenregCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenregCompaniesQuery, OpenregCompaniesQueryVariables>(OpenregCompaniesDocument, options);
        }
export function useOpenregCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OpenregCompaniesQuery, OpenregCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OpenregCompaniesQuery, OpenregCompaniesQueryVariables>(OpenregCompaniesDocument, options);
        }
export type OpenregCompaniesQueryHookResult = ReturnType<typeof useOpenregCompaniesQuery>;
export type OpenregCompaniesLazyQueryHookResult = ReturnType<typeof useOpenregCompaniesLazyQuery>;
export type OpenregCompaniesSuspenseQueryHookResult = ReturnType<typeof useOpenregCompaniesSuspenseQuery>;
export type OpenregCompaniesQueryResult = Apollo.QueryResult<OpenregCompaniesQuery, OpenregCompaniesQueryVariables>;
export const PrecalculatedKpisDocument = gql`
    query precalculatedKpis($companyId: Int!) {
  companyAssesment(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      node {
        precalculatedKpis {
          edges {
            node {
              id
              softwareShare
              salesGrowth
              absEbit
              recurringRevenueShare
              cogsRatio
              top3CustomerShare
              netNewCustomerSalesGrowth
              customerLifetimeValueDistribution
              cLevelQuality
              softwareCategoryGrowth
              onlineVisibility
              pricePerGoogleKeywordClick
              consultingManRate
              mostExpensiveKeyword
              customerIndustryGrowth
              employeeConcentrationRatio
              teamQuality
              ageOfProgrammingLanguage
              popularityOfProgrammingLanguage
              useOfBackendFramework
              useOfFrontendFramework
              buyAndBuildScore
              callabilityScore
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __usePrecalculatedKpisQuery__
 *
 * To run a query within a React component, call `usePrecalculatedKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrecalculatedKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrecalculatedKpisQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePrecalculatedKpisQuery(baseOptions: Apollo.QueryHookOptions<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables> & ({ variables: PrecalculatedKpisQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables>(PrecalculatedKpisDocument, options);
      }
export function usePrecalculatedKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables>(PrecalculatedKpisDocument, options);
        }
export function usePrecalculatedKpisSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables>(PrecalculatedKpisDocument, options);
        }
export type PrecalculatedKpisQueryHookResult = ReturnType<typeof usePrecalculatedKpisQuery>;
export type PrecalculatedKpisLazyQueryHookResult = ReturnType<typeof usePrecalculatedKpisLazyQuery>;
export type PrecalculatedKpisSuspenseQueryHookResult = ReturnType<typeof usePrecalculatedKpisSuspenseQuery>;
export type PrecalculatedKpisQueryResult = Apollo.QueryResult<PrecalculatedKpisQuery, PrecalculatedKpisQueryVariables>;
export const ProductCategoryListDocument = gql`
    query productCategoryList($first: Int) {
  productCategoryList(first: $first) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        subCategory1
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useProductCategoryListQuery__
 *
 * To run a query within a React component, call `useProductCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoryListQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProductCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<ProductCategoryListQuery, ProductCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCategoryListQuery, ProductCategoryListQueryVariables>(ProductCategoryListDocument, options);
      }
export function useProductCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoryListQuery, ProductCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCategoryListQuery, ProductCategoryListQueryVariables>(ProductCategoryListDocument, options);
        }
export function useProductCategoryListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductCategoryListQuery, ProductCategoryListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductCategoryListQuery, ProductCategoryListQueryVariables>(ProductCategoryListDocument, options);
        }
export type ProductCategoryListQueryHookResult = ReturnType<typeof useProductCategoryListQuery>;
export type ProductCategoryListLazyQueryHookResult = ReturnType<typeof useProductCategoryListLazyQuery>;
export type ProductCategoryListSuspenseQueryHookResult = ReturnType<typeof useProductCategoryListSuspenseQuery>;
export type ProductCategoryListQueryResult = Apollo.QueryResult<ProductCategoryListQuery, ProductCategoryListQueryVariables>;
export const ProductSubCategory3ListDocument = gql`
    query productSubCategory3List($subCategory2: String) {
  productCategoryList(subCategory2: $subCategory2) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        subCategory3
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useProductSubCategory3ListQuery__
 *
 * To run a query within a React component, call `useProductSubCategory3ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSubCategory3ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSubCategory3ListQuery({
 *   variables: {
 *      subCategory2: // value for 'subCategory2'
 *   },
 * });
 */
export function useProductSubCategory3ListQuery(baseOptions?: Apollo.QueryHookOptions<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>(ProductSubCategory3ListDocument, options);
      }
export function useProductSubCategory3ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>(ProductSubCategory3ListDocument, options);
        }
export function useProductSubCategory3ListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>(ProductSubCategory3ListDocument, options);
        }
export type ProductSubCategory3ListQueryHookResult = ReturnType<typeof useProductSubCategory3ListQuery>;
export type ProductSubCategory3ListLazyQueryHookResult = ReturnType<typeof useProductSubCategory3ListLazyQuery>;
export type ProductSubCategory3ListSuspenseQueryHookResult = ReturnType<typeof useProductSubCategory3ListSuspenseQuery>;
export type ProductSubCategory3ListQueryResult = Apollo.QueryResult<ProductSubCategory3ListQuery, ProductSubCategory3ListQueryVariables>;
export const ProductSubCategoryListDocument = gql`
    query productSubCategoryList($subCategory1: String) {
  productCategoryList(subCategory1: $subCategory1) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        id
        dbId
        subCategory2
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useProductSubCategoryListQuery__
 *
 * To run a query within a React component, call `useProductSubCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSubCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSubCategoryListQuery({
 *   variables: {
 *      subCategory1: // value for 'subCategory1'
 *   },
 * });
 */
export function useProductSubCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>(ProductSubCategoryListDocument, options);
      }
export function useProductSubCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>(ProductSubCategoryListDocument, options);
        }
export function useProductSubCategoryListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>(ProductSubCategoryListDocument, options);
        }
export type ProductSubCategoryListQueryHookResult = ReturnType<typeof useProductSubCategoryListQuery>;
export type ProductSubCategoryListLazyQueryHookResult = ReturnType<typeof useProductSubCategoryListLazyQuery>;
export type ProductSubCategoryListSuspenseQueryHookResult = ReturnType<typeof useProductSubCategoryListSuspenseQuery>;
export type ProductSubCategoryListQueryResult = Apollo.QueryResult<ProductSubCategoryListQuery, ProductSubCategoryListQueryVariables>;
export const SearchCompanyDocument = gql`
    query searchCompany($searchTerm: String, $first: Int, $after: Int, $before: Int) {
  cortexFirms(
    searchTerm: $searchTerm
    first: $first
    after: $after
    before: $before
  ) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      node {
        dbId
        shortName
        uniqueDomain
        country
        validated
        isSoftwareCompany
        sicCode
        doNotCrawl
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useSearchCompanyQuery__
 *
 * To run a query within a React component, call `useSearchCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanyQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchCompanyQuery(baseOptions?: Apollo.QueryHookOptions<SearchCompanyQuery, SearchCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(SearchCompanyDocument, options);
      }
export function useSearchCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCompanyQuery, SearchCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(SearchCompanyDocument, options);
        }
export function useSearchCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCompanyQuery, SearchCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(SearchCompanyDocument, options);
        }
export type SearchCompanyQueryHookResult = ReturnType<typeof useSearchCompanyQuery>;
export type SearchCompanyLazyQueryHookResult = ReturnType<typeof useSearchCompanyLazyQuery>;
export type SearchCompanySuspenseQueryHookResult = ReturnType<typeof useSearchCompanySuspenseQuery>;
export type SearchCompanyQueryResult = Apollo.QueryResult<SearchCompanyQuery, SearchCompanyQueryVariables>;
export const SearchCompanyForEvaluationDocument = gql`
    query searchCompanyForEvaluation($searchTerm: String, $first: Int, $after: Int, $before: Int) {
  cortexFirms(
    searchTerm: $searchTerm
    first: $first
    after: $after
    before: $before
  ) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      node {
        dbId
        shortName
        uniqueDomain
        country
        validated
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useSearchCompanyForEvaluationQuery__
 *
 * To run a query within a React component, call `useSearchCompanyForEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanyForEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanyForEvaluationQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchCompanyForEvaluationQuery(baseOptions?: Apollo.QueryHookOptions<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>(SearchCompanyForEvaluationDocument, options);
      }
export function useSearchCompanyForEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>(SearchCompanyForEvaluationDocument, options);
        }
export function useSearchCompanyForEvaluationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>(SearchCompanyForEvaluationDocument, options);
        }
export type SearchCompanyForEvaluationQueryHookResult = ReturnType<typeof useSearchCompanyForEvaluationQuery>;
export type SearchCompanyForEvaluationLazyQueryHookResult = ReturnType<typeof useSearchCompanyForEvaluationLazyQuery>;
export type SearchCompanyForEvaluationSuspenseQueryHookResult = ReturnType<typeof useSearchCompanyForEvaluationSuspenseQuery>;
export type SearchCompanyForEvaluationQueryResult = Apollo.QueryResult<SearchCompanyForEvaluationQuery, SearchCompanyForEvaluationQueryVariables>;
export const SoftwareCategoryGrowthRatesDocument = gql`
    query softwareCategoryGrowthRates($companyId: Int) {
  softwareCategoryGrowthRates(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      cursor
      node {
        dbId
        term
        avgAlltimeInterest
        avgInterest24To12MonthsAgo
        avgInterestLast12Months
        interestGrowthRate
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useSoftwareCategoryGrowthRatesQuery__
 *
 * To run a query within a React component, call `useSoftwareCategoryGrowthRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareCategoryGrowthRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareCategoryGrowthRatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSoftwareCategoryGrowthRatesQuery(baseOptions?: Apollo.QueryHookOptions<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>(SoftwareCategoryGrowthRatesDocument, options);
      }
export function useSoftwareCategoryGrowthRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>(SoftwareCategoryGrowthRatesDocument, options);
        }
export function useSoftwareCategoryGrowthRatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>(SoftwareCategoryGrowthRatesDocument, options);
        }
export type SoftwareCategoryGrowthRatesQueryHookResult = ReturnType<typeof useSoftwareCategoryGrowthRatesQuery>;
export type SoftwareCategoryGrowthRatesLazyQueryHookResult = ReturnType<typeof useSoftwareCategoryGrowthRatesLazyQuery>;
export type SoftwareCategoryGrowthRatesSuspenseQueryHookResult = ReturnType<typeof useSoftwareCategoryGrowthRatesSuspenseQuery>;
export type SoftwareCategoryGrowthRatesQueryResult = Apollo.QueryResult<SoftwareCategoryGrowthRatesQuery, SoftwareCategoryGrowthRatesQueryVariables>;
export const SpecialCpcKeywordsDocument = gql`
    query specialCpcKeywords($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      node {
        crawlResults {
          edges {
            node {
              dbId
              xoviKeywords(cpc: -1) {
                edges {
                  node {
                    id
                    keyword
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useSpecialCpcKeywordsQuery__
 *
 * To run a query within a React component, call `useSpecialCpcKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialCpcKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialCpcKeywordsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSpecialCpcKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>(SpecialCpcKeywordsDocument, options);
      }
export function useSpecialCpcKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>(SpecialCpcKeywordsDocument, options);
        }
export function useSpecialCpcKeywordsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>(SpecialCpcKeywordsDocument, options);
        }
export type SpecialCpcKeywordsQueryHookResult = ReturnType<typeof useSpecialCpcKeywordsQuery>;
export type SpecialCpcKeywordsLazyQueryHookResult = ReturnType<typeof useSpecialCpcKeywordsLazyQuery>;
export type SpecialCpcKeywordsSuspenseQueryHookResult = ReturnType<typeof useSpecialCpcKeywordsSuspenseQuery>;
export type SpecialCpcKeywordsQueryResult = Apollo.QueryResult<SpecialCpcKeywordsQuery, SpecialCpcKeywordsQueryVariables>;
export const StatusMessageDocument = gql`
    query statusMessage($clientId: String!) {
  statusMessage(clientId: $clientId)
}
    `;

/**
 * __useStatusMessageQuery__
 *
 * To run a query within a React component, call `useStatusMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusMessageQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useStatusMessageQuery(baseOptions: Apollo.QueryHookOptions<StatusMessageQuery, StatusMessageQueryVariables> & ({ variables: StatusMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusMessageQuery, StatusMessageQueryVariables>(StatusMessageDocument, options);
      }
export function useStatusMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusMessageQuery, StatusMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusMessageQuery, StatusMessageQueryVariables>(StatusMessageDocument, options);
        }
export function useStatusMessageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StatusMessageQuery, StatusMessageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StatusMessageQuery, StatusMessageQueryVariables>(StatusMessageDocument, options);
        }
export type StatusMessageQueryHookResult = ReturnType<typeof useStatusMessageQuery>;
export type StatusMessageLazyQueryHookResult = ReturnType<typeof useStatusMessageLazyQuery>;
export type StatusMessageSuspenseQueryHookResult = ReturnType<typeof useStatusMessageSuspenseQuery>;
export type StatusMessageQueryResult = Apollo.QueryResult<StatusMessageQuery, StatusMessageQueryVariables>;
export const TopCpcKeywordDocument = gql`
    query topCpcKeyword($companyId: Int) {
  getCompany(companyId: $companyId) {
    pageInfo {
      ...DbIdPageInfoFragment
    }
    edges {
      node {
        crawlResults {
          edges {
            node {
              dbId
              xoviKeywords(orderBy: CPC_DESC) {
                edges {
                  node {
                    id
                    keyword
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DbIdPageInfoFragmentFragmentDoc}`;

/**
 * __useTopCpcKeywordQuery__
 *
 * To run a query within a React component, call `useTopCpcKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCpcKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCpcKeywordQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTopCpcKeywordQuery(baseOptions?: Apollo.QueryHookOptions<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>(TopCpcKeywordDocument, options);
      }
export function useTopCpcKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>(TopCpcKeywordDocument, options);
        }
export function useTopCpcKeywordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>(TopCpcKeywordDocument, options);
        }
export type TopCpcKeywordQueryHookResult = ReturnType<typeof useTopCpcKeywordQuery>;
export type TopCpcKeywordLazyQueryHookResult = ReturnType<typeof useTopCpcKeywordLazyQuery>;
export type TopCpcKeywordSuspenseQueryHookResult = ReturnType<typeof useTopCpcKeywordSuspenseQuery>;
export type TopCpcKeywordQueryResult = Apollo.QueryResult<TopCpcKeywordQuery, TopCpcKeywordQueryVariables>;