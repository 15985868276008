export const SIC_CODES_LIST =
  'https://onsdigital.github.io/dp-classification-tools/standard-industrial-classification/ONS_SIC_hierarchy_view.html';

export const PD_URL = 'https://bid.pipedrive.com/organization/';

export const FLAG_URL = (country: string) => `https://flagsapi.com/${country}/shiny/32.png`;

export const POLL_NOTIFICATIONS_INTERVAL = 30000;

export const REGISTRY_LINKS = {
  DE: 'https://www.handelsregister.de/rp_web/welcome.xhtml',
  FR: 'https://www.pappers.fr/',
  ES: 'https://www.rmc.es/Home.aspx',
  UK: 'https://www.gov.uk/government/organisations/companies-house',
  IT: 'https://italianbusinessregister.it/en/',
  PT: 'https://registo.justica.gov.pt/Empresas/Pesquisar-nomes-firmas-ou-denominacoes-existentes/Iniciar',
  CH: 'https://www.zefix.ch/de/search/entity/welcome',
  AT: 'https://justizonline.gv.at/jop/web/firmenbuchabfrage',
};
