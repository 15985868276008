import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import { REGISTRY_LINKS } from '../../../../contants';
import {
  CompanyType,
  GetAllShareholdingsDocument,
  useCreateUpdateGenericShareholderMutation,
  useCreateUpdateGenericShareholdingMutation,
  useDeleteGenericShareholdingMutation,
  useGetAllShareholdersQuery,
  useGetAllShareholdingsQuery,
  useGetCompanyQuery,
} from '../../../../gql/generated/graphql';
import { getFirstNode } from '../../../../gql/helpers';
import { handleVisitPage } from '../../../../helpers/helpers';

import AddShareholderDialog from './components/AddShareholderDialog';
import AddShareholding from './components/AddShareholding';
import EditShareholding from './components/EditShareholding';
import ShareholdingFileUpload from './components/ShareholdingFileUpload';

import { validateShareholdingStyles } from './styles';

type Props = {
  companyId: number;
};

const ValidateShareholdingManagement = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [shareholderId, setShareholderId] = useState<number | null>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [edittingFields, setEdittingFields] = useState(null);

  const { data: companyData } = useGetCompanyQuery({
    variables: { companyId },
  });

  const { data: shareholdingsData, loading: loadingShareholders } = useGetAllShareholdingsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const { data: sharholdersData, refetch: refetchShareholders } = useGetAllShareholdersQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const company: CompanyType = getFirstNode(companyData?.getCompany);

  const shareholdingRows =
    shareholdingsData?.getCompany?.edges?.[0]?.node?.genericShareholdings?.edges?.map(
      (shareholder) => ({
        genericCompanyId: shareholder?.node?.genericCompanyId,
        genericShareholderId: shareholder?.node?.genericShareholderId,
        holding: shareholder?.node?.holding,
        holdingCount: shareholder?.node?.holdingCount,
        genericShareholdingId: shareholder?.node?.dbId,
        ...shareholder?.node?.genericShareholder,
      }),
    );
  const shareholders = sharholdersData?.getCompany?.edges?.[0]?.node?.shareholders?.edges?.map(
    (node) => node?.node,
  );

  const [deleteShareholding, { loading: deleteShareholdingLoading }] =
    useDeleteGenericShareholdingMutation();
  const [createUpdateGenericShareholder, { loading: createUpdateGenericShareholderLoading }] =
    useCreateUpdateGenericShareholderMutation();
  const [createUpdateGenericShareholding, { loading: createUpdateGenericShareholdingLoading }] =
    useCreateUpdateGenericShareholdingMutation();

  const shareholdingsColumns = [
    {
      field: 'name',
      width: 200,
      headerName: t('validation.validateShareholdingManagement.shareholdingsColumnsNameLabel'),
    },
    {
      field: 'holding',
      width: 100,
      headerName: t('validation.validateShareholdingManagement.shareholdingsColumnsHoldingLabel'),
    },
    {
      field: 'holdingCount',
      width: 100,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdingsColumnsHoldingCountLabel',
      ),
    },
    {
      field: 'yearBornOrFounded',
      width: 150,
      headerName: 'Year Born/Founded',
    },
    {
      field: 'isPe',
      width: 80,
      headerName: t('validation.validateShareholdingManagement.shareholdingsColumnsIsPeLabel'),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'isTradeBuyer',
      width: 150,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdingsColumnsIsTradeBuyerLabel',
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'isNaturalPerson',
      width: 150,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdingsColumnsIsNaturalPersonLabel',
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton size="small" onClick={() => handleOpenEditDialog(params.row)}>
            <Tooltip
              title={t('validation.validateShareholdingManagement.shareholdingColumnsEditTooltip')}
            >
              <EditOutlinedIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDeleteShareholding(params.row.genericShareholdingId)}
          >
            <Tooltip
              title={t(
                'validation.validateShareholdingManagement.shareholdingColumnsDeleteTooltip',
              )}
            >
              <DeleteOutlineIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleAddShareholder = async (formData: {
    name: string;
    typeOfInvestor?: string;
    yearBornOrFounded?: number;
  }) => {
    try {
      const variables: Record<string, any> = {
        genericCompanyId: companyId,
        name: formData.name,
      };

      if (formData.typeOfInvestor) {
        variables.typeOfInvestor = formData.typeOfInvestor;
      }

      if (formData.yearBornOrFounded) {
        variables.yearBornOrFounded = formData.yearBornOrFounded;
      }
      const result = await createUpdateGenericShareholder({
        variables,
      });

      if (result?.data?.createUpdateGenericShareholder?.name) {
        await refetchShareholders();
      }
    } finally {
      handleCloseDialog();
    }
  };

  const handleAddShareholding = async (holding: number, holdingCount: number) => {
    if (!shareholderId) return;

    try {
      const result = await createUpdateGenericShareholding({
        variables: {
          genericCompanyId: companyId,
          genericShareholderId: shareholderId,
          holding,
          holdingCount,
        },
        refetchQueries: [
          {
            query: GetAllShareholdingsDocument,
            variables: { companyId },
          },
        ],
      });
      if (result?.data?.createUpdateGenericShareholding) {
        setShareholderId(null);
      }
    } catch {}
  };

  const handleDeleteShareholding = async (genericShareholdingId: number) => {
    await deleteShareholding({
      variables: { genericShareholdingId },
      refetchQueries: [
        {
          query: GetAllShareholdingsDocument,
          variables: { companyId },
        },
      ],
    });
  };

  const handleEditHolding = async (data: {
    holding: number | null;
    holdingCount: number | null;
  }) => {
    await createUpdateGenericShareholding({
      variables: {
        genericCompanyId: companyId,
        genericShareholderId: edittingFields?.genericShareholderId,
        genericShareholdingId: edittingFields?.genericShareholdingId,
        holding: data.holding,
        holdingCount: data.holdingCount,
      },
      refetchQueries: [
        {
          query: GetAllShareholdingsDocument,
          variables: { companyId },
        },
      ],
    });
  };

  const handleEditHolder = async (data: {
    name: string | null;
    typeOfInvestor: string | null;
    yearBornOrFounded: number | null;
  }) => {
    await createUpdateGenericShareholder({
      variables: {
        genericCompanyId: companyId,
        genericShareholderId: edittingFields?.genericShareholderId,
        name: data.name,
        typeOfInvestor: data.typeOfInvestor,
        yearBornOrFounded: data.yearBornOrFounded,
      },
    });
  };

  const handleOpenEditDialog = (row) => {
    setIsOpenEditDialog(true);
    setEdittingFields({
      genericShareholderId: row?.genericShareholderId,
      genericCompanyId: row?.genericCompanyId,
      genericShareholdingId: row?.genericShareholdingId,
      yearBornOrFounded: row?.yearBornOrFounded,
      typeOfInvestor: row?.typeOfInvestor,
      name: row?.name,
      holding: row?.holding,
      holdingCount: row?.holdingCount,
    });
  };

  const handleCloseEditDialog = () => {
    setIsOpenEditDialog(false);
    setEdittingFields(null);
  };

  const handleOpenRegistry = () => {
    const registryLink = REGISTRY_LINKS[company?.country];
    handleVisitPage(registryLink);
  };

  const showBackdrop =
    deleteShareholdingLoading ||
    createUpdateGenericShareholdingLoading ||
    createUpdateGenericShareholderLoading;

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box mb={2}>
        <Typography variant="body1">{`${t('validation.validateShareholdingManagement.shareholdingCompanyName')}: ${company?.legalName}`}</Typography>
        <Typography variant="body1">{`${t('validation.validateShareholdingManagement.shareholdingCompanyStreet')}: ${company?.street}`}</Typography>
        <Typography variant="body1">{`${t('validation.validateShareholdingManagement.shareholdingCompanyCity')}: ${company?.city}`}</Typography>
        <Typography variant="body1">{`${t('validation.validateShareholdingManagement.shareholdingCompanyHrbNumber')}: ${company?.hrbNumber}`}</Typography>
        <Button
          sx={validateShareholdingStyles.publicRegisterButton}
          variant="contained"
          onClick={handleOpenRegistry}
        >
          {t('validation.validateShareholdingManagement.shareholdingOpenPublicRegisterButton')}
        </Button>
      </Box>
      <DataGrid
        disableRowSelectionOnClick
        rows={shareholdingRows || []}
        loading={loadingShareholders}
        columns={shareholdingsColumns}
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 80]}
        getRowId={(row) => row?.dbId}
      />
      <Button
        sx={validateShareholdingStyles.addShareholderButton}
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
      >
        {t('validation.validateShareholdingManagement.shareholdingAddShareholderButton')}
      </Button>
      <AddShareholderDialog
        open={isOpenDialog}
        onClose={handleCloseDialog}
        onAdd={handleAddShareholder}
      />
      <AddShareholding
        shareholders={shareholders}
        shareholderId={shareholderId}
        onSubmit={handleAddShareholding}
        onSetShareholderId={setShareholderId}
      />
      {isOpenEditDialog && (
        <EditShareholding
          open={isOpenEditDialog}
          onClose={handleCloseEditDialog}
          onEditHolding={handleEditHolding}
          onEditDetails={handleEditHolder}
          fields={edittingFields}
        />
      )}
      {/* TODO: commented on while testing the fileupload */}
      {/* <UploaderComponent
        autoUpload={false}
        allowedExtensions=".pdf"
        asyncSettings={{
          saveUrl: `${hostUrl}/${companyId}/upload`,
        }}
      /> */}
      <Box mt={4}>
        <ShareholdingFileUpload companyId={companyId} />
      </Box>
      <CustomBackdrop open={showBackdrop} />
    </Box>
  );
};

export default ValidateShareholdingManagement;
