import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, TextField, Typography } from '@mui/material';

import { validateShareholdingStyles } from '../styles';

import ShaderholdersPicker from './ShaderholdersPicker';

const AddShareholding = ({ shareholders, shareholderId, onSetShareholderId, onSubmit }) => {
  const { t } = useTranslation();
  const [holding, setHolding] = useState<number | null>(null);
  const [holdingCount, setHoldingCount] = useState<number | null>(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      onSubmit(holding, holdingCount);
      handleReset();
    } catch {}
  };

  const handleReset = () => {
    setHolding(null);
    setHoldingCount(null);
  };

  return (
    <Box mt={6} gap={2} display="flex" flexDirection="column">
      <Typography fontWeight="500">
        {t('validation.validateShareholdingManagement.shareholdingAddShareholdingTitle')}
      </Typography>
      <ShaderholdersPicker
        id={shareholderId}
        items={shareholders || []}
        onSelect={onSetShareholderId}
      />
      <Box onSubmit={handleSubmit} component="form" display="flex" flexDirection="column" gap={2}>
        <TextField
          label={t(
            'validation.validateShareholdingManagement.shareholdingAddShareholdingInputHoldingLabel',
          )}
          type="number"
          value={holding !== null ? holding : ''}
          onChange={(e) => {
            const value = e.target.value;
            setHolding(value === '' ? null : Number(value));
          }}
          fullWidth
          size="small"
          required
        />
        <TextField
          label={t(
            'validation.validateShareholdingManagement.shareholdingAddShareholdingInputHoldingCountLabel',
          )}
          type="number"
          value={holdingCount !== null ? holdingCount : ''}
          onChange={(e) => {
            const value = e.target.value;
            setHoldingCount(value === '' ? null : Number(value));
          }}
          fullWidth
          size="small"
          required
        />
        <Button
          sx={validateShareholdingStyles.addShareHoldingAddButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={!holding || !holdingCount || !shareholderId}
        >
          {t('validation.validateShareholdingManagement.shareholdingAddShareholdingAddButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddShareholding;
