import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Button, Chip, Tab, Tabs, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import SearchComponent from '../../components/Search/Search';
import { FLAG_URL } from '../../contants';
import {
  BuyAndBuildListDocument,
  CompanyAssesmentEdge,
  CompanyAssessmentType,
  useAddBuyAndBuildTargetsFromSourcescrubMutation,
  useGetCompanyForDfmLazyQuery,
  useGetSoftwareFirmsLazyQuery,
  useMarkCompanyAsNotSoftwareMutation,
  useTransferBuyAndBuildTargetsToPipedriveMutation,
} from '../../gql/generated/graphql';
import { getFirstNode } from '../../gql/helpers';
import { useCompanyNavigation } from '../../hooks/useCompanyNavigation';

import CompanyHeader from './../DFMPage/components/CompanyHeader/CompanyHeader';
import CustomPaper from './../DFMPage/components/CustomPaper/CustomPaper';
import { BBLonglist } from './BBLonglist';
import { BBShortlist } from './BBShortlist';

import styles from './styles';

export const MUI_RATING_SCALE = 5;

const BuyBuildPage = () => {
  const { t } = useTranslation();
  const { handleValidate, handleNavigateToAssesment } = useCompanyNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [options, setOptions] = useState<CompanyAssesmentEdge[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState(0);
  const [company, setCompany] = useState<CompanyAssessmentType | null>(null);

  const companyParamsId = searchParams.get('companyId');

  const [getCompanyForDFM, { data: companyForDFM }] = useGetCompanyForDfmLazyQuery();

  const [markCompanyAsNotSoftware, { loading: markCompanyAsNotSoftwareLoading }] =
    useMarkCompanyAsNotSoftwareMutation();
  const [loadFromSourcescrub, { loading: loadFromSourcescrubLoading }] =
    useAddBuyAndBuildTargetsFromSourcescrubMutation();
  const [transferToPipedrive, { loading: transferToPipedriveLoading }] =
    useTransferBuyAndBuildTargetsToPipedriveMutation();

  useEffect(() => {
    let variables;
    if (companyParamsId) {
      variables = {
        variables: {
          companyId: companyParamsId,
        },
      };
    } else {
      if (!company) return;

      variables = {
        variables: {
          companyId: company?.dbId,
        },
      };
    }
    getCompanyForDFM(variables);
  }, [company]);

  const companyForDFMNode = getFirstNode(companyForDFM?.getCompany);
  const companyName =
    (companyForDFMNode?.shortName !== '<unnamed reference customer>' &&
      companyForDFMNode?.shortName) ||
    companyForDFMNode?.legalName ||
    companyForDFMNode?.uniqueDomain;

  const companyAnalyses = companyForDFMNode?.companyAnalyses?.edges?.[0]?.node;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    search({ variables: { searchTerm: value } });
  }, 500);

  const [search, { loading }] = useGetSoftwareFirmsLazyQuery({
    onCompleted: (data) => {
      setOptions(data.softwareFirms.edges);
    },
  });

  const handleOptionSelect = (_, value: CompanyAssesmentEdge | null) => {
    if (value === null) return;
    setCompany(value ? value.node : null);
    setSearchParams({ companyId: value?.node?.dbId?.toString() });
  };

  const handleInputChange = (_, value: string, reason: string) => {
    if (reason === 'clear') {
      handleReset();
      search();
      return;
    }

    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };
  const handleReset = () => {
    setSearchTerm('');
    setOptions([]);
    setCompany(null);
  };

  const handleMarkAsNotSoftware = (id: number) => {
    markCompanyAsNotSoftware({ variables: { companyId: id } });
  };

  const handleLoadFromSourcescrub = () => {
    if (!companyParamsId) return;

    loadFromSourcescrub({
      variables: {
        softwareCompanyId: parseInt(companyParamsId),
      },
      refetchQueries: [
        {
          query: BuyAndBuildListDocument,
          variables: { companyId: parseInt(companyParamsId), first: 10 },
        },
      ],
    });
  };

  const handleTransferToPipedrive = () => {
    if (!companyParamsId) return;

    const variables: { companyId: number; first?: number; isShortlisted?: boolean } = {
      companyId: parseInt(companyParamsId),
      first: 10,
    };

    if (value === 1) {
      variables.isShortlisted = true;
    }

    transferToPipedrive({
      variables: {
        softwareCompanyId: parseInt(companyParamsId),
      },
      refetchQueries: [
        {
          query: BuyAndBuildListDocument,
          variables,
        },
      ],
    });
  };

  const isCompany = companyParamsId && companyForDFMNode;

  return (
    <Box gap={2} display="flex" flexDirection="column">
      <SearchComponent
        options={options}
        loading={loading}
        searchTerm={searchTerm}
        onInputChange={handleInputChange}
        onOptionSelect={handleOptionSelect}
        onReset={handleReset}
      />
      {isCompany ? (
        <CompanyHeader companyName={companyName} isRelevant={companyForDFMNode?.isRelevant} />
      ) : (
        <Typography variant="h6">
          {t('buyAndBuild.noCompanyText')} {companyName}
        </Typography>
      )}
      <CustomPaper>
        <Box>
          <Box
            gap={2}
            display="flex"
            alignItems={{ xs: 'flex-start', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box
              display="flex"
              alignItems={{ xs: 'flex-start', md: 'center' }}
              flexDirection="column"
            >
              {companyForDFMNode?.country && (
                <img
                  src={FLAG_URL(companyForDFMNode?.country)}
                  width={64}
                  height={64}
                  alt="Company country flag"
                />
              )}
              <Box
                display="flex"
                alignItems={{ xs: 'flex-start', md: 'center' }}
                justifyContent="center"
                flexDirection="column"
              >
                <Typography fontWeight="medium">{t('assessment.fte')}</Typography>
                <Typography>{companyAnalyses?.fte}</Typography>
              </Box>
              <Typography mt={2} variant="h5">
                {companyForDFMNode?.legalName || companyName}
              </Typography>
            </Box>
          </Box>
          <Typography>
            {companyForDFMNode?.street} {companyForDFMNode?.zipCode} {companyForDFMNode?.city}
          </Typography>
        </Box>
        <Chip
          color={companyForDFMNode?.validated ? 'success' : 'primary'}
          label={
            companyForDFMNode?.validated ? t('assessment.validated') : t('assessment.notValidated')
          }
        />
      </CustomPaper>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={t('buyAndBuild.longlistTab')} />
        <Tab label={t('buyAndBuild.shortlistTab')} />
      </Tabs>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="row" gap={2}>
          <Button
            sx={styles.sourcescrubButton}
            variant="contained"
            onClick={handleLoadFromSourcescrub}
            disabled={!companyParamsId}
          >
            From
            <img src="/sourcescrub.png" alt="Load from Sourcescrub" width={19} height={19} />
          </Button>
          <Button
            sx={styles.sourcescrubButton}
            variant="contained"
            onClick={handleTransferToPipedrive}
            disabled={!companyParamsId}
          >
            To
            <img src="/pipedrive.png" alt="Pipedrive" width={19} height={19} />
          </Button>
        </Box>
        {value === 0 && (
          <BBLonglist
            companyId={companyParamsId}
            handleValidate={handleValidate}
            handleMarkAsNotSoftware={handleMarkAsNotSoftware}
            handleNavigateToAssesment={handleNavigateToAssesment}
            markCompanyAsNotSoftwareLoading={markCompanyAsNotSoftwareLoading}
          />
        )}
        {value === 1 && (
          <BBShortlist
            companyId={companyParamsId}
            handleValidate={handleValidate}
            handleMarkAsNotSoftware={handleMarkAsNotSoftware}
            handleNavigateToAssesment={handleNavigateToAssesment}
            markCompanyAsNotSoftwareLoading={markCompanyAsNotSoftwareLoading}
          />
        )}
      </Box>
      <Box>
        <Typography variant="h6">
          <a
            href={`http://sourcing.bidequity.ai/crawler/search/get_buy_and_build_targets_for_company?company_id=${companyParamsId}`}
          >
            Download ML B&B List
          </a>
        </Typography>
      </Box>
      <CustomBackdrop open={loadFromSourcescrubLoading || transferToPipedriveLoading} />
    </Box>
  );
};

export default BuyBuildPage;
