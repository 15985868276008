import { useState } from 'react';

import { Button, Input, Paper, Typography } from '@mui/material';

const hostUrl = process.env.REACT_APP_FILESERVER_BACKEND;

type Props = {
  companyId: number;
};

const ShareholdingFileUpload = ({ companyId }: Props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage('Please select a file first.');
      return;
    }

    setUploading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('uploadFiles', selectedFile);

    try {
      const response = await fetch(`${hostUrl}/${companyId}/upload?fileMeanings=shareholder_list`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Upload failed');
      }

      const data = await response.json();
      setMessage('File uploaded successfully: ' + data.fileName);
    } catch (error) {
      setMessage('Error uploading file: ' + error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 400 }}>
      <Typography variant="h6" gutterBottom>
        Upload File
      </Typography>
      <Input type="file" onChange={handleFileChange} fullWidth sx={{ mb: 2 }} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleUpload}
        disabled={uploading || !companyId}
        sx={{ mt: 1 }}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
      {message && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Paper>
  );
};

export default ShareholdingFileUpload;
