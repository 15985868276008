import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CompareIcon from '@mui/icons-material/Compare';
import InsightsIcon from '@mui/icons-material/Insights';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import i18n from '../locales/i18n';

import { paths } from './paths';

const navbarIcons = {
  search: SearchOutlinedIcon,
  validation: AssignmentTurnedInOutlinedIcon,
  evaluation: InventoryOutlinedIcon,
  crawler: MonitorHeartOutlinedIcon,
  benchmarking: CompareIcon,
  analysis: BarChartOutlinedIcon,
  notifications: NotificationsOutlinedIcon,
  research: InsightsIcon,
  settings: SettingsOutlinedIcon,
};

export const navbarItems = [
  {
    text: i18n.t('navbar.search'),
    path: paths.search,
    icon: navbarIcons.search,
  },
  {
    text: i18n.t('navbar.validation'),
    path: paths.crawlerInbox,
    icon: navbarIcons.validation,
  },
  {
    text: i18n.t('navbar.evaluation'),
    icon: navbarIcons.evaluation,
    path: paths.evaluation.evaluation,
    subItems: [
      { text: i18n.t('navbar.assessment'), path: paths.evaluation.dfm },
      {
        text: i18n.t('navbar.customerSegment'),
        path: paths.evaluation.customerSegment,
      },
      { text: i18n.t('navbar.companyFiles'), path: paths.evaluation.companyFiles },
      { text: i18n.t('navbar.bb-lists'), path: paths.evaluation.bbLists },
      { text: i18n.t('navbar.outreach'), path: paths.evaluation.outreach },
    ],
  },
  {
    text: i18n.t('navbar.benchmarking'),
    path: paths.benchmarking,
    icon: navbarIcons.benchmarking,
  },
  {
    text: i18n.t('navbar.research'),
    path: paths.research.softwareCategories,
    icon: navbarIcons.research,
    subItems: [
      { text: i18n.t('navbar.softwareCategories'), path: paths.research.softwareCategories },
      { text: i18n.t('navbar.customerIndustries'), path: paths.research.customerIndustries },
      { text: i18n.t('navbar.matrixSearch'), path: paths.research.matrixSearch },
    ],
  },
  {
    text: i18n.t('navbar.crawler-ops'),
    path: paths.crawlerOps,
    icon: navbarIcons.crawler,
  },
  {
    text: i18n.t('navbar.settings'),
    path: paths.settings,
    icon: navbarIcons.settings,
  },
];

export const navbarNotificationsItemWithBadge = {
  text: i18n.t('navbar.notifications'),
  path: paths.notifications,
  Icon: navbarIcons.notifications,
};
