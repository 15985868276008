import '@assistant-ui/react/styles/index.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import * as Sentry from '@sentry/react';
import { signInWithRedirect } from 'aws-amplify/auth';

import NavbarItemWithBadge from '../../components/NavbarItemWithBadge/NavbarItemWithBadge';
import { navbarItems, navbarNotificationsItemWithBadge } from '../../routes/navbar';

import styles from './styles';

const DRAWER_WIDTH = 240;

function RootLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { user, authStatus, signOut } = useAuthenticator();

  const hasCompanyParamId = searchParams.get('companyId') ? true : false;

  useEffect(() => {
    if (user) {
      console.log('User is authenticated. Setting Sentry user: ', user.username);
      Sentry.setUser({
        name: user.username,
        email: user.userId,
      });
    } else {
      console.log('User is not authenticated');
      Sentry.setUser(null);
    }
  }, [user]);

  const handleAuth = async () => {
    if (authStatus === 'unauthenticated') {
      try {
        await signInWithRedirect({
          provider: {
            custom: 'OktaOIDC',
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error during sign-in:', error);
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error signing out: ', error);
    }
  };

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const renderIcon = (
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
      muiName: string;
    },
  ) => {
    return <Icon sx={styles.navbarIcon} />;
  };

  const drawer = (
    <div>
      <Box sx={styles.drawerLogoWrapper}>
        <Link component={RouterLink} to="/">
          <img src="/logo.png" alt="Logo" style={{ width: '100%', maxWidth: '100px' }} />
        </Link>
      </Box>
      <Divider />
      <List>
        {navbarItems.map((item, index) => (
          <div key={index}>
            <Link
              component={RouterLink}
              to={`${item.path!}${location.search}`}
              underline="none"
              color="inherit"
            >
              <ListItemButton sx={styles.listItemButton}>
                {item?.icon && renderIcon(item?.icon)}
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
            {item.subItems && item.text === t('navbar.evaluation') ? (
              <List component="div" disablePadding>
                {item.subItems.map((subItem, subIndex) => {
                  const isDisabled = !hasCompanyParamId;

                  return (
                    <Box
                      key={subIndex}
                      sx={{
                        ...(isDisabled && { pointerEvents: 'none' }),
                      }}
                    >
                      <Link
                        component={RouterLink}
                        to={`${subItem.path}${location.search}`}
                        underline="none"
                        color={isDisabled ? 'text.disabled' : 'inherit'}
                        style={isDisabled ? { cursor: 'not-allowed' } : {}}
                      >
                        <ListItemButton sx={styles.drawerListItemButton} disabled={isDisabled}>
                          <Box sx={styles.drawerIconWrapper}>
                            <CircleIcon sx={styles.drawerIcon} />
                          </Box>
                          <ListItemText primary={subItem.text} />
                        </ListItemButton>
                      </Link>
                    </Box>
                  );
                })}
              </List>
            ) : (
              item.subItems && (
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => (
                    <Link
                      component={RouterLink}
                      to={`${subItem.path}${location.search}`}
                      underline="none"
                      color={'inherit'}
                      key={subIndex}
                    >
                      <ListItemButton sx={styles.drawerListItemButton}>
                        <Box sx={styles.drawerIconWrapper}>
                          <CircleIcon sx={styles.drawerIcon} />
                        </Box>
                        <ListItemText primary={subItem.text} />
                      </ListItemButton>
                    </Link>
                  ))}
                </List>
              )
            )}
          </div>
        ))}
        <NavbarItemWithBadge {...navbarNotificationsItemWithBadge} location={location.search} />
      </List>
      <Box>
        <Button color="warning" onClick={handleSignOut} fullWidth>
          {t('navbar.logout')}
        </Button>
      </Box>
    </div>
  );

  if (!user) {
    handleAuth();
    return (
      <Box sx={styles.notAuthenticatedWrapper}>
        <CircularProgress color="error" size={50} />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Box component="nav" sx={{ ...styles.wrapper, width: { sm: DRAWER_WIDTH } }}>
        <IconButton
          color="inherit"
          aria-label="open navbar"
          edge="start"
          onClick={handleDrawerToggle}
          sx={styles.menuIconButton}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            ...styles.mobileDrawer,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
            },
          }}
        >
          <IconButton onClick={handleDrawerToggle} sx={styles.menuIconCloseButton}>
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            ...styles.desktopDrawer,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          ...styles.mainContentWrapper,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default RootLayout;
