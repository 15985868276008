import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  onEditHolding: (data: { holding: number | null; holdingCount: number | null }) => void;
  onEditDetails: (data: {
    name: string | null;
    typeOfInvestor: string | null;
    yearBornOrFounded: number | null;
  }) => void;
  fields?: {
    name?: string;
    typeOfInvestor?: string;
    yearBornOrFounded?: number;
    holding?: number;
    holdingCount?: number;
  };
};

const EditShareholding = ({ open, onClose, onEditHolding, onEditDetails, fields = {} }: Props) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string | null>(fields.name ?? null);
  const [typeOfInvestor, setTypeOfInvestor] = useState<string | null>(
    fields.typeOfInvestor ?? null,
  );
  const [yearBornOrFounded, setYearBornOrFounded] = useState<number | null>(
    fields.yearBornOrFounded ?? null,
  );
  const [holding, setHolding] = useState<number | null>(fields.holding ?? null);
  const [holdingCount, setHoldingCount] = useState<number | null>(fields.holdingCount ?? null);

  const handleEdit = () => {
    try {
      onEditHolding({
        holding,
        holdingCount,
      });

      onEditDetails({
        name: name || null,
        typeOfInvestor: typeOfInvestor || null,
        yearBornOrFounded: yearBornOrFounded,
      });

      onClose();
    } catch {}
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {t('validation.validateShareholdingManagement.shareholdingEditDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            label={t(
              'validation.validateShareholdingManagement.shareholdingEditDialogInputNameLabel',
            )}
            value={name || ''}
            onChange={(e) => setName(e.target.value || null)}
            fullWidth
            size="small"
          />
          <TextField
            label={t(
              'validation.validateShareholdingManagement.shareholdingEditDialogInputinvestorLabel',
            )}
            value={typeOfInvestor || ''}
            onChange={(e) => setTypeOfInvestor(e.target.value || null)}
            fullWidth
            size="small"
          />
          <TextField
            label={t(
              'validation.validateShareholdingManagement.shareholdingEditDialogInputYearLabel',
            )}
            type="number"
            value={yearBornOrFounded !== null ? yearBornOrFounded : ''}
            onChange={(e) =>
              setYearBornOrFounded(e.target.value === '' ? null : Number(e.target.value))
            }
            fullWidth
            size="small"
          />
          <TextField
            label={t(
              'validation.validateShareholdingManagement.shareholdingEditDialogInputHoldingLabel',
            )}
            type="number"
            value={holding !== null ? holding : ''}
            onChange={(e) => setHolding(e.target.value === '' ? null : Number(e.target.value))}
            fullWidth
            size="small"
          />
          <TextField
            label={t(
              'validation.validateShareholdingManagement.shareholdingEditDialogInputHoldingCountLabel',
            )}
            type="number"
            value={holdingCount !== null ? holdingCount : ''}
            onChange={(e) => setHoldingCount(e.target.value === '' ? null : Number(e.target.value))}
            fullWidth
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('validation.validateShareholdingManagement.shareholdingEditDialogActionsCancelButton')}
        </Button>
        <Button onClick={handleEdit} variant="contained" color="primary">
          {t('validation.validateShareholdingManagement.shareholdingEditDialogActionsEditButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditShareholding;
