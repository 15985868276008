import { SxProps, Theme } from '@mui/system';

const isSoftwareStepStyles: Record<string, SxProps<Theme>> = {
  iframeBox: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    height: '100vh',
  },
  link: {
    color: 'primary.main',
  },
};

const validateAddressStyles: Record<string, SxProps<Theme>> = {
  container: {
    py: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mt: 2,
  },
  datePicker: {
    width: { xs: '100%', md: '50%' },
  },
  link: {
    alignItems: 'center',
    display: 'flex',
  },
};

const validateShareholdingStyles: Record<string, SxProps<Theme>> = {
  publicRegisterButton: {
    mt: 2,
  },
  addShareholderButton: {
    mt: 2,
    alignSelf: 'flex-start',
  },
  addShareHoldingAddButton: {
    alignSelf: 'flex-start',
  },
};

const validateKeywordsStyles: Record<string, SxProps<Theme>> = {
  gridFooter: {
    border: 'none',
  },
  dataGridMargin: {
    mt: 2,
    mb: 4,
  },
};

const validateCustomerStyles: Record<string, SxProps<Theme>> = {
  tabsBox: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  customLinkSicCodes: {
    color: 'primary.main',
    textDecoration: 'underline',
    mb: 1,
    '&:hover': { color: 'error.main' },
  },
  addIndustryTabUrlTextField: {
    mt: 2,
  },
  addIndustryTabAddButton: {
    mt: 1,
    width: 'fit-content',
  },
  addIndustryTabHorizontalSolutionButton: {
    textTransform: 'none',
  },
};

const validateProductCategoryStyles: Record<string, SxProps<Theme>> = {
  dataGrid: {
    mt: 2,
  },
};

const validateTechnologyStyles: Record<string, SxProps<Theme>> = {
  dataGrid: {
    mt: 2,
  },
  textField: {
    mt: 2,
  },
};

const finishStepStyles: Record<string, SxProps<Theme>> = {
  paperPadding: {
    p: 3,
  },
  divider: {
    my: 1,
  },
};

export {
  isSoftwareStepStyles,
  validateAddressStyles,
  validateKeywordsStyles,
  validateCustomerStyles,
  validateProductCategoryStyles,
  validateShareholdingStyles,
  validateTechnologyStyles,
  finishStepStyles,
};
