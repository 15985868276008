import { SxProps, Theme } from '@mui/system';

const actionButtonsStyles: Record<string, SxProps<Theme>> = {
  forceCrawlButton: {
    backgroundColor: 'white',
    borderRadius: 1,
    color: 'primary.main',
  },
};

export default actionButtonsStyles;
