import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';

import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import SearchComponent from '../../components/Search/Search';
import {
  useCompanyInboxListLazyQuery,
  useGetBuyAndBuildPlatformsLazyQuery,
  useGetShortCompanyLazyQuery,
  useMarkCompanyAsNotSoftwareMutation,
  useStartCrawlMutation,
  useStartVerificationSessionMutation,
} from '../../gql/generated/graphql';
import { handleVisitPage } from '../../helpers/helpers';
import { useCompanyNavigation } from '../../hooks/useCompanyNavigation';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { paths } from '../../routes/paths';
import CustomPaper from '../DFMPage/components/CustomPaper/CustomPaper';

import ActionButtonsFalseNegatives from './components/ActionButtonsFalseNegatives';
import { countryOptions } from './helpers';
import { TabIndex } from './types';

const CrawlerInbox = () => {
  const navigate = useNavigate();
  const { handleValidate, handleNavigateToAssesment } = useCompanyNavigation();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { user } = useAuthenticator();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [platform, setPlatform] = useState<string | null>(null);

  const companyParamId = searchParams.get('companyId');

  const [getShortCompany, { data: shortCompanyData, loading: shortCompanyLoading }] =
    useGetShortCompanyLazyQuery();

  useEffect(() => {
    if (!companyParamId) return;
    getShortCompany({ variables: { companyId: Number(companyParamId) } });
  }, [companyParamId]);

  const [
    getCompanyInboxList,
    { data: companyInboxList, loading: companyInboxListLoading, refetch: companyInboxListRefetch },
  ] = useCompanyInboxListLazyQuery();

  const [getBuyAndBuildPlatforms, { data: getBuyAndBuildPlatformsData }] =
    useGetBuyAndBuildPlatformsLazyQuery();

  const [markCompanyAsNotSoftware, { loading: markCompanyAsNotSoftwareLoading }] =
    useMarkCompanyAsNotSoftwareMutation();

  const [startCrawl, { loading: startCrawlLoading }] = useStartCrawlMutation();

  const companyName = shortCompanyData?.getCompany?.edges?.[0]?.node?.shortName;

  const getBuyAndBuildPlatformsNode =
    getBuyAndBuildPlatformsData?.getBuyAndBuildPlatforms?.edges?.map((edge) => edge?.node);

  const [startVerificationSession] = useStartVerificationSessionMutation();

  useEffect(() => {
    if (activeTab === TabIndex.PORTFOLIO) {
      getBuyAndBuildPlatforms();
    }
  }, [activeTab]);

  useEffect(() => {
    const queryVariables = getQueryVariables(activeTab, platform);

    if (Object.keys(queryVariables)?.length > 0) {
      getCompanyInboxList({
        variables: queryVariables,
        fetchPolicy: 'network-only',
      });
    }
  }, [activeTab, platform]);

  const getQueryVariables = (activeTab: TabIndex, platform: string | null) => {
    switch (activeTab) {
      case TabIndex.PORTFOLIO:
        return { platformId: platform ? Number(platform) : undefined };
      case TabIndex.POTENTIAL_PLATFORMS:
        return {
          newPlatforms: true,
          country: countryOptions[0].value,
        };
      case TabIndex.STEALTH_PLATFORMS:
        return {
          prioritizedPlatforms: true,
        };
      case TabIndex.FALSE_NEGATIVES:
        return {
          falseNegatives: true,
        };
      default:
        return {};
    }
  };

  const productCategoryColumns = [
    {
      field: 'uniqueDomain',
      headerName: t('validation.finishStep.domain'),
      width: 150,
    },
    {
      field: 'country',
      headerName: t('validation.finishStep.country'),
      width: 80,
    },
    {
      field: 'sicCode',
      headerName: t('validation.finishStep.industry'),
      width: 100,
    },
    {
      field: 'sicCodeCertainty',
      headerName: t('validation.finishStep.certainty'),
      width: 100,
    },
    {
      field: 'fte',
      headerName: t('validation.finishStep.fte'),
      width: 50,
    },
    {
      field: 'privateOwned',
      width: 100,
      headerName: t('validation.finishStep.private'),
      renderCell: (params) => {
        return params?.formattedValue ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <DoneIcon fontSize="small" />
          </Box>
        ) : null;
      },
    },
    {
      field: 'datetimeCreated',
      headerName: t('validation.finishStep.when'),
      valueGetter: (params) => format(params, 'MMM dd, yyyy h:mm a'),
      width: 170,
    },
    {
      field: 'productCategories',
      headerName: t('validation.finishStep.systemType'),
      width: 150,
      valueGetter: (params) => {
        return params?.edges?.[0]?.node?.softwareCategory?.subCategory1 || '';
      },
    },
    {
      field: 'iabDomainCategory',
      headerName: t('validation.finishStep.iabDomainCategory'),
      width: 200,
    },
    {
      field: 'peOwned',
      width: 100,
      headerName: t('validation.finishStep.peOwned'),
      renderCell: (params) => {
        return params?.formattedValue ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <DoneIcon fontSize="small" />
          </Box>
        ) : null;
      },
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return activeTab === TabIndex.FALSE_NEGATIVES ? (
          <ActionButtonsFalseNegatives
            params={params}
            onMarkAsNotSoftware={handleMarkAsNotSoftware}
            onForceCrawl={handleForceCrawl}
          />
        ) : (
          <>
            <IconButton size="small" onClick={() => handleValidateAction(params.row)}>
              <Tooltip title={t('validation.finishStep.validate')}>
                <CheckCircleOutlineIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleNavigateToAssesment(params.row?.dbId)}>
              <Tooltip title={t('validation.finishStep.check')}>
                <FactCheckIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleMarkAsNotSoftware(params.row)}>
              <Tooltip title={t('validation.finishStep.notSW')}>
                <CloseIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleVisitPage(params.row.uniqueDomain)}>
              <LanguageIcon fontSize="small" />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleOptionSelect(null, countryOptions[0]);
  }, []);

  const handleOptionSelect = (_, option: { value: string; label: string }) => {
    if (option === null || option.value === '') return;
    let queryVariables = {};
    switch (activeTab) {
      case TabIndex.COUNTRY:
        queryVariables = {
          country: option.value,
        };
        break;
      case TabIndex.POTENTIAL_PLATFORMS:
        queryVariables = {
          country: option.value,
          newPlatforms: true,
        };
        break;
      default:
        return;
    }
    getCompanyInboxList({
      variables: queryVariables,
      fetchPolicy: 'network-only',
    });
  };

  const handleInputChange = (_, value: string) => {
    setSearchTerm(value);
  };
  const handleReset = () => {
    setSearchTerm('');
  };

  const getLabel = (option: { label: string; value: string }) => {
    return option?.label;
  };

  const handleMoveToValidation = () => {
    if (!companyParamId) return;
    navigate(`${paths.validation}?companyId=${companyParamId}`);
  };

  const authId = user?.userId;

  const handleValidateAction = async (row) => {
    const companyId = row?.dbId;

    const result = await startVerificationSession({ variables: { companyId, authId } });
    if (result?.data?.startCompanyVerificationSession?.success) {
      handleValidate(companyId);
    } else {
      showSnackbar('VerificationSession', 'error');
    }
  };

  const handleMarkAsNotSoftware = async (row) => {
    const result = await markCompanyAsNotSoftware({
      variables: { companyId: row?.dbId },
    });
    if (result?.data?.markCompanyAsNotSoftware?.ok) {
      companyInboxListRefetch();
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangePlatform = (event: SelectChangeEvent) => {
    setPlatform(event.target.value);
  };

  const handleForceCrawl = async (row) => {
    const result = await startCrawl({
      variables: {
        companyName: row?.shortName,
        domain: row?.uniqueDomain,
        forceSw: true,
      },
    });
    if (result?.data?.startCrawler?.success) {
      companyInboxListRefetch();
    }
  };

  const isActiveFalseNegatives = activeTab === TabIndex.FALSE_NEGATIVES;

  return (
    <Box display="flex" gap={4} flexDirection="column">
      <Typography variant="h5">{t('crawlerInbox')}</Typography>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label={t('validation.tabs.country')} />
        <Tab label={t('validation.tabs.portfolio')} />
        <Tab label={t('validation.tabs.potentialPlatforms')} />
        <Tab label={t('validation.tabs.stealthPlatforms')} />
        <Tab label={t('validation.tabs.falseNegatives')} />
      </Tabs>
      {activeTab === TabIndex.COUNTRY || activeTab === TabIndex.POTENTIAL_PLATFORMS ? (
        <SearchComponent
          options={countryOptions}
          loading={false}
          searchTerm={searchTerm}
          placeholder={t('validation.countrySelectLabel')}
          defaultValue
          onInputChange={handleInputChange}
          onOptionSelect={handleOptionSelect}
          onReset={handleReset}
          getCustomOptionLabel={getLabel}
        />
      ) : null}
      {activeTab === TabIndex.PORTFOLIO && (
        <Box>
          <FormControl fullWidth>
            <InputLabel size="small" id="bid-firm">
              BID Firm
            </InputLabel>
            <Select
              size="small"
              labelId="bid-firm"
              id="bid-firm"
              value={platform}
              label="Bid Firm"
              onChange={handleChangePlatform}
            >
              {getBuyAndBuildPlatformsNode?.map((node) => (
                <MenuItem key={node?.dbId} value={node?.dbId}>
                  {node?.shortName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {companyInboxListLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <CustomPaper>
          <DataGrid
            paginationMode="client"
            loading={companyInboxListLoading}
            disableRowSelectionOnClick
            rows={companyInboxList?.companyInboxList || []}
            getRowId={(row) => row.dbId}
            columns={productCategoryColumns}
            rowHeight={35}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  privateOwned: !isActiveFalseNegatives,
                  peOwned: !isActiveFalseNegatives,
                  productCategories: !isActiveFalseNegatives,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        </CustomPaper>
      )}
      {companyParamId && (
        <Box>
          <Button
            disabled={!companyParamId || shortCompanyLoading}
            variant="contained"
            onClick={handleMoveToValidation}
          >
            {t('validation.moveToValidateProcess', { companyName: companyName })}
          </Button>
        </Box>
      )}
      <CustomBackdrop open={markCompanyAsNotSoftwareLoading || startCrawlLoading} />
    </Box>
  );
};

export default CrawlerInbox;
